import { React, useState, useEffect } from "react";
import axios from "axios";
import authHeader from "../auth-header";

function PageDivers() {

  const [currentUser, setCurrentUser] = useState({});
  const [dmdGarage, setDmdGarage] = useState([]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios.get(process.env.REACT_APP_BASE_URL + '/api/dmdintervgarage/', { headers: authHeader() })
      .then(response => {
        setDmdGarage(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  function countMissingUsernames() {
    if (currentUser.roles === 'Admin') {
      const filteredDmdGarage = dmdGarage.filter(vehicle => {
        return vehicle.service === currentUser.service && !vehicle.idusers.includes(currentUser.username);
      });

      return filteredDmdGarage.length;
    } else {
      return 0;
    }
  }

  const CustomButton = ({ onClick, label, hasNotification, notificationCount }) => (
    <div className="w-full">
      <button
        type="button"
        onClick={onClick}
        className="relative w-full flex items-center justify-between px-6 py-4 bg-white rounded-lg shadow-sm hover:bg-gray-50 transition duration-150 ease-in-out group"
      >
        <span className="text-sm font-medium text-gray-900">{label}</span>
        {hasNotification && notificationCount > 0 && (
          <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
            {notificationCount}
          </span>
        )}
        <svg
          className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-3xl mx-auto px-4 py-6">
        <h1 className="text-2xl font-semibold text-gray-900 mb-8">État des véhicules</h1>

        <div className="space-y-3">
          <CustomButton
            onClick={() => (window.location.href = "/divers/exercice")}
            label="Véhicules en exercice"
          />
          <CustomButton
            onClick={() => (window.location.href = "/divers/liaison")}
            label="Véhicules en liaison"
          />
          <CustomButton
            onClick={() => (window.location.href = "/divers/garage")}
            label="Véhicules au garage"
          />
          <CustomButton
            onClick={() => (window.location.href = "/listdmdgarage")}
            label="Véhicules en demande d'interv. garage"
            hasNotification={true}
            notificationCount={countMissingUsernames()}
          />
          <CustomButton
            onClick={() => (window.location.href = "/divers/sic")}
            label="Véhicules aux SIC"
          />
        </div>
      </div>
    </div>
  );
};

export default PageDivers;
