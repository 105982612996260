import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import "react-datepicker/dist/react-datepicker.css";
import authHeader from "../auth-header";
import Datepicker from "tailwind-datepicker-react";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function NewRevision() {
  const [vhl, setVhl] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [date, setDate] = useState(new Date());
  const [kmeffectif, setKmEffectif] = useState("");
  const [kmrevision, setKmRevision] = useState("");
  const [remarque, setRemarque] = useState("");
  const { id } = useParams();
  const [show, setShow] = useState(false);

  const revisions = [];
  for (let km = 10000; km <= 380000; km += 10000) {
    revisions.push({ title: `Révision des ${km}km`, km });
  }

  const [selectedRevision, setSelectedRevision] = useState(revisions[0]);

  const options = {
    autoHide: true,
    todayBtn: true,
    clearBtn: true,
    maxDate: new Date("2030-01-01"),
    minDate: new Date("2023-01-01"),
    theme: {
      background: "bg-gray-50 dark:bg-gray-700",
      todayBtn: "",
      clearBtn: "",
      icons: "",
      text: "",
      disabledText: "",
      input: "",
      inputIcon: "",
      selected: "bg-gray-100"
    },
    icons: {
      prev: () => <span>Previous</span>,
      next: () => <span>Next</span>,
    },
    datepickerClassNames: "top-12",
    defaultDate: new Date(),
    language: "fr",
    disabledDates: [],
    weekDays: ["Lu", "Mar", "Mer", "Jeu", "Ve", "Sa", "Di"],
    inputNameProp: "date",
    inputIdProp: "date",
    inputPlaceholderProp: "Selectionner la date",
    inputDateFormatProp: {
      day: "numeric",
      month: "long",
      year: "numeric",
    },
  };

  const handleDateChange = (date) => {
    setDate(date);
  };

  const handleDateClick = () => {
    setShow(true);
  };

  const onChangeKm = (e) => {
    setKmEffectif(Number(e.target.value));
    };

  const onChangeKMRevision = (e) => {
    setSelectedRevision(e);
    setKmRevision(e.km);
  };

  const onChangeRemarque = (e) => {
    setRemarque(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const historevision = {
      idvhl: vhl._id,
      nomvhl: vhl.nomvhl,
      service: vhl.service,
      date: date,
      remarque: remarque,
      kmeffectif: Number(kmeffectif),
      km: selectedRevision.km,
    };

    axios
      .post(process.env.REACT_APP_BASE_URL + "/api/historiquerevision/add/", historevision, { headers: authHeader() })
      .then((res) => {
        window.location = `/historiquerevision/${vhl._id}`;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isLoading) {
      axios
        .get(process.env.REACT_APP_BASE_URL + "/api/vhl/" + id, { headers: authHeader() })
        .then((response) => {
          setVhl(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      setIsLoading(false);
    }
  }, [id, isLoading]);


  return (
    <div className="bg-gray-100">
      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
        <div className="bg-white rounded-2xl shadow-lg p-6 transform hover:scale-[1.01] transition-all duration-300">
          {/* En-tête avec image */}
          <div className="flex flex-col items-center mb-8">
            <div className="relative group">
              <img
                className="h-32 w-32 rounded-full object-cover ring-4 ring-white shadow-lg transform group-hover:scale-105 transition-all duration-300"
                src={vhl.image}
                alt={vhl.nomvhl}
              />
              <div className="absolute inset-0 rounded-full ring-2 ring-blue-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
            </div>
            <h3 className="mt-4 text-2xl font-bold text-gray-900">{vhl.nomvhl}</h3>
            <p className="mt-1 text-lg text-gray-500 font-medium">{vhl.dossardorigine}</p>
          </div>

          {/* Séparateur décoratif */}
          <div className="relative my-8">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-200" />
            </div>
            <div className="relative flex justify-center">
              <span className="bg-white px-4 text-sm font-medium text-gray-500">
                Nouvelle révision
              </span>
            </div>
          </div>

          {/* Formulaire */}
          <form onSubmit={onSubmit} className="space-y-6">
            {/* Sélection de la révision */}
            <div className="space-y-2">
              <Listbox value={selectedRevision} onChange={onChangeKMRevision}>
                {({ open }) => (
                  <>
                    <Listbox.Label className="block text-sm font-semibold text-gray-900">
                      Type de révision
                    </Listbox.Label>
                    <div className="relative mt-1">
                      <Listbox.Button className="relative w-full cursor-pointer rounded-lg bg-white py-3 pl-4 pr-10 text-left border border-gray-300 hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200">
                        <span className="block truncate text-gray-900">
                          {selectedRevision.title}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {revisions.map((option) => (
                            <Listbox.Option
                              key={option.km}
                              value={option}
                              className={({ active }) =>
                                classNames(
                                  active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                  'relative cursor-pointer select-none py-2 pl-10 pr-4 hover:bg-blue-50 hover:text-blue-900 transition-colors duration-150'
                                )
                              }
                            >
                              {({ selected, active }) => (
                                <>
                                  <span className={classNames(
                                    selected ? 'font-semibold' : 'font-normal',
                                    'block truncate'
                                  )}>
                                    {option.title}
                                  </span>
                                  {selected && (
                                    <span className={classNames(
                                      active ? 'text-white' : 'text-blue-600',
                                      'absolute inset-y-0 left-0 flex items-center pl-3'
                                    )}>
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  )}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>

            {/* Date de révision */}
            <div className="space-y-2">
              <label className="block text-sm font-semibold text-gray-900">
                Date de révision
              </label>
              <div className="mt-1">
                <Datepicker
                  options={options}
                  value={date}
                  onChange={handleDateChange}
                  show={show}
                  setShow={setShow}
                  onClick={handleDateClick}
                />
              </div>
            </div>

            {/* Kilométrage */}
            <div className="space-y-2">
              <label className="block text-sm font-semibold text-gray-900">
                Kilométrage actuel
              </label>
              <input
                type="number"
                name="kmeffectif"
                id="kmeffectif"
                value={kmeffectif}
                onChange={onChangeKm}
                className="block w-full rounded-lg border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-200"
                placeholder="Entrez le kilométrage actuel"
              />
            </div>

            {/* Remarques */}
            <div className="space-y-2">
              <label className="block text-sm font-semibold text-gray-900">
                Remarques
              </label>
              <textarea
                name="remarques"
                id="remarques"
                rows="3"
                value={remarque}
                onChange={onChangeRemarque}
                className="block w-full rounded-lg border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-200"
                placeholder="Ajoutez vos remarques ici"
              />
            </div>

            {/* Bouton de validation */}
            <div className="flex justify-center pt-6">
              <button
                type="submit"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transform hover:scale-105 transition-all duration-200"
              >
                Enregistrer la révision
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default NewRevision;
