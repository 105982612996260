import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import authHeader from "../auth-header";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { CalendarIcon, MapPinIcon, UserIcon } from '@heroicons/react/20/solid';

export default function ReservationList() {
  const [reservations, setReservations] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [reservationsRes, userRes] = await Promise.all([
          axios.get(process.env.REACT_APP_BASE_URL + '/api/reservations/', { headers: authHeader() }),
          axios.get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
        ]);
        setReservations(reservationsRes.data);
        setCurrentUser(userRes.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const filteredVehicles = reservations
    .filter((reservation) => currentUser && reservation.service === currentUser.service)
    .sort((a, b) => new Date(a.datedebut) - new Date(b.datedebut));

  const formatDate = (date) => {
    return format(new Date(date), 'dd MMMM yyyy', { locale: fr });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-900"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-gray-900">Réservations en cours</h1>
        </div>

        {filteredVehicles.length === 0 ? (
          <div className="text-center py-12">
            <CalendarIcon className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">Aucune réservation</h3>
            <div className="mt-6">
              <Link
                to="/reservations"
                className="inline-flex items-center rounded-md bg-blue-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-800"
              >
                <CalendarIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                Nouvelle réservation
              </Link>
            </div>
          </div>
        ) : (
          <div className="bg-white shadow-sm ring-1 ring-gray-900/5 rounded-xl">
            <ul role="list" className="divide-y divide-gray-100">
              {filteredVehicles.map((item) => (
                <li key={item._id} className="relative">
                  <Link
                    to={`/reservations/${item._id}`}
                    className="block hover:bg-gray-50 transition-colors duration-150 p-6"
                  >
                    <div className="flex items-center gap-x-4">
                      <img
                        src={item.imagevhl}
                        alt={item.nomvhl}
                        className="h-12 w-12 flex-none rounded-lg bg-gray-50 object-cover"
                      />
                      <div className="min-w-0 flex-auto">
                        <div className="flex items-center gap-x-3">
                          <h2 className="text-sm font-semibold leading-6 text-gray-900">
                            {item.nomvhl}
                          </h2>
                          <div className="flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                            <UserIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                            <span>{item.nom}</span>
                          </div>
                        </div>
                        <div className="mt-3 flex flex-wrap items-center gap-x-2 gap-y-1 text-xs leading-5 text-gray-500">
                          <CalendarIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                          <time dateTime={item.datedebut}>
                            Du {formatDate(item.datedebut)}
                          </time>
                          <span className="mx-1">au</span>
                          <time dateTime={item.datefin}>
                            {formatDate(item.datefin)}
                          </time>
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Bouton Calendrier */}
        <Link
          to="/reservations/calendrier"
          className="fixed bottom-6 right-6 inline-flex items-center rounded-full bg-blue-900 p-3 text-white shadow-sm hover:bg-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-900"
        >
          <CalendarIcon className="h-6 w-6" aria-hidden="true" />
        </Link>
      </div>
    </div>
  );
}
