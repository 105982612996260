import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, UserCircleIcon } from '@heroicons/react/20/solid';
import authHeader from "../auth-header";

function EditUser(props) {
  const [user, setUser] = useState({ username: '' });
  const [currentUser, setCurrentUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const { id } = useParams();

  const serviceOptions = [
    { id: 1, name: 'FOR' },
    { id: 2, name: 'FI' },
    { id: 3, name: 'FSP' },
    { id: 4, name: 'SMS' },
    { id: 5, name: 'DT' },
    { id: 6, name: 'CNFIS' },
    { id: 7, name: 'EMOPS' },
    { id: 8, name: 'EMSF' },
    { id: 9, name: 'GARAGE' },
    { id: 10, name: 'MAG' },
    { id: 11, name: 'FA' },
    { id: 12, name: 'R&D' },
    { id: 13, name: 'CAB' },
    { id: 14, name: 'AMS' },
    { id: 15, name: 'CYNO' },
    { id: 16, name: 'DPA' },
    { id: 17, name: 'CIO' },
    { id: 18, name: 'CTAO' },
    { id: 19, name: 'GAV' },
    { id: 20, name: 'XPRH' },
  ];

  const roleOptions = [
    { id: 1, name: 'Admin' },
    { id: 2, name: 'Normal' },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  useEffect(() => {
    if (isLoading) {
      axios
        .get(process.env.REACT_APP_BASE_URL + "/api/users/" + id, { headers: authHeader() })
        .then((response) => {
          setUser(response.data);
          setSelectedService(serviceOptions.find(option => option.name === response.data.service));
          setSelectedRole(roleOptions.find(option => option.name === response.data.roles));
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", {
          headers: authHeader(),
        })
        .then((response) => {
          setCurrentUser(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isLoading, id]);

  function onChangeNom(e) {
    setUser((user) => ({
      ...user,
      username: e.target.value,
    }));
  }

  function onChangeService(selectedOption) {
    setSelectedService(selectedOption);
  }

  function onChangeRole(selectedOption) {
    setSelectedRole(selectedOption);
  }

  function onSubmit(e) {
    e.preventDefault();

    user.service = selectedService.name;
    user.roles = selectedRole.name;

    axios
      .post(process.env.REACT_APP_BASE_URL + "/api/users/update/" + id, user, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log(res.data);
        window.location = "/listuser";
      });
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <div className="max-w-2xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-sm overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <div className="flex items-center justify-center mb-6">
              <UserCircleIcon className="h-12 w-12 text-blue-900" />
            </div>
            <h3 className="text-2xl font-bold text-center text-gray-900 mb-8">
              Éditer un utilisateur
            </h3>

            <form onSubmit={onSubmit} className="space-y-6">
              <div>
                <label htmlFor="username" className="block text-sm font-medium text-gray-900">
                  Nom d'utilisateur
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="username"
                    id="username"
                    className="block w-full rounded-lg border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm"
                    placeholder="Nom d'utilisateur"
                    value={user.username}
                    onChange={onChangeNom}
                  />
                </div>
              </div>

              <div>
                <Listbox value={selectedService} onChange={onChangeService}>
                  {({ open }) => (
                    <>
                      <Listbox.Label className="block text-sm font-medium text-gray-900">
                        Service
                      </Listbox.Label>
                      <div className="relative mt-2">
                        <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-900 sm:text-sm">
                          <span className="block truncate">
                            {selectedService ? selectedService.name : 'Sélectionner un service'}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {serviceOptions.map((option) => (
                              <Listbox.Option
                                key={option.id}
                                className={({ active }) =>
                                  classNames(
                                    active ? 'bg-blue-900 text-white' : 'text-gray-900',
                                    'relative cursor-default select-none py-2 pl-8 pr-4'
                                  )
                                }
                                value={option}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                      {option.name}
                                    </span>
                                    {selected && (
                                      <span
                                        className={classNames(
                                          active ? 'text-white' : 'text-blue-900',
                                          'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                        )}
                                      >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    )}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div>

              <div>
                <Listbox value={selectedRole} onChange={onChangeRole}>
                  {({ open }) => (
                    <>
                      <Listbox.Label className="block text-sm font-medium text-gray-900">
                        Rôle
                      </Listbox.Label>
                      <div className="relative mt-2">
                        <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-900 sm:text-sm">
                          <span className="block truncate">
                            {selectedRole ? selectedRole.name : 'Sélectionner un rôle'}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {roleOptions.map((option) => (
                              <Listbox.Option
                                key={option.id}
                                className={({ active }) =>
                                  classNames(
                                    active ? 'bg-blue-900 text-white' : 'text-gray-900',
                                    'relative cursor-default select-none py-2 pl-8 pr-4'
                                  )
                                }
                                value={option}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                      {option.name}
                                    </span>
                                    {selected && (
                                      <span
                                        className={classNames(
                                          active ? 'text-white' : 'text-blue-900',
                                          'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                        )}
                                      >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    )}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div>

              <div className="mt-6 flex justify-center">
                <button
                  type="submit"
                  className="rounded-lg bg-blue-900 px-6 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-900 transition-colors duration-200"
                >
                  Enregistrer les modifications
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditUser;
