import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Checkbox,
  Badge,
  Paper,
  Divider,
  useTheme,
  useMediaQuery,
  Container,
  CircularProgress,
  Alert,
  Snackbar
} from '@mui/material';

// Icons
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import KeyIcon from '@mui/icons-material/Key';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import MicIcon from '@mui/icons-material/Mic';
import ForestIcon from '@mui/icons-material/Forest';
import MasksIcon from '@mui/icons-material/Masks';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ConstructionIcon from '@mui/icons-material/Construction';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';
import { useParams, useNavigate } from 'react-router-dom';

import authHeader from '../../auth-header';
import { groupBy } from 'lodash';

const categories = [
  { id: 'Image', label: 'Image', icon: CameraAltIcon, color: '#4CAF50' },
  { id: 'COF / Perben', label: 'COF / Perben', icon: KeyIcon, color: '#2196F3' },
  { id: 'Rens aérien / Drones', label: 'Rens aérien / Drone', icon: AirplanemodeActiveIcon, color: '#9C27B0' },
  { id: 'Micro', label: 'Micro', icon: MicIcon, color: '#F44336' },
  { id: 'Verte', label: 'Verte', icon: ForestIcon, color: '#4CAF50' },
  { id: 'CNP', label: 'CNP', icon: MasksIcon, color: '#FF9800' },
  { id: 'Interpel / Armement', label: 'Interpel / Armement', icon: LocalFireDepartmentIcon, color: '#795548' },
  { id: 'Mobilité', label: 'Mobilité', icon: DirectionsCarIcon, color: '#607D8B' },
  { id: 'Divers', label: 'Divers', icon: ConstructionIcon, color: '#9E9E9E' },
  { id: 'SIC', label: 'SIC', icon: SettingsInputAntennaIcon, color: '#3F51B5' },
  { id: 'Track', label: 'Track', icon: LocationOnIcon, color: '#E91E63' }
];

const EditPerception = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { id } = useParams();
  
  const [formData, setFormData] = useState({
    typePerception: '',
    nomPerception: '',
    remarques: ''
  });
  const [materiels, setMateriels] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});
  const [filteredMateriels, setFilteredMateriels] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [materielResponse, userResponse, perceptionResponse] = await Promise.all([
          axios.get(process.env.REACT_APP_BASE_URL + '/api/materiels/', { headers: authHeader() }),
          axios.get(process.env.REACT_APP_BASE_URL + '/api/users/CurrentUser', { headers: authHeader() }),
          axios.get(process.env.REACT_APP_BASE_URL + '/api/perceptionmateriel/' + id, { headers: authHeader() })
        ]);
        
        setMateriels(materielResponse.data);
        setCurrentUser(userResponse.data);
        
        // Set form data from existing perception
        setFormData({
          typePerception: perceptionResponse.data.typeperception,
          nomPerception: perceptionResponse.data.nomperception,
          remarques: perceptionResponse.data.remarques || ''
        });

        // Convert existing perception data to new format with quantities
        const convertedItems = {};
        categories.forEach(category => {
          const categoryKey = category.id.toLowerCase().replace(/ .*/, '');
          const items = perceptionResponse.data[categoryKey] || [];
          
          const categoryItems = {};
          items.forEach(item => {
            categoryItems[item.materiel._id] = {
              selected: true,
              quantity: item.materiel.nbperception || 1
            };
          });
          
          if (Object.keys(categoryItems).length > 0) {
            convertedItems[category.id] = categoryItems;
          }
        });

        setSelectedItems(convertedItems);
        setLoading(false);
      } catch (err) {
        setError('Erreur lors du chargement des données');
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    if (selectedCategory && materiels.length > 0) {
      const filteredMateriels = materiels.filter(m => 
        m.categorie === selectedCategory.id
      );
      setFilteredMateriels(filteredMateriels);
    }
  }, [selectedCategory, materiels]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError('');
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedCategory(null);
  };

  const handleItemSelection = (itemId) => {
    setSelectedItems(prev => ({
      ...prev,
      [selectedCategory.id]: {
        ...(prev[selectedCategory.id] || {}),
        [itemId]: {
          selected: !(prev[selectedCategory.id]?.[itemId]?.selected || false),
          quantity: prev[selectedCategory.id]?.[itemId]?.quantity || 1
        }
      }
    }));
  };

  const handleQuantityChange = (itemId, value) => {
    setSelectedItems(prev => ({
      ...prev,
      [selectedCategory.id]: {
        ...(prev[selectedCategory.id] || {}),
        [itemId]: {
          ...prev[selectedCategory.id][itemId],
          quantity: Math.max(1, parseInt(value) || 1)
        }
      }
    }));
  };

  const getSelectedItemsCount = (categoryId) => {
    return Object.values(selectedItems[categoryId] || {})
      .filter(item => item.selected)
      .length;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.nomPerception || !formData.typePerception) {
      setError('Veuillez remplir tous les champs obligatoires');
      setSnackbarOpen(true);
      return;
    }

    try {
      setLoading(true);

      // Initialiser toutes les catégories avec des tableaux vides
      const perceptionData = {
        nomperception: formData.nomPerception,
        typeperception: formData.typePerception,
        remarques: formData.remarques,
        date: new Date(),
        image: [],
        cof: [],
        drone: [],
        micro: [],
        verte: [],
        cnp: [],
        interpel: [],
        mobilite: [],
        divers: [],
        sic: [],
        track: []
      };

      // Remplir les catégories avec les éléments sélectionnés
      Object.entries(selectedItems).forEach(([category, items]) => {
        const categoryMapping = {
          'Image': 'image',
          'COF / Perben': 'cof',
          'Rens aérien / Drones': 'drone',
          'Micro': 'micro',
          'Verte': 'verte',
          'CNP': 'cnp',
          'Interpel / Armement': 'interpel',
          'Mobilité': 'mobilite',
          'Divers': 'divers',
          'SIC': 'sic',
          'Track': 'track'
        };

        const categoryKey = categoryMapping[category];
        if (perceptionData.hasOwnProperty(categoryKey)) {
          perceptionData[categoryKey] = Object.entries(items)
            .filter(([_, item]) => item.selected)
            .map(([itemId]) => {
              const materiel = materiels.find(m => m._id === itemId);
              return {
                materiel: {
                  _id: materiel._id,
                  nom: materiel.nom,
                  image: materiel.image,
                  nbperception: items[itemId].quantity,
                  imageperception: materiel.imageperception,
                }
              };
            });
        }
      });

      await axios.post(
        process.env.REACT_APP_BASE_URL + '/api/perceptionmateriel/update/' + id,
        perceptionData,
        { headers: authHeader() }
      );

      setSuccess('Perception modifiée avec succès');
      setSnackbarOpen(true);
      setTimeout(() => {
        navigate(`/perceptionmateriel/${id}`);
      }, 1500);
    } catch (err) {
      setError('Erreur lors de la modification de la perception');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <form onSubmit={handleSubmit}>
        <div className="text-center mb-6">
          <h1 className="text-3xl font-bold text-gray-900">Modifier la perception</h1>
        </div>

        {/* Form Fields */}
        <Card sx={{ mb: 4 }}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Nom de la perception"
                  name="nomPerception"
                  value={formData.nomPerception}
                  onChange={handleInputChange}
                  required
                  error={!formData.nomPerception && error}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required error={!formData.typePerception && error}>
                  <InputLabel>Type de perception</InputLabel>
                  <Select
                    name="typePerception"
                    value={formData.typePerception}
                    onChange={handleInputChange}
                    label="Type de perception"
                  >
                    <MenuItem value="Mission">Mission</MenuItem>
                    <MenuItem value="Exercice">Exercice</MenuItem>
                    <MenuItem value="Maintenance">Maintenance</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Remarques"
                  name="remarques"
                  value={formData.remarques}
                  onChange={handleInputChange}
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* Categories Grid */}
        <Grid container spacing={2}>
          {categories.map((category) => {
            const selectedCount = getSelectedItemsCount(category.id);
            const Icon = category.icon;
            
            return (
              <Grid item xs={4} sm={3} md={2} key={category.id}>
                <Card 
                  onClick={() => handleCategoryClick(category)}
                  sx={{
                    cursor: 'pointer',
                    aspectRatio: '1/1',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    transition: 'transform 0.2s',
                    position: 'relative',
                    overflow: 'visible',
                    '&:hover': {
                      transform: 'scale(1.02)',
                    }
                  }}
                >
                  {selectedCount > 0 && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: -8,
                        right: -8,
                        zIndex: 1,
                        backgroundColor: 'primary.main',
                        color: 'white',
                        borderRadius: '50%',
                        width: 24,
                        height: 24,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '0.85rem',
                        fontWeight: 'bold',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                        border: '2px solid white'
                      }}
                    >
                      {selectedCount}
                    </Box>
                  )}
                  <CardContent sx={{ 
                    textAlign: 'center', 
                    position: 'relative',
                    p: 1,
                    '&:last-child': { 
                      pb: 1 
                    }
                  }}>
                    <Icon sx={{ fontSize: 32, color: category.color, mb: 0.5 }} />
                    <Typography 
                      variant="caption" 
                      component="div" 
                      sx={{ 
                        mt: 0.5,
                        fontSize: '0.7rem',
                        lineHeight: 1.2
                      }}
                    >
                      {category.label}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>

        {/* Action Buttons */}
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            onClick={() => navigate('/perceptionmateriels')}
            disabled={loading}
          >
            Annuler
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Enregistrer les modifications'}
          </Button>
        </Box>
      </form>

      {/* Material Selection Dialog */}
      <Dialog 
        open={openDialog} 
        onClose={handleCloseDialog}
        fullScreen={isMobile}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {selectedCategory && (
            <Box display="flex" alignItems="center">
              <selectedCategory.icon sx={{ color: selectedCategory.color, mr: 1 }} />
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {selectedCategory.label}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleCloseDialog}
                sx={{ color: theme.palette.grey[500] }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <List>
            {filteredMateriels.map((materiel) => {
              const isSelected = selectedItems[selectedCategory?.id]?.[materiel._id]?.selected;
              const quantity = selectedItems[selectedCategory?.id]?.[materiel._id]?.quantity || 1;

              return (
                <Paper 
                  key={materiel._id} 
                  elevation={1} 
                  sx={{ 
                    mb: 1,
                    backgroundColor: isSelected ? 'action.selected' : 'background.paper'
                  }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar src={materiel.imageperception} />
                    </ListItemAvatar>
                    <ListItemText 
                      primary={materiel.nom}
                      secondary={materiel.description}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      {isSelected && (
                        <TextField
                          type="number"
                          label="Qté"
                          value={quantity}
                          onChange={(e) => handleQuantityChange(materiel._id, e.target.value)}
                          size="small"
                          sx={{ width: 70 }}
                        />
                      )}
                      <Checkbox
                        checked={isSelected || false}
                        onChange={() => handleItemSelection(materiel._id)}
                      />
                    </Box>
                  </ListItem>
                </Paper>
              );
            })}
          </List>
        </DialogContent>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={error ? 'error' : 'success'}
          variant="filled"
        >
          {error || success}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EditPerception;