import React, { useState, useEffect } from 'react';
import axios from 'axios';
import authHeader from "../auth-header";
import Datepicker from "tailwind-datepicker-react";
import { Link } from 'react-router-dom';
import { CalendarIcon, ArrowLeftIcon, CheckCircleIcon } from '@heroicons/react/24/outline';

const ReservationForm = () => {
  const [idvhl, setIdVHL] = useState(null);
  const [nomvhl, setVHL] = useState('');
  const [service, setService] = useState('');
  const [imagevhl, setImage] = useState('');
  const [dossardorigine, setDossard] = useState('');
  const [datedebut, setDateDebut] = useState('');
  const [datefin, setDateFin] = useState('');
  const [typereservation, setTypeResa] = useState('');
  const [nom, setNomResa] = useState('');
  const [vehicles, setVehicles] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [show, setShow] = useState(false);
  const [showEnd, setShowEnd] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const options = {
    autoHide: true,
    todayBtn: true,
    clearBtn: true,
    maxDate: new Date("2030-01-01"),
    minDate: new Date("2023-01-01"),
    theme: {
      background: "bg-white",
      todayBtn: "bg-blue-900 text-white hover:bg-blue-800",
      clearBtn: "bg-gray-200 hover:bg-gray-300",
      icons: "text-gray-500",
      text: "text-gray-900",
      disabledText: "text-gray-400",
      input: "bg-white border border-gray-300 rounded-lg",
      inputIcon: "text-gray-500",
      selected: "bg-blue-900 text-white"
    },
    icons: {
      prev: () => <span>Précédent</span>,
      next: () => <span>Suivant</span>,
    },
    datepickerClassNames: "top-12",
    defaultDate: new Date(),
    language: "fr",
    weekDays: ["Lu", "Ma", "Me", "Je", "Ve", "Sa", "Di"],
    inputNameProp: "date",
    inputIdProp: "date",
    inputPlaceholderProp: "Sélectionner une date",
    inputDateFormatProp: {
      day: "numeric",
      month: "long",
      year: "numeric"
    }
  };

  const handleChangeTypeResa = (e) => {
    setTypeResa(e.target.value);
  };

  const handleChangeNom = (e) => {
    setNomResa(e.target.value);
  };

  const filterVehicles = () => {
    const filteredVehicles = vehicles.filter((vehicle) => {
      const overlappingReservations = reservations.filter((reservation) => {
        const reservationStartDate = new Date(reservation.datedebut);
        const reservationEndDate = new Date(reservation.datefin);
        const selectedStartDate = new Date(datedebut);
        const selectedEndDate = new Date(datefin);
        return (
          (reservationStartDate <= selectedStartDate && reservationEndDate >= selectedStartDate) ||
          (reservationStartDate <= selectedEndDate && reservationEndDate >= selectedEndDate) ||
          (reservationStartDate >= selectedStartDate && reservationEndDate <= selectedEndDate)
        );
      });
      return !overlappingReservations.some((reservation) => reservation.idvhl === vehicle._id);
    });

    const filterVehicles2 = filteredVehicles.filter((vehicle) => {
      if (currentUser.service === "FOR") {
        return vehicle.service === currentUser.service && vehicle.typedevhl === "Véhicules de liaison";
      }
      if (currentUser.service === "CNFIS") {
        return vehicle.service === currentUser.service && vehicle.typedevhl === "CPI";
      }
      if (currentUser.service === "CAB") {
        return vehicle.service === currentUser.service && vehicle.typedevhl === "Véhicules de liaison";
      }
      return vehicle.service === currentUser.service;
    });

    setFilteredVehicles(filterVehicles2);
  };

  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL + '/api/vhl/', { headers: authHeader() })
      .then((response) => {
        setVehicles(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL + '/api/reservations/', { headers: authHeader() })
      .then((response) => {
        setReservations(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    filterVehicles();
  }, [vehicles, reservations, datedebut, datefin]);

  const handleDateStartClick = () => {
    setShow(true);
  };

  const handleDateEndClick = () => {
    setShowEnd(true);
  };

  const handleDateChange = (date, field) => {
    if (field === 'datedebut') {
      setDateDebut(date);
    } else if (field === 'datefin') {
      setDateFin(date);
    }
  };

  const handleVehicleSelect = (vehicle) => {
    setIdVHL(vehicle._id);
    setVHL(vehicle.nomvhl);
    setImage(vehicle.image);
    setDossard(vehicle.dossardorigine);
    setService(vehicle.service);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (idvhl && typereservation && nom) {
      const reservation = {
        idvhl,
        nomvhl,
        service,
        imagevhl,
        dossardorigine,
        typereservation,
        datedebut,
        datefin,
        nom,
      };

      axios.post(process.env.REACT_APP_BASE_URL + '/api/reservations/add', reservation, { headers: authHeader() })
        .then((res) => {
          console.log(res.data);
          window.location = '/reservations';
        })
        .catch((error) => {
          console.error(error);
        });

      
    } else {
      console.log('Veuillez remplir tous les champs obligatoires');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* En-tête */}
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <Link
                to="/reservations"
                className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700"
              >
                <ArrowLeftIcon className="h-4 w-4 mr-1" />
                Retour
              </Link>
              <h1 className="text-2xl font-bold text-gray-900">Nouvelle réservation</h1>
            </div>
          </div>
        </div>

        {/* Formulaire */}
        <div className="bg-white shadow-sm ring-1 ring-gray-900/5 rounded-xl overflow-hidden">
          <form onSubmit={handleSubmit} className="space-y-6 p-6">
            {/* Période de réservation */}
            <div className="border-b border-gray-200 pb-6">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">
                Période de réservation
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Date de début
                  </label>
                  <Datepicker
                    options={options}
                    value={datedebut}
                    onChange={(date) => handleDateChange(date, 'datedebut')}
                    show={show}
                    setShow={setShow}
                    onClick={handleDateStartClick}
                  />
                </div>
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Date de fin
                  </label>
                  <Datepicker
                    options={options}
                    value={datefin}
                    onChange={(date) => handleDateChange(date, 'datefin')}
                    show={showEnd}
                    setShow={setShowEnd}
                    onClick={handleDateEndClick}
                  />
                </div>
              </div>
            </div>

            {/* Liste des véhicules */}
            {datedebut && datefin && (
              <div className="border-b border-gray-200 pb-6">
                <h2 className="text-lg font-semibold text-gray-900 mb-4">
                  Véhicules disponibles
                </h2>
                <div className="space-y-2">
                  {filteredVehicles.length === 0 ? (
                    <p className="text-sm text-gray-500 text-center py-4">
                      Aucun véhicule disponible pour cette période
                    </p>
                  ) : (
                    <div className="grid gap-4 sm:grid-cols-2">
                      {filteredVehicles.map((vhl) => (
                        <label
                          key={vhl._id}
                          className={`relative flex items-center p-4 cursor-pointer rounded-lg border ${
                            idvhl === vhl._id
                              ? 'border-blue-900 ring-2 ring-blue-900'
                              : 'border-gray-200 hover:border-gray-300'
                          }`}
                        >
                          <input
                            type="radio"
                            name="selectedVehicle"
                            value={vhl._id}
                            className="sr-only"
                            onChange={() => handleVehicleSelect(vhl)}
                            checked={idvhl === vhl._id}
                          />
                          <div className="flex items-center flex-1 min-w-0">
                            <img
                              src={vhl.image}
                              alt={vhl.nomvhl}
                              className="h-12 w-12 flex-shrink-0 rounded-lg object-cover"
                            />
                            <div className="ml-4 flex-1 min-w-0">
                              <p className="text-sm font-medium text-gray-900 truncate">
                                {vhl.nomvhl}
                              </p>
                              {vhl.dossardorigine && (
                                <p className="text-sm text-gray-500 truncate">
                                  {vhl.dossardorigine}
                                </p>
                              )}
                            </div>
                          </div>
                          {idvhl === vhl._id && (
                            <CheckCircleIcon className="h-5 w-5 text-blue-900" />
                          )}
                        </label>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Informations complémentaires */}
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Type de réservation
                </label>
                <input
                  type="text"
                  required
                  className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-900 focus:ring-blue-900 sm:text-sm"
                  value={typereservation}
                  onChange={handleChangeTypeResa}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Nom
                </label>
                <input
                  type="text"
                  required
                  className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-900 focus:ring-blue-900 sm:text-sm"
                  value={nom}
                  onChange={handleChangeNom}
                />
              </div>
            </div>

            {/* Bouton de soumission */}
            <div className="flex justify-end pt-6">
              <button
                type="submit"
                disabled={!idvhl || !typereservation || !nom}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <CalendarIcon className="h-5 w-5 mr-2" />
                Créer la réservation
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReservationForm;
