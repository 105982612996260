import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Checkbox,
  Badge,
  Paper,
  Divider,
  useTheme,
  useMediaQuery
} from '@mui/material';

// Icons
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import KeyIcon from '@mui/icons-material/Key';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import MicIcon from '@mui/icons-material/Mic';
import ForestIcon from '@mui/icons-material/Forest';
import MasksIcon from '@mui/icons-material/Masks';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ConstructionIcon from '@mui/icons-material/Construction';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';

import authHeader from '../../auth-header';
import { groupBy } from 'lodash';

const categories = [
  { id: 'Image', label: 'Image', icon: CameraAltIcon, color: '#4CAF50' },
  { id: 'COF / Perben', label: 'COF / Perben', icon: KeyIcon, color: '#2196F3' },
  { id: 'Rens aérien / Drones', label: 'Rens aérien / Drone', icon: AirplanemodeActiveIcon, color: '#9C27B0' },
  { id: 'Micro', label: 'Micro', icon: MicIcon, color: '#F44336' },
  { id: 'Verte', label: 'Verte', icon: ForestIcon, color: '#4CAF50' },
  { id: 'CNP', label: 'CNP', icon: MasksIcon, color: '#FF9800' },
  { id: 'Interpel / Armement', label: 'Interpel / Armement', icon: LocalFireDepartmentIcon, color: '#795548' },
  { id: 'Mobilité', label: 'Mobilité', icon: DirectionsCarIcon, color: '#607D8B' },
  { id: 'Divers', label: 'Divers', icon: ConstructionIcon, color: '#9E9E9E' },
  { id: 'SIC', label: 'SIC', icon: SettingsInputAntennaIcon, color: '#3F51B5' },
  { id: 'Track', label: 'Track', icon: LocationOnIcon, color: '#E91E63' }
];

const NewPerception = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [formData, setFormData] = useState({
    typePerception: '',
    nomPerception: '',
    remarques: ''
  });
  const [materiels, setMateriels] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [materielResponse, userResponse] = await Promise.all([
          axios.get(process.env.REACT_APP_BASE_URL + '/api/materiels/', { headers: authHeader() }),
          axios.get(process.env.REACT_APP_BASE_URL + '/api/users/CurrentUser', { headers: authHeader() })
        ]);
        setMateriels(materielResponse.data);
        setCurrentUser(userResponse.data);
      } catch (err) {
        setError('Erreur lors du chargement des données');
      }
    };
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedCategory(null);
  };

  const handleItemSelection = (itemId) => {
    setSelectedItems(prev => ({
      ...prev,
      [selectedCategory.id]: {
        ...(prev[selectedCategory.id] || {}),
        [itemId]: {
          selected: !(prev[selectedCategory.id]?.[itemId]?.selected || false),
          quantity: prev[selectedCategory.id]?.[itemId]?.quantity || 1
        }
      }
    }));
  };

  const handleQuantityChange = (itemId, value) => {
    setSelectedItems(prev => ({
      ...prev,
      [selectedCategory.id]: {
        ...(prev[selectedCategory.id] || {}),
        [itemId]: {
          ...prev[selectedCategory.id][itemId],
          quantity: Math.max(1, parseInt(value) || 1)
        }
      }
    }));
  };

  const getSelectedItemsCount = (categoryId) => {
    return Object.values(selectedItems[categoryId] || {})
      .filter(item => item.selected)
      .length;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!formData.nomPerception || !formData.typePerception) {
      setError('Veuillez remplir tous les champs obligatoires');
      return;
    }

    try {
      // Créer un objet avec toutes les catégories initialisées à vide
      const categoriesData = {
        image: [],
        cof: [],
        drone: [],
        micro: [],
        verte: [],
        cnp: [],
        interpel: [],
        mobilite: [],
        divers: [],
        sic: [],
        track: []
      };

      // Remplir les catégories avec les éléments sélectionnés
      Object.entries(selectedItems).forEach(([category, items]) => {
        // Mapping des catégories vers les clés du backend
        const categoryMapping = {
          'Image': 'image',
          'COF / Perben': 'cof',
          'Rens aérien / Drones': 'drone',
          'Micro': 'micro',
          'Verte': 'verte',
          'CNP': 'cnp',
          'Interpel / Armement': 'interpel',
          'Mobilité': 'mobilite',
          'Divers': 'divers',
          'SIC': 'sic',
          'Track': 'track'
        };

        const categoryKey = categoryMapping[category];
        if (categoriesData.hasOwnProperty(categoryKey)) {
          categoriesData[categoryKey] = Object.entries(items)
            .filter(([_, item]) => item.selected)
            .map(([itemId]) => {
              const materiel = materiels.find(m => m._id === itemId);
              return {
                materiel: {
                  _id: materiel._id,
                  nom: materiel.nom,
                  image: materiel.image,
                  nbperception: items[itemId].quantity,
                  imageperception: materiel.imageperception,
                }
              };
            });
        }
      });

      const perceptionData = {
        nomperception: formData.nomPerception,
        typeperception: formData.typePerception,
        remarques: formData.remarques,
        date: new Date(),
        ...categoriesData
      };

      await axios.post(
        process.env.REACT_APP_BASE_URL + '/api/perceptionmateriel/add',
        perceptionData,
        { headers: authHeader() }
      );

      setSuccess('Perception créée avec succès');
      window.location = '/materiel';
    } catch (err) {
      setError('Erreur lors de la création de la perception');
      console.error(err);
    }
  };

  const renderCategoryGrid = () => (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      {categories.map((category) => {
        const selectedCount = getSelectedItemsCount(category.id);
        const Icon = category.icon;
        
        return (
          <Grid item xs={6} sm={4} md={3} key={category.id}>
            <Card 
              onClick={() => handleCategoryClick(category)}
              sx={{
                cursor: 'pointer',
                height: '100%',
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'scale(1.02)',
                }
              }}
            >
              <CardContent sx={{ textAlign: 'center', position: 'relative' }}>
                {selectedCount > 0 && (
                  <Badge
                    badgeContent={selectedCount}
                    color="primary"
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                    }}
                  />
                )}
                <Icon sx={{ fontSize: 40, color: category.color, mb: 1 }} />
                <Typography variant="body2" component="div">
                  {category.label}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );

  const renderDialog = () => {
    if (!selectedCategory) return null;

    const filteredMateriels = materiels.filter(
      m => m.categorie === selectedCategory.id
    );

    return (
      <Dialog 
        open={openDialog} 
        onClose={handleCloseDialog}
        fullScreen={isMobile}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Box display="flex" alignItems="center">
            <selectedCategory.icon sx={{ color: selectedCategory.color, mr: 1 }} />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {selectedCategory.label}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{ color: theme.palette.grey[500] }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <List>
            {filteredMateriels.map((materiel) => {
              const isSelected = selectedItems[selectedCategory.id]?.[materiel._id]?.selected;
              const quantity = selectedItems[selectedCategory.id]?.[materiel._id]?.quantity || 1;

              return (
                <Paper 
                  key={materiel._id} 
                  elevation={1} 
                  sx={{ 
                    mb: 1,
                    backgroundColor: isSelected ? 'action.selected' : 'background.paper'
                  }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar src={materiel.imageperception} />
                    </ListItemAvatar>
                    <ListItemText 
                      primary={materiel.nom}
                      secondary={materiel.description}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      {isSelected && (
                        <TextField
                          type="number"
                          label="Qté"
                          value={quantity}
                          onChange={(e) => handleQuantityChange(materiel._id, e.target.value)}
                          size="small"
                          sx={{ width: 70 }}
                        />
                      )}
                      <Checkbox
                        checked={isSelected || false}
                        onChange={() => handleItemSelection(materiel._id)}
                      />
                    </Box>
                  </ListItem>
                </Paper>
              );
            })}
          </List>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', p: 3 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Nouvelle Perception
        </Typography>

        {error && (
          <Box sx={{ mb: 2, p: 2, bgcolor: 'error.light', borderRadius: 1 }}>
            <Typography color="error">{error}</Typography>
          </Box>
        )}

        {success && (
          <Box sx={{ mb: 2, p: 2, bgcolor: 'success.light', borderRadius: 1 }}>
            <Typography color="success.dark">{success}</Typography>
          </Box>
        )}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Nom de la perception"
                name="nomPerception"
                value={formData.nomPerception}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel>Type de perception</InputLabel>
                <Select
                  name="typePerception"
                  value={formData.typePerception}
                  onChange={handleInputChange}
                  label="Type de perception"
                >
                  <MenuItem value="Mission">Mission</MenuItem>
                  <MenuItem value="Exercice">Exercice</MenuItem>
                  <MenuItem value="Maintenance">Maintenance</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Remarques"
                name="remarques"
                value={formData.remarques}
                onChange={handleInputChange}
                multiline
                rows={4}
              />
            </Grid>
          </Grid>

          <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
            Sélection des matériels
          </Typography>

          {renderCategoryGrid()}
          {renderDialog()}

          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button
              variant="outlined"
              onClick={() => window.history.back()}
            >
              Annuler
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Créer la perception
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default NewPerception;
