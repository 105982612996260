import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import "react-datepicker/dist/react-datepicker.css";
import {
  Avatar,
  Box,
  Typography,
  ListItemButton,
  ListItemText,
  Tabs,
  Tab,
  Card,
  CardMedia,
  CardContent,
  Button,
  List
} from "@mui/material";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import DeleteIcon from "@mui/icons-material/Delete";
import KeyIcon from '@mui/icons-material/Key';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import MicIcon from '@mui/icons-material/Mic';
import ForestIcon from '@mui/icons-material/Forest';
import MasksIcon from '@mui/icons-material/Masks';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ConstructionIcon from '@mui/icons-material/Construction';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import authHeader from "../auth-header";

const label = { inputProps: { 'aria-label': 'Switch' } };

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function DetailHistoPerception(props) {
  const [perception, setPerception] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState({});
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const { id } = useParams();
  

  useEffect(() => {
    if (isLoading) {
      axios
        .get(process.env.REACT_APP_BASE_URL + "/api/histoperceptionmateriel/" + id, { headers: authHeader() })
        .then((response) => {
          setPerception(response.data);

        })
        .catch((error) => {
          console.log(error);
        });
      setisLoading(false);
    }
  }, [id, isLoading]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const deletePerception = (id) => {
    axios
      .delete(process.env.REACT_APP_BASE_URL + "/api/histoperceptionmateriel/" + id, { headers: authHeader() })
      .then((res) => {
        window.location = "/listhistoperceptions";
      })
  };


  const PerceptionList = ({ list }) => {
    if (!list || list.length === 0) {
      return (
        <Box 
          sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            py: 4,
            color: 'text.secondary'
          }}
        >
          <Typography variant="body1">
            Aucun matériel dans cette catégorie
          </Typography>
        </Box>
      );
    }

    return (
      <List sx={{ width: '100%', p: 0 }}>
        {list.map((item, index) => (
          <ListItemButton
            key={index}
            component={Link}
            to={`/pagemateriel/${item.materiel._id}`}
            sx={{
              mb: 1,
              borderRadius: 1,
              bgcolor: 'background.paper',
              transition: 'all 0.2s',
              '&:hover': {
                bgcolor: 'action.hover',
                transform: 'translateX(8px)'
              }
            }}
          >
            <Avatar
              alt={item.materiel.nom}
              src={item.materiel.imageperception}
              sx={{
                width: { xs: 40, sm: 48 },
                height: { xs: 40, sm: 48 },
                mr: 2,
                bgcolor: 'primary.main'
              }}
            />
            <ListItemText
              primary={item.materiel.nom}
              secondary={`Quantité : ${item.materiel.nbperception}`}
              primaryTypographyProps={{
                variant: 'subtitle1',
                fontWeight: 'medium'
              }}
              secondaryTypographyProps={{
                variant: 'body2',
                color: 'text.secondary'
              }}
            />
          </ListItemButton>
        ))}
      </List>
    );
  };

  return (
    <Box sx={{ maxWidth: '1200px', mx: 'auto', p: { xs: 1, sm: 2, md: 3 } }}>
      {/* Header Card */}
      <Card 
        elevation={3}
        sx={{ 
          mb: 2,
          background: 'linear-gradient(135deg, rgba(26, 35, 126, 0.8) 0%, rgba(13, 71, 161, 0.8) 100%)',
          color: 'white',
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            opacity: 0.2
          }}
        />
        <CardContent sx={{ position: 'relative', p: { xs: 2, sm: 3 } }}>
          <Typography 
            variant="h4" 
            component="h1" 
            gutterBottom 
            sx={{ 
              fontWeight: 'bold',
              fontSize: { xs: '1.5rem', sm: '2rem' },
              textAlign: 'center',
              mb: 2
            }}
          >
            {perception.nomperception}
          </Typography>
          
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            mb: 2
          }}>
            <Typography variant="h6" sx={{ opacity: 0.9 }}>
              {perception.typeperception}
            </Typography>
            <Box 
              sx={{ 
                display: { xs: 'none', sm: 'block' },
                width: '4px',
                height: '4px',
                borderRadius: '50%',
                bgcolor: 'white',
                opacity: 0.5
              }} 
            />
            <Typography variant="h8" sx={{ opacity: 0.9 }}>
              Réintégré le {new Date(perception.date).toLocaleDateString()}
            </Typography>
          </Box>
          
          {perception.remarques && (
            <Typography 
              variant="body1" 
              sx={{ 
                mt: 2,
                textAlign: 'center',
                opacity: 0.8,
                fontStyle: 'italic'
              }}
            >
              {perception.remarques}
            </Typography>
          )}
        </CardContent>
      </Card>

      {/* Admin Delete Button */}
      {currentUser.roles === "Admin" && (
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => {
              if (window.confirm("Êtes-vous sûr de vouloir supprimer ce matériel?")) {
                deletePerception(perception._id);
              }
            }}
            sx={{ 
              px: 3,
              py: 1,
              borderRadius: 1,
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'error.dark'
              }
            }}
          >
            Supprimer
          </Button>
        </Box>
      )}

      {/* Main Content */}
      <Card elevation={2}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' },
          minHeight: { xs: 'auto', md: 500 } 
        }}>
          {/* Categories */}
          <Box sx={{ 
            width: { xs: '100%', md: '250px' },
            borderRight: { xs: 0, md: 1 },
            borderBottom: { xs: 1, md: 0 },
            borderColor: 'divider'
          }}>
            <Tabs
              orientation={{ xs: 'horizontal', md: 'vertical' }}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              value={value}
              onChange={handleChange}
              aria-label="Catégories de matériels"
              sx={{
                '& .MuiTab-root': {
                  minHeight: { xs: 60, md: 48 },
                  py: { xs: 1, md: 1.5 },
                  px: 2,
                  alignItems: 'center',
                  justifyContent: { xs: 'center', md: 'flex-start' },
                  textAlign: { xs: 'center', md: 'left' },
                  '&.Mui-selected': {
                    color: 'primary.main',
                    bgcolor: 'action.selected'
                  }
                }
              }}
            >
              <Tab
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CameraAltIcon sx={{ fontSize: 20 }} />
                    <Typography sx={{ display: { xs: 'none', sm: 'block' } }}>Image</Typography>
                  </Box>
                }
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <KeyIcon sx={{ fontSize: 20 }} />
                    <Typography sx={{ display: { xs: 'none', sm: 'block' } }}>COF</Typography>
                  </Box>
                }
                {...a11yProps(1)}
              />
              <Tab
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <AirplanemodeActiveIcon sx={{ fontSize: 20 }} />
                    <Typography sx={{ display: { xs: 'none', sm: 'block' } }}>Drone</Typography>
                  </Box>
                }
                {...a11yProps(2)}
              />
              <Tab
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <MicIcon sx={{ fontSize: 20 }} />
                    <Typography sx={{ display: { xs: 'none', sm: 'block' } }}>Micro</Typography>
                  </Box>
                }
                {...a11yProps(3)}
              />
              <Tab
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <ForestIcon sx={{ fontSize: 20 }} />
                    <Typography sx={{ display: { xs: 'none', sm: 'block' } }}>Verte</Typography>
                  </Box>
                }
                {...a11yProps(4)}
              />
              <Tab
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <MasksIcon sx={{ fontSize: 20 }} />
                    <Typography sx={{ display: { xs: 'none', sm: 'block' } }}>CNP</Typography>
                  </Box>
                }
                {...a11yProps(5)}
              />
              <Tab
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <LocalFireDepartmentIcon sx={{ fontSize: 20 }} />
                    <Typography sx={{ display: { xs: 'none', sm: 'block' } }}>Interpel</Typography>
                  </Box>
                }
                {...a11yProps(6)}
              />
              <Tab
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <DirectionsCarIcon sx={{ fontSize: 20 }} />
                    <Typography sx={{ display: { xs: 'none', sm: 'block' } }}>Mobilité</Typography>
                  </Box>
                }
                {...a11yProps(7)}
              />
              <Tab
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <ConstructionIcon sx={{ fontSize: 20 }} />
                    <Typography sx={{ display: { xs: 'none', sm: 'block' } }}>Divers</Typography>
                  </Box>
                }
                {...a11yProps(8)}
              />
              <Tab
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <SettingsInputAntennaIcon sx={{ fontSize: 20 }} />
                    <Typography sx={{ display: { xs: 'none', sm: 'block' } }}>SIC</Typography>
                  </Box>
                }
                {...a11yProps(9)}
              />
              <Tab
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <LocationOnIcon sx={{ fontSize: 20 }} />
                    <Typography sx={{ display: { xs: 'none', sm: 'block' } }}>Track</Typography>
                  </Box>
                }
                {...a11yProps(10)}
              />
            </Tabs>
          </Box>

          {/* Content */}
          <Box sx={{ flexGrow: 1, p: { xs: 2, sm: 3 } }}>
            <TabPanel value={value} index={0}>
              <PerceptionList list={perception.image} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <PerceptionList list={perception.cof} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <PerceptionList list={perception.drone} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <PerceptionList list={perception.micro} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <PerceptionList list={perception.verte} />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <PerceptionList list={perception.cnp} />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <PerceptionList list={perception.interpel} />
            </TabPanel>
            <TabPanel value={value} index={7}>
              <PerceptionList list={perception.mobilite} />
            </TabPanel>
            <TabPanel value={value} index={8}>
              <PerceptionList list={perception.divers} />
            </TabPanel>
            <TabPanel value={value} index={9}>
              <PerceptionList list={perception.sic} />
            </TabPanel>
            <TabPanel value={value} index={10}>
              <PerceptionList list={perception.track} />
            </TabPanel>
          </Box>
        </Box>
      </Card>

    </Box>
  );
}

export default DetailHistoPerception;
