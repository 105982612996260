import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import authHeader from "../auth-header";

export default function DetailMateriel() {
  const [materiel, setMateriel] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/materiels/" + id, {
        headers: authHeader(),
      })
      .then((response) => {
        setMateriel(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  if (!materiel) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  const getAlerteBadgeColor = (alerte) => {
    switch (alerte) {
      case "Alerte 1":
        return "bg-red-100 text-red-800";
      case "Alerte 2":
        return "bg-orange-100 text-orange-800";
      default:
        return "bg-green-100 text-green-800";
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header avec bouton retour */}
        <div className="mb-6">
          <button
            onClick={() => window.history.back()}
            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <svg
              className="-ml-1 mr-2 h-5 w-5 text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
            Retour
          </button>
        </div>

        {/* Carte principale */}
        <div className="bg-white shadow rounded-lg overflow-hidden">
          {/* En-tête avec image */}
          <div className="relative h-64 sm:h-80">
            <img
              src={materiel.image}
              alt={materiel.nom}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-30" />
            <div className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-black">
              <div className="flex items-center justify-between">
                <h1 className="text-2xl font-bold text-white">{materiel.nom}</h1>
                <span
                  className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${getAlerteBadgeColor(
                    materiel.alerte
                  )}`}
                >
                  {materiel.alerte}
                </span>
              </div>
            </div>
          </div>

          {/* Contenu */}
          <div className="px-6 py-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Informations principales */}
              <div className="space-y-4">
                <div>
                  <h2 className="text-sm font-semibold text-gray-500">Catégorie</h2>
                  <p className="mt-1 text-base text-gray-900">{materiel.categorie}</p>
                </div>

                <div>
                  <h2 className="text-sm font-semibold text-gray-500">Emplacement</h2>
                  <p className="mt-1 text-base text-gray-900">{materiel.emplacement}</p>
                </div>

                <div>
                  <h2 className="text-sm font-semibold text-gray-500">Description</h2>
                  <p className="mt-1 text-base text-gray-900 whitespace-pre-line">
                    {materiel.remarques || "Aucune description disponible"}
                  </p>
                </div>
              </div>

                {/* Actions */}
                <div className="flex flex-col space-y-3">
                  <button
                    onClick={() => window.location.href = `/editmateriel/${materiel._id}`}
                    className="inline-flex justify-center items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <svg
                      className="-ml-1 mr-2 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                    </svg>
                    Modifier
                  </button>

                  <button
                    onClick={() => {
                      if (window.confirm("Êtes-vous sûr de vouloir supprimer ce matériel ?")) {
                        axios
                          .delete(
                            process.env.REACT_APP_BASE_URL + "/api/materiels/" + materiel._id,
                            { headers: authHeader() }
                          )
                          .then(() => {
                            window.location.href = "/materiel/toutmateriel";
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }
                    }}
                    className="inline-flex justify-center items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    <svg
                      className="-ml-1 mr-2 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Supprimer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
