import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import authHeader from '../auth-header';

export default function ListDmdGarage() {
  const [dmdGarage, setDmdGarage] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + '/api/dmdintervgarage/', { headers: authHeader() })
      .then((response) => {
        setDmdGarage(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get(process.env.REACT_APP_BASE_URL + '/api/users/CurrentUser', { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  function handleListItemClick(Id) {
    const vehicleToUpdate = dmdGarage.find((vehicle) => vehicle._id === Id);

    if (!vehicleToUpdate) {
      console.error(`Vehicle with ID ${Id} not found.`);
      return;
    }

    if (vehicleToUpdate.idusers.includes(currentUser.username)) {
      navigate(`/dmdintervgarage/${Id}`);
    } else {
      const updatedIdUsers = [...vehicleToUpdate.idusers, currentUser.username];

      axios.post(`${process.env.REACT_APP_BASE_URL}/api/dmdintervgarage/update/${Id}`, { idusers: updatedIdUsers }, { headers: authHeader() })
        .then((res) => {
        console.log(res.data);
        const updatedDmdGarage = dmdGarage.map((vehicle) =>
            vehicle._id === Id ? { ...vehicle, idusers: updatedIdUsers } : vehicle
          );

        setDmdGarage(updatedDmdGarage)
        navigate(`/dmdintervgarage/${Id}`);
        
        })
        .catch((error) => {
        console.error(error);
        });  
    }
  }

  const filteredVehicles = dmdGarage.filter(
    (dmdGarage) => currentUser && dmdGarage.service === currentUser.service
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="px-4 py-4">
        <h3 className="text-xl font-semibold text-gray-900 mb-6">Véhicules en demande d'interv. GARAGE</h3>
        
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <ul className="divide-y divide-gray-200">
            {filteredVehicles.map((vhl) => (
              <li key={vhl._id}>
                <div onClick={() => handleListItemClick(vhl._id)} className="cursor-pointer">
                  <Link to={`/dmdintervgarage/${vhl._id}`} className="block hover:bg-gray-50 transition duration-150 ease-in-out">
                    <div className="flex items-center px-4 py-4 relative">
                      {!vhl.idusers.includes(currentUser.username) && (
                        <span className="absolute left-2 top-1/2 -translate-y-1/2 block h-3 w-3 rounded-full bg-green-400 ring-2 ring-white" />
                      )}
                      <div className="flex-shrink-0 ml-4">
                        <img className="h-12 w-12 rounded-lg object-cover" src={vhl.image} alt="" />
                      </div>
                      <div className="ml-4 flex-1 min-w-0">
                        <p className="text-sm font-bold text-gray-900 truncate">{vhl.nomvhl}</p>
                        <p className="mt-1 text-sm text-gray-500 italic truncate">{vhl.dossardorigine}</p>
                      </div>
                      <ChevronRightIcon className="h-5 w-5 text-gray-400 ml-4" aria-hidden="true" />
                    </div>
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
