import { React, useState, useEffect } from "react";
import { Button } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import axios from "axios";
import authHeader from "../auth-header";
import { CalendarDaysIcon } from "@heroicons/react/20/solid";

function HomeMateriel() {
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const logOut = () => {
    localStorage.removeItem("user");
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Hero Section */}
        <div className="relative rounded-2xl overflow-hidden mb-8 shadow-lg">
          <img 
            className="w-full h-50 object-cover object-center" 
            src="/images/homemateriel.png" 
            alt="Matériel"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-gray-900/75 to-transparent" />
          <div className="absolute bottom-0 left-0 right-0 p-6">
            <h1 className="text-3xl font-bold text-white mb-2">
              Inventaire FOR
            </h1>
          </div>
        </div>

        {/* Main Actions Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
          {/* Tout le matériel */}
          <button
            onClick={() => (window.location.href = "/materiel/toutmateriel")}
            className="relative group bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all duration-200 transform hover:scale-[1.02]"
          >
            <div className="flex items-center">
              <div className="flex-shrink-0 bg-blue-600 rounded-lg p-3">
                <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                </svg>
              </div>
              <div className="ml-4">
                <h2 className="text-lg font-semibold text-gray-900 text-left">Tout le matériel</h2>
                <p className="mt-1 text-sm text-gray-500">Voir l'inventaire complet</p>
              </div>
            </div>
            <div className="absolute inset-x-0 bottom-0 h-0.5 bg-blue-600 scale-x-0 group-hover:scale-x-100 transition-transform duration-200" />
          </button>

          {/* Nouvelle perception */}
          <button
            onClick={() => (window.location.href = "/newperception")}
            className="relative group bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all duration-200 transform hover:scale-[1.02]"
          >
            <div className="flex items-center">
              <div className="flex-shrink-0 bg-green-600 rounded-lg p-3">
                <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                </svg>
              </div>
              <div className="ml-4">
                <h2 className="text-lg font-semibold text-gray-900">Nouvelle perception</h2>
                <p className="mt-1 text-sm text-gray-500">Créer une nouvelle mission</p>
              </div>
            </div>
            <div className="absolute inset-x-0 bottom-0 h-0.5 bg-green-600 scale-x-0 group-hover:scale-x-100 transition-transform duration-200" />
          </button>

          {/* Perceptions en cours */}
          <button
            onClick={() => (window.location.href = "/listperceptions")}
            className="relative group bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all duration-200 transform hover:scale-[1.02]"
          >
            <div className="flex items-center">
              <div className="flex-shrink-0 bg-red-600 rounded-lg p-3">
                <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <div className="ml-4">
                <h2 className="text-lg font-semibold text-gray-900 text-left">Perceptions en cours</h2>
                <p className="mt-1 text-sm text-gray-500">Suivre les perceptions en cours</p>
              </div>
            </div>
            <div className="absolute inset-x-0 bottom-0 h-0.5 bg-red-600 scale-x-0 group-hover:scale-x-100 transition-transform duration-200" />
          </button>

          {/* Historique */}
          <button
            onClick={() => (window.location.href = "/listhistoperceptions")}
            className="relative group bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all duration-200 transform hover:scale-[1.02]"
          >
            <div className="flex items-center">
              <div className="flex-shrink-0 bg-indigo-600 rounded-lg p-3">
                <CalendarDaysIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <div className="ml-4">
                <h2 className="text-lg font-semibold text-gray-900 text-left">Historique</h2>
                <p className="mt-1 text-sm text-gray-500">Consulter l'historique des perceptions</p>
              </div>
            </div>
            <div className="absolute inset-x-0 bottom-0 h-0.5 bg-indigo-600 scale-x-0 group-hover:scale-x-100 transition-transform duration-200" />
          </button>
        </div>

        {/* Auth Section */}
        <div className="flex justify-center">
          {currentUser.username === undefined ? (
            <button
              onClick={() => (window.location.href = "/login")}
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200 transform hover:scale-105"
            >
              <svg className="mr-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
              </svg>
              Se connecter
            </button>
          ) : (
            <button
              onClick={() => {
                logOut();
                window.location.href = "/login";
              }}
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-lg text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-all duration-200 transform hover:scale-105"
            >
              <svg className="mr-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>
              Se déconnecter
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default HomeMateriel;
