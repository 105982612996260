import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpIcon, ChevronDownIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import authHeader from "./auth-header";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const DmdGarage = () => {
  const [idvhl, setIdVHL] = useState("");
  const [nomvhl, setVHL] = useState("");
  const [service, setService] = useState("");
  const [dossardorigine, setImmat] = useState("");
  const [km, setKm] = useState("");
  const [image, setImage] = useState("");
  const [typedemande, setTypeDmd] = useState("");
  const [degreurgence, setDegreUrgence] = useState("");
  const [nom, setNom] = useState("");
  const [nTPH, setNTPH] = useState("");
  const [positioncdb, setPositionCDB] = useState("");
  const [positionvhl, setPositionVHL] = useState("");
  const [details, setDetails] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [selectedVHL, setSelectedVHL] = useState({});
  const [selectedDMD, setSelectedDMD] = useState({});
  const [selectedUrgence, setSelectedUrgence] = useState({});

  const typedmd = [
    { id: 1, name: 'Révision' },
    { id: 2, name: 'Accident' },
    { id: 3, name: 'Autre' },
  ];

  const typeurgence = [
    { id: 1, name: 'Priorité mission' },
    { id: 2, name: 'Urgent' },
    { id: 3, name: 'Normal' },
  ];

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/vhl/", { headers: authHeader() })
      .then((response) => {
        setVehicles(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
      axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChangeVHL = (e) => {
    setSelectedVHL(e);
    const selectedVehicle = vehicles.find(
      (vehicle) => vehicle._id === e._id
    );
    if (selectedVehicle) {
      setIdVHL(selectedVehicle?._id || "");
      setService(selectedVehicle?.service || "");
      setVHL(selectedVehicle?.nomvhl || "");
      setImmat(selectedVehicle?.dossardorigine || "");
      setKm(selectedVehicle?.km || "");
      setImage(selectedVehicle?.image || "");
    }
  };

  const handleChangeKm = (e) => {
    setKm(e.target.value);
  };

  const handleChangeTypeDmd = (e) => {
    setTypeDmd(e.name);
    setSelectedDMD(e);
  };

  const handleChangeDegreUrgence = (e) => {
    setDegreUrgence(e.name);
    setSelectedUrgence(e);
  };

  const handleChangeNom = (e) => {
    setNom(e.target.value);
  };

  const handleChangeNTPH = (e) => {
    setNTPH(e.target.value);
  };

  const handleChangePositionCDB = (e) => {
    setPositionCDB(e.target.value);
  };

  const handleChangePositionVHL = (e) => {
    setPositionVHL(e.target.value);
  };

  const handleChangeDetails = (e) => {
    setDetails(e.target.value);
  };

  
  const onSubmit = async (e) => {
    e.preventDefault();
  
    try {
      
      const dmdgarage = {
        idvhl,
        nomvhl,
        service,
        dossardorigine,
        km,
        image,
        nom,
        typedemande,
        degreurgence,
        nTPH,
        positioncdb,
        positionvhl,
        details,
        idusers: currentUser.username,
      };
  
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/dmdintervgarage/add`,
        dmdgarage,
        {
          headers: authHeader(),
        }
      );
  
      window.location = "/";
    } catch (error) {
      console.error(error);
    }
  };
  

  const filteredVehicles = vehicles.filter(
    (vehicles) => currentUser && vehicles.service === currentUser.service
  );

  const sortedVehicles = filteredVehicles.slice().sort((a, b) => a.nomvhl.localeCompare(b.nomvhl));


  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 py-8">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <h3 className="text-2xl font-bold text-gray-900 mb-8">Demande d'intervention Garage</h3>
        
        <form onSubmit={onSubmit} className="space-y-8">
          {/* Sélection du véhicule */}
          <div>
            <label htmlFor="vhl" className="block text-sm font-semibold text-gray-700 mb-2">
              Véhicule
            </label>
            <Listbox value={selectedVHL} onChange={handleChangeVHL}>
              {({ open }) => (
                <div className="relative">
                  <Listbox.Button className="relative w-full cursor-pointer rounded-xl bg-white py-3 pl-4 pr-10 text-left border border-gray-300 hover:border-blue-500 transition-colors shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 sm:text-sm">
                    <span className="flex items-center">
                      <img 
                        src={selectedVHL.image} 
                        alt="" 
                        className="h-10 w-10 flex-none rounded-lg bg-gray-100 object-cover" 
                      />
                      <span className="ml-4 block font-semibold text-gray-900">
                        {selectedVHL.nomvhl || "Sélectionnez un véhicule"} 
                      </span>
                      {selectedVHL.dossardorigine && (
                        <span className="ml-3 text-sm text-gray-500 italic">
                          {selectedVHL.dossardorigine}
                        </span>
                      )}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-2 w-full overflow-auto rounded-xl bg-white py-1 text-base shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {sortedVehicles.map((vhl) => (
                        <Listbox.Option
                          key={vhl._id}
                          className={({ active }) =>
                            classNames(
                              active ? 'bg-blue-600 text-white' : 'text-gray-900',
                              'relative cursor-pointer select-none py-3 pl-4 pr-9 hover:bg-blue-50 hover:text-blue-600 transition-colors'
                            )
                          }
                          value={vhl}
                        >
                          {({ selected, active }) => (
                            <>
                              <div className="flex items-center">
                                <img 
                                  src={vhl.image} 
                                  alt="" 
                                  className="h-10 w-10 flex-none rounded-lg bg-gray-100 object-cover" 
                                />
                                <span className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'ml-4 block truncate'
                                )}>
                                  {vhl.nomvhl}
                                </span>
                                <span className="ml-3 text-sm text-gray-500 italic">
                                  {vhl.dossardorigine}
                                </span>
                              </div>

                              {selected && (
                                <span className={classNames(
                                  active ? 'text-white' : 'text-blue-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}>
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              )}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              )}
            </Listbox>
          </div>

          {/* Informations du véhicule */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div>
              <label htmlFor="km" className="block text-sm font-semibold text-gray-700 mb-2">
                Kilométrage
              </label>
              <input
                type="text"
                id="km"
                value={km}
                onChange={handleChangeKm}
                className="block w-full rounded-xl border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                required
              />
            </div>

            <div>
              <label htmlFor="nom" className="block text-sm font-semibold text-gray-700 mb-2">
                Nom
              </label>
              <input
                type="text"
                id="nom"
                value={nom}
                onChange={handleChangeNom}
                className="block w-full rounded-xl border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                required
              />
            </div>

            <div>
              <label htmlFor="nTPH" className="block text-sm font-semibold text-gray-700 mb-2">
                N° de TPH
              </label>
              <input
                type="text"
                id="nTPH"
                value={nTPH}
                onChange={handleChangeNTPH}
                className="block w-full rounded-xl border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                required
              />
            </div>

            <div>
              <label htmlFor="positioncdb" className="block text-sm font-semibold text-gray-700 mb-2">
                Position du CDB
              </label>
              <input
                type="text"
                id="positioncdb"
                value={positioncdb}
                onChange={handleChangePositionCDB}
                className="block w-full rounded-xl border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                required
              />
            </div>

            <div>
              <label htmlFor="positionvhl" className="block text-sm font-semibold text-gray-700 mb-2">
                Position du VHL
              </label>
              <input
                type="text"
                id="positionvhl"
                value={positionvhl}
                onChange={handleChangePositionVHL}
                className="block w-full rounded-xl border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                required
              />
            </div>
          </div>

          {/* Type de demande et urgence */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-2">
                Type de demande
              </label>
              <Listbox value={selectedDMD} onChange={handleChangeTypeDmd}>
                {({ open }) => (
                  <div className="relative">
                    <Listbox.Button className="relative w-full cursor-pointer rounded-xl bg-white py-3 pl-4 pr-10 text-left border border-gray-300 hover:border-blue-500 transition-colors shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 sm:text-sm">
                      <span className="block truncate">
                        {selectedDMD.name || "Sélectionnez un type"}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-2 w-full overflow-auto rounded-xl bg-white py-1 text-base shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {typedmd.map((type) => (
                          <Listbox.Option
                            key={type.id}
                            className={({ active }) =>
                              classNames(
                                active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                'relative cursor-pointer select-none py-3 pl-4 pr-9 hover:bg-blue-50 hover:text-blue-600 transition-colors'
                              )
                            }
                            value={type}
                          >
                            {({ selected, active }) => (
                              <>
                                <span className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate'
                                )}>
                                  {type.name}
                                </span>
                                {selected && (
                                  <span className={classNames(
                                    active ? 'text-white' : 'text-blue-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}>
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                )}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                )}
              </Listbox>
            </div>

            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-2">
                Degré d'urgence
              </label>
              <Listbox value={selectedUrgence} onChange={handleChangeDegreUrgence}>
                {({ open }) => (
                  <div className="relative">
                    <Listbox.Button className="relative w-full cursor-pointer rounded-xl bg-white py-3 pl-4 pr-10 text-left border border-gray-300 hover:border-blue-500 transition-colors shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 sm:text-sm">
                      <span className="block truncate">
                        {selectedUrgence.name || "Sélectionnez une urgence"}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-2 w-full overflow-auto rounded-xl bg-white py-1 text-base shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {typeurgence.map((urgence) => (
                          <Listbox.Option
                            key={urgence.id}
                            className={({ active }) =>
                              classNames(
                                active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                'relative cursor-pointer select-none py-3 pl-4 pr-9 hover:bg-blue-50 hover:text-blue-600 transition-colors'
                              )
                            }
                            value={urgence}
                          >
                            {({ selected, active }) => (
                              <>
                                <span className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate'
                                )}>
                                  {urgence.name}
                                </span>
                                {selected && (
                                  <span className={classNames(
                                    active ? 'text-white' : 'text-blue-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}>
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                )}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                )}
              </Listbox>
            </div>
          </div>

          {/* Détails */}
          <div>
            <label htmlFor="details" className="block text-sm font-semibold text-gray-700 mb-2">
              Détails / Explications
            </label>
            <textarea
              id="details"
              rows={4}
              value={details}
              onChange={handleChangeDetails}
              className="block w-full rounded-xl border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm resize-none"
              placeholder="Ajoutez des détails sur la demande d'intervention..."
              required
            />
          </div>

          {/* Bouton de soumission */}
          <div className="flex justify-center">
            <button
              type="submit"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-xl shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
            >
              Soumettre la demande
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DmdGarage;