import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import {
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import authHeader from "../auth-header";

function CreateVHL() {
  const [state, setState] = useState({
    nomvhl: "",
    typedevhl: "",
    service: "",
    dossardorigine: "",
    km: "",
    image: "",
    position: "",
    emploi: "",
    utilisateur: "",
    prochainerevision: "",
    remarques: "",
    dossardbana: "",
    identitebana: "",
    dossardcompl: "",
    plaquevelcro: false,
    majradio: "",
    chargeurintelligent: false,
    attelage: false,
    balise: false,
    pneusavant: "",
    pneusarriere: "",
    taillepneus: "",
    carburant: "",
    adblue: false,
    taillevhl: "",
    poidsvhl: "",
    pneusneige: false,
    currentUser: "",
    carteEssSchell: "",
    dateCarteEssSchell: null,
    carteEssTotal: "",
    dateCarteEssTotal: null,
  });

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setState((prevState) => ({
          ...prevState,
          currentUser: response.data,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const vhl = { ...state };

    axios
      .post(process.env.REACT_APP_BASE_URL + "/api/vhl/add", vhl, { headers: authHeader() })
      .then((res) => {
        console.log(res.data);
        window.location = "/pageadmin";
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const inputStyle = {
    marginBottom: "1rem",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "0.75rem",
      "&:hover fieldset": {
        borderColor: "rgb(59, 130, 246)",
      },
    },
    "& .MuiInputLabel-root": {
      color: "rgb(55, 65, 81)",
    },
  };

  const checkboxStyle = {
    marginBottom: "1rem",
    width: "100%",
    "& .MuiFormControlLabel-label": {
      color: "rgb(55, 65, 81)",
      fontSize: "0.875rem",
      fontWeight: 500,
    },
  };

  return (
    state.currentUser.roles === "Admin" && (
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 py-8">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-8">Ajouter un nouveau véhicule</h2>
          
          <div className="bg-white rounded-xl shadow-sm p-6 sm:p-8">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Informations principales */}
                <div className="space-y-6">
                  <TextField
                    label="Nom du véhicule"
                    name="nomvhl"
                    value={state.nomvhl}
                    onChange={handleChange}
                    sx={inputStyle}
                    required
                  />

                  <TextField
                    label="Image (URL)"
                    name="image"
                    value={state.image}
                    onChange={handleChange}
                    sx={inputStyle}
                  />

                  <FormControl sx={inputStyle}>
                    <InputLabel>Service</InputLabel>
                    <Select
                      name="service"
                      value={state.service}
                      onChange={handleChange}
                      label="Service"
                      required
                    >
                      <MenuItem value="FOR">FOR</MenuItem>
                      <MenuItem value="FSP">FSP</MenuItem>
                      <MenuItem value="FI">FI</MenuItem>
                      <MenuItem value="SMS">SMS</MenuItem>
                      <MenuItem value="DT">DT</MenuItem>
                      <MenuItem value="CNFIS">CNFIS</MenuItem>
                      <MenuItem value="EMOPS">EMOPS</MenuItem>
                      <MenuItem value="EMSF">EMSF</MenuItem>
                      <MenuItem value="GARAGE">GARAGE</MenuItem>
                      <MenuItem value="R&D">R&D</MenuItem>
                      <MenuItem value="MAG">MAG</MenuItem>
                      <MenuItem value="FA">FA</MenuItem>
                      <MenuItem value="CAB">CAB</MenuItem>
                      <MenuItem value="AMS">AMS</MenuItem>
                      <MenuItem value="CYNO">CYNO</MenuItem>
                      <MenuItem value="DPA">DPA</MenuItem>
                      <MenuItem value="CIO">CIO</MenuItem>
                      <MenuItem value="CTAO">CTAO</MenuItem>
                      <MenuItem value="GAV">GAV</MenuItem>
                      <MenuItem value="XPRH">XPRH</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl sx={inputStyle}>
                    <InputLabel>Type de véhicule</InputLabel>
                    <Select
                      name="typedevhl"
                      value={state.typedevhl}
                      onChange={handleChange}
                      label="Type de véhicule"
                      required
                    >
                      <MenuItem value="Classique">Classique</MenuItem>
                      <MenuItem value="Track">Track</MenuItem>
                      <MenuItem value="Moto">Moto</MenuItem>
                      <MenuItem value="SUV">SUV</MenuItem>
                      <MenuItem value="Utilitaire">Utilitaire</MenuItem>
                      <MenuItem value="Blindé">Blindé</MenuItem>
                      <MenuItem value="IMSI">IMSI</MenuItem>
                      <MenuItem value="Brouilleur">Brouilleur</MenuItem>
                      <MenuItem value="Liaison">Liaison</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                {/* Informations secondaires */}
                <div className="space-y-6">
                  <TextField
                    label="Dossard d'origine"
                    name="dossardorigine"
                    value={state.dossardorigine}
                    onChange={handleChange}
                    sx={inputStyle}
                  />

                  <TextField
                    label="Kilométrage"
                    type="number"
                    name="km"
                    value={state.km}
                    onChange={handleChange}
                    sx={inputStyle}
                  />

                  <FormControl sx={inputStyle}>
                    <InputLabel>Carburant</InputLabel>
                    <Select
                      name="carburant"
                      value={state.carburant}
                      onChange={handleChange}
                      label="Carburant"
                    >
                      <MenuItem value="Diesel">Diesel</MenuItem>
                      <MenuItem value="Essence">Essence</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    label="Remarques"
                    multiline
                    rows={4}
                    name="remarques"
                    value={state.remarques}
                    onChange={handleChange}
                    sx={inputStyle}
                  />
                </div>
              </div>

              {/* Cartes carburant */}
              <div className="border-t border-gray-200 pt-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Cartes carburant</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-6">
                    <TextField
                      label="Carte Essence Shell"
                      name="carteEssSchell"
                      value={state.carteEssSchell}
                      onChange={handleChange}
                      sx={inputStyle}
                    />
                    <TextField
                      label="Expiration Carte Shell"
                      type="date"
                      name="dateCarteEssSchell"
                      value={state.dateCarteEssSchell}
                      onChange={handleChange}
                      sx={inputStyle}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div className="space-y-6">
                    <TextField
                      label="Carte Essence Total"
                      name="carteEssTotal"
                      value={state.carteEssTotal}
                      onChange={handleChange}
                      sx={inputStyle}
                    />
                    <TextField
                      label="Expiration Carte Total"
                      type="date"
                      name="dateCarteEssTotal"
                      value={state.dateCarteEssTotal}
                      onChange={handleChange}
                      sx={inputStyle}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
              </div>

              {/* Section FOR */}
              {state.currentUser.service === "FOR" && (
                <div className="border-t border-gray-200 pt-6">
                  <h3 className="text-lg font-semibold text-gray-900 mb-4">Informations spécifiques FOR</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="space-y-6">
                      <TextField
                        label="Dossard Bana Totale"
                        name="dossardbana"
                        value={state.dossardbana}
                        onChange={handleChange}
                        sx={inputStyle}
                      />
                      <TextField
                        label="Identité Bana Totale"
                        multiline
                        rows={4}
                        name="identitebana"
                        value={state.identitebana}
                        onChange={handleChange}
                        sx={inputStyle}
                      />
                      <TextField
                        label="Dossard complémentaire"
                        name="dossardcompl"
                        value={state.dossardcompl}
                        onChange={handleChange}
                        sx={inputStyle}
                      />
                    </div>
                    <div className="space-y-6">
                      <TextField
                        label="Pneus avants"
                        name="pneusavant"
                        value={state.pneusavant}
                        onChange={handleChange}
                        sx={inputStyle}
                      />
                      <TextField
                        label="Pneus arrières"
                        name="pneusarriere"
                        value={state.pneusarriere}
                        onChange={handleChange}
                        sx={inputStyle}
                      />
                      <TextField
                        label="Taille Pneus"
                        name="taillepneus"
                        value={state.taillepneus}
                        onChange={handleChange}
                        sx={inputStyle}
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
                    <div className="space-y-6">
                      <TextField
                        label="MAJ Crypto Radio"
                        name="majradio"
                        value={state.majradio}
                        onChange={handleChange}
                        sx={inputStyle}
                      />
                      <TextField
                        label="Taille VHL"
                        name="taillevhl"
                        value={state.taillevhl}
                        onChange={handleChange}
                        sx={inputStyle}
                      />
                      <TextField
                        label="Poids VHL"
                        name="poidsvhl"
                        value={state.poidsvhl}
                        onChange={handleChange}
                        sx={inputStyle}
                      />
                    </div>
                    <div className="space-y-4">
                      <FormControlLabel
                        control={<Checkbox checked={state.balise} onChange={handleChange} name="balise" />}
                        label="Balise"
                        sx={checkboxStyle}
                      />
                      <FormControlLabel
                        control={<Checkbox checked={state.plaquevelcro} onChange={handleChange} name="plaquevelcro" />}
                        label="Plaque Magnétique"
                        sx={checkboxStyle}
                      />
                      <FormControlLabel
                        control={<Checkbox checked={state.chargeurintelligent} onChange={handleChange} name="chargeurintelligent" />}
                        label="Chargeur intelligent"
                        sx={checkboxStyle}
                      />
                      <FormControlLabel
                        control={<Checkbox checked={state.adblue} onChange={handleChange} name="adblue" />}
                        label="AD-Blue"
                        sx={checkboxStyle}
                      />
                      <FormControlLabel
                        control={<Checkbox checked={state.pneusneige} onChange={handleChange} name="pneusneige" />}
                        label="Pneus neige"
                        sx={checkboxStyle}
                      />
                      <FormControlLabel
                        control={<Checkbox checked={state.attelage} onChange={handleChange} name="attelage" />}
                        label="Attelage"
                        sx={checkboxStyle}
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* Bouton de soumission */}
              <div className="flex justify-center pt-6">
                <button
                  type="submit"
                  className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-xl shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                >
                  Créer le véhicule
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  );
}

export default CreateVHL;