import React, { useEffect, useState } from "react";
import axios from "axios";
import authHeader from "../../auth-header";

export default function ListeMobilite() {
  const [materiels, setMateriels] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/materiels/", { headers: authHeader() })
      .then((response) => {
        const filteredMateriels = response.data.filter(
          (materiel) => materiel.categorie === "Mobilité"
        );
        setMateriels(filteredMateriels);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <div className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold text-gray-900">Mobilité</h1>
            <div className="flex space-x-2">
              <button
                onClick={() => window.history.back()}
                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Retour
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="bg-white rounded-lg shadow">
          {materiels
            .sort((a, b) => a.alerte.localeCompare(b.alerte))
            .map((materiel, index, array) => (
              <React.Fragment key={materiel._id}>
                {/* Category Header */}
                {(index === 0 || materiel.alerte !== array[index - 1].alerte) && (
                  <div className="border-b border-gray-200 bg-gray-50 px-4 py-3">
                    <div className="flex items-center justify-between">
                      <h2 className="text-lg font-medium text-gray-900">
                        {materiel.alerte}
                      </h2>
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                        materiel.alerte === "Alerte 1" ? "bg-red-100 text-red-800" :
                        materiel.alerte === "Alerte 2" ? "bg-orange-100 text-orange-800" :
                        "bg-green-100 text-green-800"
                      }`}>
                        {materiel.alerte}
                      </span>
                    </div>
                  </div>
                )}

                {/* Item Card */}
                <a
                  href={`/pagemateriel/${materiel._id}`}
                  className="block hover:bg-gray-50 transition duration-150 ease-in-out"
                >
                  <div className="px-4 py-4 flex items-center space-x-4 sm:px-6">
                    {/* Image */}
                    <div className="flex-shrink-0">
                      <img
                        src={materiel.image}
                        alt={materiel.nom}
                        className="h-12 w-12 rounded-lg object-cover shadow-sm"
                      />
                    </div>

                    {/* Content */}
                    <div className="flex-1 min-w-0">
                      <div className="flex items-center justify-between">
                        <p className="text-sm font-medium text-gray-900 truncate">
                          {materiel.nom}
                        </p>
                        <div className="ml-2 flex-shrink-0">
                          <svg
                            className="h-5 w-5 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      </div>
                      {materiel.description && (
                        <p className="mt-1 text-sm text-gray-500 truncate">
                          {materiel.description}
                        </p>
                      )}
                    </div>
                  </div>
                  {index !== array.length - 1 && (
                    <div className="border-t border-gray-200" />
                  )}
                </a>
              </React.Fragment>
            ))}

          {materiels.length === 0 && (
            <div className="text-center py-12">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                />
              </svg>
              <h3 className="mt-2 text-sm font-medium text-gray-900">Aucun matériel</h3>
              <p className="mt-1 text-sm text-gray-500">
                Aucun matériel de mobilité n'est disponible pour le moment.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
