import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import * as dates from '../../utils/dates';
import authHeader from "../auth-header";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/fr';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeftIcon, CalendarIcon } from '@heroicons/react/24/outline';

// Configuration de moment en français
moment.locale('fr');
const mLocalizer = momentLocalizer(moment);

// Personnalisation des styles du calendrier
const calendarCustomStyles = {
  // Style pour les événements
  event: {
    backgroundColor: '#1e3a8a',
    color: 'white',
    borderRadius: '4px',
    border: 'none',
    padding: '2px 5px',
  },
  // Style pour aujourd'hui
  today: {
    backgroundColor: '#eff6ff',
  },
  // Style pour les dates sélectionnées
  selected: {
    backgroundColor: '#bfdbfe',
  }
};

export default function CalendrierResa() {
  const [reservations, setReservations] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState(window.innerWidth < 768 ? 'agenda' : 'month');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [reservationsRes, userRes] = await Promise.all([
          axios.get(process.env.REACT_APP_BASE_URL + '/api/reservations/', { headers: authHeader() }),
          axios.get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
        ]);
        setReservations(reservationsRes.data);
        setCurrentUser(userRes.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();

    // Gestion du responsive pour la vue du calendrier
    const handleResize = () => {
      setView(window.innerWidth < 768 ? 'agenda' : 'month');
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const filteredVehicles = reservations
    .filter((reservation) => currentUser && reservation.service === currentUser.service)
    .sort((a, b) => new Date(a.datedebut) - new Date(b.datedebut));

  const events = filteredVehicles.map((reservation) => ({
    title: reservation.nomvhl,
    id: reservation._id,
    start: new Date(reservation.datedebut),
    end: new Date(reservation.datefin),
    resource: reservation,
  }));

  // Personnalisation des messages du calendrier
  const messages = {
    today: "Aujourd'hui",
    previous: 'Précédent',
    next: 'Suivant',
    month: 'Mois',
    week: 'Semaine',
    day: 'Jour',
    agenda: 'Agenda',
    date: 'Date',
    time: 'Heure',
    event: 'Événement',
    noEventsInRange: 'Aucune réservation sur cette période',
  };

  // Personnalisation du format des dates
  const formats = {
    monthHeaderFormat: 'MMMM YYYY',
    agendaHeaderFormat: ({ start, end }) => {
      return `${moment(start).format('D MMMM')} - ${moment(end).format('D MMMM YYYY')}`;
    },
    agendaDateFormat: 'D MMMM',
    agendaTimeFormat: 'HH:mm',
    eventTimeFormat: 'HH:mm',
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-900"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* En-tête */}
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <h1 className="text-2xl font-bold text-gray-900">Calendrier des réservations</h1>
            </div>
            <div className="hidden sm:flex items-center space-x-2 text-sm text-gray-500">
              <CalendarIcon className="h-5 w-5" />
              <span>Service {currentUser.service}</span>
            </div>
          </div>
        </div>

        {/* Calendrier */}
        <div className="bg-white shadow-sm ring-1 ring-gray-900/5 rounded-xl overflow-hidden">
          <div className="p-4 sm:p-6" style={{ height: 'calc(100vh - 200px)' }}>
            <Calendar
              localizer={mLocalizer}
              events={events}
              defaultView={view}
              views={['month', 'week', 'day', 'agenda']}
              step={60}
              showMultiDayTimes
              messages={messages}
              formats={formats}
              onSelectEvent={(event) => navigate(`/reservations/${event.id}`)}
              eventPropGetter={() => ({
                style: calendarCustomStyles.event
              })}
              dayPropGetter={date => {
                if (moment(date).isSame(moment(), 'day')) {
                  return { style: calendarCustomStyles.today };
                }
                return {};
              }}
              className="h-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
