import { React, useState, useEffect } from "react";
import axios from "axios";
import authHeader from "../auth-header";
import { Avatar, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

function ToutMateriel() {
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const logOut = () => {
    localStorage.removeItem("user");
  };

  const fabStyle = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
  };

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="text-center mb-6">
          <h1 className="text-3xl font-bold text-gray-900">Inventaire FOR</h1>
        </div>

        {/* Grid of Categories */}
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {/* CNP */}
          <Link to="/listing-cnp" className="group">
            <div className="bg-white rounded-2xl p-6 shadow-sm hover:shadow-md transition-all duration-200 transform hover:scale-[1.02]">
              <div className="aspect-w-1 aspect-h-1 mb-4 rounded-xl overflow-hidden bg-gray-100">
                <img
                  src="/images/cnp.jpeg"
                  alt="CNP"
                  className="w-full h-full object-center object-cover transform group-hover:scale-110 transition-transform duration-200"
                />
              </div>
              <h3 className="text-lg font-semibold text-gray-900 text-center">CNP</h3>
            </div>
          </Link>

          {/* COF */}
          <Link to="/listing-cof" className="group">
            <div className="bg-white rounded-2xl p-6 shadow-sm hover:shadow-md transition-all duration-200 transform hover:scale-[1.02]">
              <div className="aspect-w-1 aspect-h-1 mb-4 rounded-xl overflow-hidden bg-gray-100">
                <img
                  src="/images/cof.jpeg"
                  alt="COF"
                  className="w-full h-full object-center object-cover transform group-hover:scale-110 transition-transform duration-200"
                />
              </div>
              <h3 className="text-lg font-semibold text-gray-900 text-center">COF / Perben</h3>
            </div>
          </Link>

          {/* Divers */}
          <Link to="/listing-divers" className="group">
            <div className="bg-white rounded-2xl p-6 shadow-sm hover:shadow-md transition-all duration-200 transform hover:scale-[1.02]">
              <div className="aspect-w-1 aspect-h-1 mb-4 rounded-xl overflow-hidden bg-gray-100">
                <img
                  src="/images/divers.jpeg"
                  alt="Divers"
                  className="w-full h-full object-center object-cover transform group-hover:scale-110 transition-transform duration-200"
                />
              </div>
              <h3 className="text-lg font-semibold text-gray-900 text-center">Divers</h3>
            </div>
          </Link>

          {/* Image */}
          <Link to="/listing-image" className="group">
            <div className="bg-white rounded-2xl p-6 shadow-sm hover:shadow-md transition-all duration-200 transform hover:scale-[1.02]">
              <div className="aspect-w-1 aspect-h-1 mb-4 rounded-xl overflow-hidden bg-gray-100">
                <img
                  src="/images/image.jpeg"
                  alt="Image"
                  className="w-full h-full object-center object-cover transform group-hover:scale-110 transition-transform duration-200"
                />
              </div>
              <h3 className="text-lg font-semibold text-gray-900 text-center">Image</h3>
            </div>
          </Link>

          {/* Interpel */}
          <Link to="/listing-interpel" className="group">
            <div className="bg-white rounded-2xl p-6 shadow-sm hover:shadow-md transition-all duration-200 transform hover:scale-[1.02]">
              <div className="aspect-w-1 aspect-h-1 mb-4 rounded-xl overflow-hidden bg-gray-100">
                <img
                  src="/images/interpel.jpeg"
                  alt="Interpel"
                  className="w-full h-full object-center object-cover transform group-hover:scale-110 transition-transform duration-200"
                />
              </div>
              <h3 className="text-lg font-semibold text-gray-900 text-center">Interpel / Armement</h3>
            </div>
          </Link>

          {/* Micro */}
          <Link to="/listing-micro" className="group">
            <div className="bg-white rounded-2xl p-6 shadow-sm hover:shadow-md transition-all duration-200 transform hover:scale-[1.02]">
              <div className="aspect-w-1 aspect-h-1 mb-4 rounded-xl overflow-hidden bg-gray-100">
                <img
                  src="/images/micro.jpeg"
                  alt="Micro"
                  className="w-full h-full object-center object-cover transform group-hover:scale-110 transition-transform duration-200"
                />
              </div>
              <h3 className="text-lg font-semibold text-gray-900 text-center">Micro</h3>
            </div>
          </Link>

          {/* Mobilité */}
          <Link to="/listing-vhl" className="group">
            <div className="bg-white rounded-2xl p-6 shadow-sm hover:shadow-md transition-all duration-200 transform hover:scale-[1.02]">
              <div className="aspect-w-1 aspect-h-1 mb-4 rounded-xl overflow-hidden bg-gray-100">
                <img
                  src="/images/vhl.jpeg"
                  alt="Mobilité"
                  className="w-full h-full object-center object-cover transform group-hover:scale-110 transition-transform duration-200"
                />
              </div>
              <h3 className="text-lg font-semibold text-gray-900 text-center">Mobilité</h3>
            </div>
          </Link>

          {/* Drone */}
          <Link to="/listing-drone" className="group">
            <div className="bg-white rounded-2xl p-6 shadow-sm hover:shadow-md transition-all duration-200 transform hover:scale-[1.02]">
              <div className="aspect-w-1 aspect-h-1 mb-4 rounded-xl overflow-hidden bg-gray-100">
                <img
                  src="/images/drone.jpeg"
                  alt="Drone"
                  className="w-full h-full object-center object-cover transform group-hover:scale-110 transition-transform duration-200"
                />
              </div>
              <h3 className="text-lg font-semibold text-gray-900 text-center">Rens aérien / Drones</h3>
            </div>
          </Link>

          {/* SIC */}
          <Link to="/listing-sic" className="group">
            <div className="bg-white rounded-2xl p-6 shadow-sm hover:shadow-md transition-all duration-200 transform hover:scale-[1.02]">
              <div className="aspect-w-1 aspect-h-1 mb-4 rounded-xl overflow-hidden bg-gray-100">
                <img
                  src="/images/sic.jpeg"
                  alt="SIC"
                  className="w-full h-full object-center object-cover transform group-hover:scale-110 transition-transform duration-200"
                />
              </div>
              <h3 className="text-lg font-semibold text-gray-900 text-center">SIC</h3>
            </div>
          </Link>

          {/* Track */}
          <Link to="/listing-track" className="group">
            <div className="bg-white rounded-2xl p-6 shadow-sm hover:shadow-md transition-all duration-200 transform hover:scale-[1.02]">
              <div className="aspect-w-1 aspect-h-1 mb-4 rounded-xl overflow-hidden bg-gray-100">
                <img
                  src="/images/track.jpeg"
                  alt="Track"
                  className="w-full h-full object-center object-cover transform group-hover:scale-110 transition-transform duration-200"
                />
              </div>
              <h3 className="text-lg font-semibold text-gray-900 text-center">Track</h3>
            </div>
          </Link>

          {/* Verte */}
          <Link to="/listing-verte" className="group">
            <div className="bg-white rounded-2xl p-6 shadow-sm hover:shadow-md transition-all duration-200 transform hover:scale-[1.02]">
              <div className="aspect-w-1 aspect-h-1 mb-4 rounded-xl overflow-hidden bg-gray-100">
                <img
                  src="/images/verte.jpeg"
                  alt="Verte"
                  className="w-full h-full object-center object-cover transform group-hover:scale-110 transition-transform duration-200"
                />
              </div>
              <h3 className="text-lg font-semibold text-gray-900 text-center">Verte</h3>
            </div>
          </Link>
        </div>

        {/* Admin FAB */}
        {currentUser.roles === "Admin" && (
          <div className="fixed bottom-8 right-8">
            <Link
              to="/newmateriel"
              className="inline-flex items-center justify-center w-14 h-14 rounded-full bg-blue-600 text-white shadow-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200"
            >
              <AddIcon className="h-6 w-6" aria-hidden="true" />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default ToutMateriel;
