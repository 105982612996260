import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import authHeader from "../auth-header";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Perception() {
  const [vhl, setVhl] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [currentUser, setCurrentUser] = useState({});

  const PositionOptions = [
    { id: 1, name: 'Disponible' },
    { id: 2, name: 'Mission' },
    { id: 3, name: 'Liaison' },
    { id: 4, name: 'Exercice' },
    { id: 5, name: 'Garage' },
    { id: 6, name: 'SIC' },
    { id: 7, name: 'Diagnostic Garage' },
  ];

  const [selectedPosition, setSelectedPosition] = useState(PositionOptions[6]);


  const onChangePosition = (e) => {
    setSelectedPosition(e);
    if (e.name === 'Disponible') {
      // Si la position est "Disponible", réinitialise Emploi et Utilisateur à des chaînes vides.
      setVhl({
        ...vhl,
        position: e.name,
        emploi: '',
        utilisateur: '',
      });
    } else {
      setVhl({
        ...vhl,
        position: e.name,
      });
    }
  };

  const onChangeEmploi = (e) => {
    setVhl({
      ...vhl,
      emploi: e.target.value,
    });
  };

  const onChangeUtilisateur = (e) => {
    setVhl({
      ...vhl,
      utilisateur: e.target.value,
    });
  };

  const onChangeKm = (e) => {
    const kmValue = e.target.value;
    let revisionprov;
    if (currentUser.service === "FOR" || currentUser.service === "FSP" || currentUser.service === "FI" || currentUser.service === "SMS") {
      revisionprov = Math.ceil(kmValue / 10000) * 10000;
    } else {
      revisionprov = Math.ceil(kmValue / 10000) * 10000 + 10000;
    }
    setVhl({
      ...vhl,
      km: e.target.value,
      prochainerevision: revisionprov,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
  
    axios
      .post(process.env.REACT_APP_BASE_URL + "/api/vhl/update/" + id, vhl, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log(res.data);
        setVhl((prevVhl) => ({ ...prevVhl, ...vhl }));
  
        if (vhl.position !== "Disponible") {
          const histoperception = {
            ...vhl,
            idvhl: vhl.id,
            nomvhl: vhl.nomvhl,
            service: vhl.service,
            date: new Date(),
            position: vhl.position,
            emploi: vhl.emploi,
            utilisateur: vhl.utilisateur,
            km: vhl.km,
          };
  
          axios
            .post(
              process.env.REACT_APP_BASE_URL + "/api/historiqueperception/add/",
              histoperception,
              { headers: authHeader() }
            )
            .then((res) => {
              window.location = `/pagevhl/${vhl._id}`;
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          window.location = `/pagevhl/${vhl._id}`;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  

  useEffect(() => {
    if (isLoading) {
      axios
        .get(process.env.REACT_APP_BASE_URL + "/api/vhl/" + id, { headers: authHeader() })
        .then((response) => {
          setVhl(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      setIsLoading(false);
    }
    axios.get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, isLoading]);

  return (
    <div className="bg-gray-100">
      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
        <div className="bg-white rounded-2xl shadow-lg p-4 transform hover:scale-[1.01] transition-all duration-300">
          {/* En-tête avec image */}
          <div className="flex flex-col items-center">
            <div className="relative group">
              <div className="absolute inset-0 bg-blue-900 rounded-full opacity-10 group-hover:opacity-20 animate-pulse transition-opacity"></div>
              <img
                className="relative h-40 w-40 rounded-full object-cover border-4 border-white shadow-lg transform group-hover:scale-105 transition-transform duration-300"
                src={vhl.image}
                alt={vhl.nomvhl}
              />
            </div>
            <h3 className="mt-6 text-2xl font-bold text-gray-900">{vhl.nomvhl}</h3>
            <p className="mt-2 text-lg text-gray-500 font-semibold">{vhl.dossardorigine}</p>
          </div>

          {/* Séparateur */}
          <div className="relative my-8">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-200" />
            </div>
            <div className="relative flex justify-center">
              <span className="bg-white px-4 text-sm text-gray-500 font-medium">
                Perception du VHL
              </span>
            </div>
          </div>

          {/* Formulaire */}
          <form onSubmit={onSubmit} className="space-y-6">
            {/* Position */}
            <div className="relative">
              <Listbox value={selectedPosition} onChange={onChangePosition}>
                {({ open }) => (
                  <>
                    <Listbox.Label className="block text-sm font-semibold text-gray-700 mb-1">
                      Position
                    </Listbox.Label>
                    <div className="relative">
                      <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2.5 pl-3 pr-10 text-left border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-900 focus:border-blue-900 sm:text-sm transition-all duration-200 hover:border-blue-900">
                        <span className="block truncate">{selectedPosition.name}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {PositionOptions.map((option) => (
                            <Listbox.Option
                              key={option.id}
                              className={({ active }) =>
                                classNames(
                                  active ? 'bg-blue-900 text-white' : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-3 pr-9 transition-colors duration-200'
                                )
                              }
                              value={option}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                    {option.name}
                                  </span>
                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active ? 'text-white' : 'text-blue-900',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>

            {/* Emploi */}
            <div className="relative">
              <label htmlFor="emploi" className="block text-sm font-semibold text-gray-700 mb-1">
                Emploi
              </label>
              <input
                type="text"
                name="emploi"
                id="emploi"
                required={vhl.position !== "Disponible"}
                className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-900 focus:border-blue-900 sm:text-sm transition-all duration-200 hover:border-blue-900"
                value={vhl.emploi}
                onChange={onChangeEmploi}
              />
            </div>

            {/* Utilisateur */}
            <div className="relative">
              <label htmlFor="utilisateur" className="block text-sm font-semibold text-gray-700 mb-1">
                Utilisateur
              </label>
              <input
                type="text"
                name="utilisateur"
                id="utilisateur"
                required={vhl.position !== "Disponible"}
                className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-900 focus:border-blue-900 sm:text-sm transition-all duration-200 hover:border-blue-900"
                value={vhl.utilisateur}
                onChange={onChangeUtilisateur}
              />
            </div>

            {/* Kilométrage */}
            <div className="relative">
              <label htmlFor="km" className="block text-sm font-semibold text-gray-700 mb-1">
                Kilométrage
              </label>
              <input
                type="number"
                name="km"
                id="km"
                className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-900 focus:border-blue-900 sm:text-sm transition-all duration-200 hover:border-blue-900"
                value={vhl.km}
                onChange={onChangeKm}
              />
            </div>

            {/* Bouton de validation */}
            <div className="flex justify-center pt-6">
              <button
                type="submit"
                className="inline-flex items-center px-8 py-3 border border-transparent text-base font-medium rounded-xl text-white bg-blue-900 hover:bg-blue-800 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 transition-all duration-200 shadow-md"
              >
                Valider la perception
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Perception;
