import React, { useState, useEffect } from 'react';
import axios from 'axios';
import authHeader from './auth-header';
import { Link } from 'react-router-dom';
import { Disclosure} from '@headlessui/react';
import { Bars3Icon, XMarkIcon, UserCircleIcon, HomeIcon, KeyIcon, ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import { Car } from 'lucide-react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function NavBar() {
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + '/api/users/CurrentUser', { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const logOut = () => {
    localStorage.removeItem('user');
    window.location = '/login';
  };

  const navigation = [
    { name: 'Accueil', href: '/accueil', icon: HomeIcon },
    { name: 'Véhicules', href: '/accueil', icon: Car },
    ...(currentUser.service === 'FOR' ? [{ name: 'Matériels', href: '/materiel', icon: KeyIcon }] : []),
  ];

  return (
    <Disclosure as="nav" className="bg-gradient-to-r from-blue-900 to-blue-800">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-100 hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-shrink-0 items-center">
                  <Link to="/accueil" className="flex items-center gap-2">
                    <Car className="h-8 w-8 text-white" />
                    <span className="text-xl font-bold text-white tracking-wider">HIBOUX</span>
                  </Link>
                </div>
                <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4">
                  {currentUser.username && navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="group flex items-center gap-2 px-3 py-2 text-sm font-medium text-gray-100 hover:bg-blue-700 hover:text-white rounded-md transition-colors duration-150"
                    >
                      <item.icon className="h-5 w-5 text-gray-100 group-hover:text-white" />
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
              {currentUser.username && (
                <div className="flex items-center">
                  <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
                    <div className="flex items-center gap-3">
                      <span className="text-sm text-gray-100">
                        {currentUser.username} ({currentUser.service})
                      </span>
                      <button
                        onClick={logOut}
                        className="group flex items-center gap-2 rounded-md bg-blue-700 px-3 py-2 text-sm font-medium text-white hover:bg-blue-600 transition-colors duration-150"
                      >
                        <ArrowRightOnRectangleIcon className="h-5 w-5" />
                        Déconnexion
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {currentUser.username && navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as={Link}
                  to={item.href}
                  className="group flex items-center gap-2 rounded-md px-3 py-2 text-base font-medium text-gray-100 hover:bg-blue-700 hover:text-white"
                >
                  <item.icon className="h-5 w-5 text-gray-100 group-hover:text-white" />
                  {item.name}
                </Disclosure.Button>
              ))}
              <div className="border-t border-blue-700 pt-4 pb-3">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <UserCircleIcon className="h-8 w-8 text-gray-100" />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-white">{currentUser.username}</div>
                    <div className="text-sm font-base text-gray-100">{currentUser.service}</div>
                  </div>
                </div>
                <div className="mt-3 space-y-1 px-2">
                  <Disclosure.Button
                    as="button"
                    onClick={logOut}
                    className="group flex w-full items-center gap-2 rounded-md px-3 py-2 text-base font-medium text-gray-100 hover:bg-blue-700 hover:text-white"
                  >
                    <ArrowRightOnRectangleIcon className="h-5 w-5 text-gray-100 group-hover:text-white" />
                    Déconnexion
                  </Disclosure.Button>
                </div>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
