import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TrashIcon, KeyIcon, ClockIcon } from '@heroicons/react/20/solid';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import authHeader from "../auth-header";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function PageHistoArmoire(props) {
  const [histo, setHisto] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState({});

  const fetchHistoData = () => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/histoarmoire/", { headers: authHeader() })
      .then((response) => {
        setHisto(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchCurrentUser = () => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", {
        headers: authHeader(),
      })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteHisto = (id) => {
    axios
      .delete(process.env.REACT_APP_BASE_URL + "/api/histoarmoire/" + id, { headers: authHeader() })
      .then(() => {
        setisLoading(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isLoading) {
      fetchHistoData();
      fetchCurrentUser();
      setisLoading(false);
    }
  }, [isLoading]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-sm overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            {/* Header */}
            <div className="sm:flex sm:items-center border-b border-gray-200 pb-5">
              <div className="sm:flex-auto">
                <div className="flex items-center gap-3">
                  <h1 className="text-2xl font-bold text-gray-900 text-center">
                    Historiques d'ouverture Armoire FOR
                  </h1>
                </div>
              </div>
            </div>

            {/* Table */}
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          Utilisateur
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Service
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Date
                        </th>
                        {currentUser.username === "Dje" && (
                          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                            <span className="sr-only">Actions</span>
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {histo.map((histoarmoire) => (
                        <tr key={histoarmoire._id} className="hover:bg-gray-50">
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-0">
                            <div className="flex items-center">
                              <div className="ml-4">
                                <div className="font-medium text-gray-900">{histoarmoire.user}</div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm">
                            <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-sm font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                              {histoarmoire.service}
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            <div className="flex items-center gap-1.5">
                              <ClockIcon className="h-4 w-4 text-gray-500" />
                              {format(new Date(histoarmoire.date), 'dd/MM/yyyy HH:mm', { locale: fr })}
                            </div>
                          </td>
                          {currentUser.username === "Dje" && (
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                              <button
                                onClick={() => {
                                  if (window.confirm('Êtes-vous sûr de vouloir supprimer cette ligne ?')) {
                                    deleteHisto(histoarmoire._id);
                                  }
                                }}
                                className="text-red-600 hover:text-red-900 inline-flex items-center gap-1.5 rounded-md px-2 py-1 hover:bg-red-50 transition-colors duration-200"
                              >
                                <TrashIcon className="h-5 w-5" />
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageHistoArmoire;
