import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import "react-datepicker/dist/react-datepicker.css";
import {
  Avatar,
  Box,
  Button,
  Typography,
  ListItemButton,
  ListItemText,
  Tabs,
  Tab,
  Card,
  CardMedia,
  CardContent,
  List,
  Snackbar,
  Alert
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import KeyIcon from '@mui/icons-material/Key';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import MicIcon from '@mui/icons-material/Mic';
import ForestIcon from '@mui/icons-material/Forest';
import MasksIcon from '@mui/icons-material/Masks';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ConstructionIcon from '@mui/icons-material/Construction';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import authHeader from "../../auth-header";

const label = { inputProps: { 'aria-label': 'Switch' } };

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const initialPerception = {
    image: [],
    cof: [],
    drone: [],
    micro: [],
    verte: [],
    cnp: [],
    interpel: [],
    mobilite: [],
    divers: [],
    sic: [],
    track: [],
  };

function Reintegration(props) {
  const [perception, setPerception] = useState(initialPerception);
  const [isLoading, setisLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState({});
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedItems, setSelectedItems] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  useEffect(() => {
    if (isLoading) {
      axios
        .get(process.env.REACT_APP_BASE_URL + "/api/perceptionmateriel/" + id, { headers: authHeader() })
        .then((response) => {
          setPerception(response.data);

        })
        .catch((error) => {
          console.log(error);
        });
      setisLoading(false);
    }
  }, [id, isLoading]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleReintegrateSelected = () => {
    const filteredPerception = { ...perception };
  
    switch (value) {
      case 0:
        filteredPerception.image = [];
        break;
      case 1:
        filteredPerception.cof = [];
        break;
      case 2:
        filteredPerception.drone = [];
        break;
      case 3:
        filteredPerception.micro = [];
        break;
      case 4:
        filteredPerception.verte = [];
        break;
      case 5:
        filteredPerception.cnp = [];
        break;
      case 6:
        filteredPerception.interpel = [];
        break;
      case 7:
        filteredPerception.mobilite = [];
        break;
      case 8:
        filteredPerception.divers = [];
        break;
      case 9:
        filteredPerception.sic = [];
        break;
      case 10:
        filteredPerception.track = [];
        break;        
      default:
        break;
    }
  
    setPerception(filteredPerception);
    setSelectedItems([]);
    
    axios
      .post(process.env.REACT_APP_BASE_URL + '/api/perceptionmateriel/update/' + id, filteredPerception, {
        headers: authHeader(),
      })
      .then((res) => {
        showSnackbar('Matériels réintégrés avec succès');
      })
      .catch((error) => {
        showSnackbar('Erreur lors de la réintégration des matériels', 'error');
        console.error(error);
      });
  };

  const handleDeletePerception = () => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette perception ?")) {
      axios
        .delete(process.env.REACT_APP_BASE_URL + "/api/perceptionmateriel/" + id, { headers: authHeader() })
        .then((res) => {
          showSnackbar('Perception supprimée avec succès');
          setTimeout(() => {
            navigate("/listperceptions");
          }, 1500);
        })
        .catch((error) => {
          showSnackbar('Erreur lors de la suppression de la perception', 'error');
          console.error(error);
        });
    }
  };

  const PerceptionList = ({ list }) => {
    return (
        <div>
      <List>
        {list &&
          list.map((item, index) => (
            <ListItemButton key={index} alignItems="flex-start" href={`/pagemateriel/${item.materiel._id}`}>
              {item && item.materiel && (
                <Avatar
                  alt={item.materiel.nom}
                  src={item.materiel.imageperception}
                  sx={{ width: 32, height: 32, mr: 1 }}
                />
              )}
              {item && item.materiel && (
                <ListItemText
                  primary={item.materiel.nom}
                  primaryTypographyProps={{ variant: 'body2' }}
                  sx={{ alignSelf: 'flex-start' }}
                />
              )}
            </ListItemButton>
          ))}
      </List>
      </div>
    );
  };

  return (
    <Box sx={{ maxWidth: '1200px', mx: 'auto', p: { xs: 1, sm: 2, md: 3 } }}>
      {/* Header */}
      <div className="text-center mb-4">
        <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">Réintégration des matériels</h1>
      </div>

      {/* Info Card */}
      <Card sx={{ mb: 2, bgcolor: 'background.paper', boxShadow: 2 }}>
        <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
          <Typography variant="h6" component="div" gutterBottom sx={{ fontWeight: 'bold' }}>
            {perception.nomperception}
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Type de perception : {perception.typeperception}
          </Typography>
          {perception.remarques && (
            <Typography variant="body2" color="text.secondary">
              Remarques : {perception.remarques}
            </Typography>
          )}
        </CardContent>
      </Card>

      {/* Action Buttons */}
      <Box sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', sm: 'row' },
        gap: 1,
        mb: 2
      }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleReintegrateSelected}
          fullWidth={true}
          sx={{
            py: 1.5,
            borderRadius: 2,
            textTransform: 'none',
            fontSize: '0.9rem'
          }}
        >
          Réintégrer les matériels
        </Button>

        <Button
          variant="outlined"
          color="error"
          onClick={handleDeletePerception}
          disabled={
            perception.image.length !== 0 ||
            perception.cof.length !== 0 ||
            perception.drone.length !== 0 ||
            perception.micro.length !== 0 ||
            perception.verte.length !== 0 ||
            perception.cnp.length !== 0 ||
            perception.interpel.length !== 0 ||
            perception.mobilite.length !== 0 ||
            perception.divers.length !== 0 ||
            perception.sic.length !== 0 ||
            perception.track.length !== 0
          }
          fullWidth={true}
          sx={{
            py: 1.5,
            borderRadius: 2,
            textTransform: 'none',
            fontSize: '0.9rem'
          }}
        >
          Supprimer la perception
        </Button>
      </Box>

      {/* Categories and Content */}
      <Card sx={{ bgcolor: 'background.paper', boxShadow: 2 }}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' },
          minHeight: { xs: 'auto', md: 500 } 
        }}>
          {/* Categories Tabs */}
          <Tabs
            orientation={{ xs: 'horizontal', md: 'vertical' }}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            value={value}
            onChange={handleChange}
            aria-label="Catégories de matériels"
            sx={{
              borderRight: { xs: 0, md: 1 },
              borderBottom: { xs: 1, md: 0 },
              borderColor: 'divider',
              minWidth: { xs: '100%', md: 200 },
              maxWidth: { xs: '100%', md: 200 },
              '& .MuiTab-root': {
                alignItems: 'center',
                justifyContent: { xs: 'center', md: 'flex-start' },
                textAlign: { xs: 'center', md: 'left' },
                textTransform: 'none',
                minHeight: { xs: 60, md: 48 },
                py: { xs: 0.5, md: 1 },
                px: { xs: 1, md: 2 },
                minWidth: { xs: 'auto', md: '100%' },
                fontSize: '0.875rem'
              }
            }}
          >
            {[
              { icon: CameraAltIcon, label: 'Image', items: perception.image },
              { icon: KeyIcon, label: 'COF', items: perception.cof },
              { icon: AirplanemodeActiveIcon, label: 'Drone', items: perception.drone },
              { icon: MicIcon, label: 'Micro', items: perception.micro },
              { icon: ForestIcon, label: 'Verte', items: perception.verte },
              { icon: MasksIcon, label: 'CNP', items: perception.cnp },
              { icon: LocalFireDepartmentIcon, label: 'Interpel', items: perception.interpel },
              { icon: DirectionsCarIcon, label: 'Mobilité', items: perception.mobilite },
              { icon: ConstructionIcon, label: 'Divers', items: perception.divers },
              { icon: SettingsInputAntennaIcon, label: 'SIC', items: perception.sic },
              { icon: LocationOnIcon, label: 'Track', items: perception.track }
            ].map((category, index) => (
              <Tab
                key={index}
                label={
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: { xs: 'center', md: 'flex-start' }
                  }}>
                    {category.items.length === 0 && (
                      <CheckCircleIcon 
                        sx={{ 
                          color: 'success.main',
                          mr: 1,
                          fontSize: 18
                        }} 
                      />
                    )}
                    <category.icon sx={{ mr: 1, fontSize: 18 }} />
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        display: { xs: 'none', sm: 'block' },
                        flexGrow: 1 
                      }}
                    >
                      {category.label}
                    </Typography>
                    {category.items.length > 0 && (
                      <Box
                        sx={{
                          ml: { xs: 0, sm: 1 },
                          bgcolor: 'primary.main',
                          color: 'white',
                          borderRadius: '50%',
                          width: 18,
                          height: 18,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '0.7rem',
                        }}
                      >
                        {category.items.length}
                      </Box>
                    )}
                  </Box>
                }
                {...a11yProps(index)}
              />
            ))}
          </Tabs>

          {/* Right Content - Material List */}
          <Box sx={{ flexGrow: 1, p: { xs: 1, sm: 2, md: 3 } }}>
            {[
              perception.image,
              perception.cof,
              perception.drone,
              perception.micro,
              perception.verte,
              perception.cnp,
              perception.interpel,
              perception.mobilite,
              perception.divers,
              perception.sic,
              perception.track
            ].map((items, index) => (
              <TabPanel key={index} value={value} index={index}>
                <List sx={{ width: '100%', p: 0 }}>
                  {items.map((item, itemIndex) => (
                    <ListItemButton 
                      key={itemIndex} 
                      component={Link}
                      to={`/pagemateriel/${item.materiel._id}`}
                      sx={{
                        mb: 1,
                        borderRadius: 1,
                        p: { xs: 1, sm: 2 },
                        '&:hover': {
                          bgcolor: 'action.hover',
                        }
                      }}
                    >
                      <Avatar
                        alt={item.materiel.nom}
                        src={item.materiel.imageperception}
                        sx={{ 
                          width: { xs: 32, sm: 40 }, 
                          height: { xs: 32, sm: 40 }, 
                          mr: { xs: 1, sm: 2 } 
                        }}
                      />
                      <ListItemText
                        primary={item.materiel.nom}
                        secondary={`Quantité : ${item.materiel.nbperception}`}
                        primaryTypographyProps={{
                          variant: 'body2',
                          fontWeight: 'medium',
                          fontSize: { xs: '0.875rem', sm: '1rem' }
                        }}
                        secondaryTypographyProps={{
                          variant: 'caption',
                          fontSize: { xs: '0.75rem', sm: '0.875rem' }
                        }}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </TabPanel>
            ))}
          </Box>
        </Box>
      </Card>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Reintegration;
