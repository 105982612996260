import React, { useState, useEffect } from "react";
import axios from "axios";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { fr } from 'date-fns/locale';
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Avatar,
  Container
} from '@mui/material';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EventIcon from '@mui/icons-material/Event';
import BuildIcon from '@mui/icons-material/Build';
import authHeader from "../auth-header";

const CreateRDV = () => {
  const [vehicles, setVehicles] = useState([]);
  const [selectedVHL, setSelectedVHL] = useState(null);
  const [selectedRDV, setSelectedRDV] = useState("");
  const [typerdv, setTypeRDV] = useState("");
  const [daterdv, setDateRDV] = useState(new Date());
  const [remarque, setRemarque] = useState("");
  const [currentUser, setCurrentUser] = useState({});

  const typeRDVOptions = [
    { id: 1, name: 'Check VHL' },
    { id: 2, name: 'Visite' },
    { id: 3, name: 'Accident' },
    { id: 4, name: 'Confection de plaques' },
    { id: 5, name: 'Liquide de frein' },
    { id: 6, name: 'Pneumatique' },
    { id: 7, name: 'Frein' },
    { id: 8, name: 'Distribution' },
    { id: 9, name: 'Embrayage' },
    { id: 10, name: 'Recherche de panne' },
    { id: 11, name: 'Récupération de VHL' },
    { id: 12, name: 'Autre' },
  ];

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/vhl/", { headers: authHeader() })
      .then((response) => {
        setVehicles(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!selectedVHL) {
      alert("Veuillez sélectionner un véhicule");
      return;
    }

    const rdv = {
      idvhl: selectedVHL._id,
      nomvhl: selectedVHL.nomvhl,
      service: selectedVHL.service,
      dossardorigine: selectedVHL.dossardorigine,
      daterdv,
      remarque,
      image: selectedVHL.image,
      typerdv,
      idusers: currentUser.username,
    };

    axios
      .post(process.env.REACT_APP_BASE_URL + "/api/rdvgarage/add", rdv, {
        headers: authHeader(),
      })
      .then((res) => {
        window.location = "/rdvvhl";
      })
      .catch((error) => {
        console.error(error);
      }); 
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      {/* Header Card */}
      <Card 
        elevation={3}
        sx={{ 
          position: 'relative',
          overflow: 'hidden',
          mb: 4
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '100%',
            background: 'linear-gradient(135deg, rgba(26, 35, 126, 0.8) 0%, rgba(13, 71, 161, 0.8) 100%)',
            zIndex: 1
          }}
        />
        <CardContent sx={{ position: 'relative', zIndex: 2, color: 'white', textAlign: 'center', py: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2, mb: 2 }}>
            <DirectionsCarIcon sx={{ fontSize: 40 }} />
            <BuildIcon sx={{ fontSize: 40 }} />
          </Box>
          <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
            Nouveau Rendez-vous
          </Typography>
          <Typography variant="subtitle1" sx={{ opacity: 0.9 }}>
            Planifier un rendez-vous pour un véhicule
          </Typography>
        </CardContent>
      </Card>

      {/* Main Form Card */}
      <Card elevation={2}>
        <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
          <Box component="form" onSubmit={onSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            {/* Vehicle Selection */}
            <Autocomplete
              value={selectedVHL}
              onChange={(event, newValue) => {
                setSelectedVHL(newValue);
              }}
              options={vehicles}
              getOptionLabel={(option) => `${option.nomvhl} - ${option.dossardorigine} - ${option.service}`}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  <Avatar
                    src={option.image}
                    alt={option.nomvhl}
                    sx={{ width: 32, height: 32, mr: 2 }}
                  >
                    <DirectionsCarIcon />
                  </Avatar>
                  <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography variant="body1" fontWeight="medium">
                      {option.nomvhl}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                      {option.dossardorigine}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {option.service}
                    </Typography>
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sélectionner un véhicule"
                  required
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <DirectionsCarIcon color="action" sx={{ mr: 1 }} />
                    )
                  }}
                />
              )}
            />

            {/* Date and Type Row */}
            <Box sx={{ 
              display: 'flex', 
              gap: 2, 
              flexDirection: { xs: 'column', sm: 'row' },
              '& > *': { flex: 1 }
            }}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                <DatePicker
                  label="Date du rendez-vous"
                  value={daterdv}
                  onChange={(newValue) => setDateRDV(newValue)}
                  slotProps={{
                    textField: {
                      required: true,
                      fullWidth: true,
                      InputProps: {
                        startAdornment: (
                          <EventIcon color="action" sx={{ mr: 1 }} />
                        )
                      }
                    }
                  }}
                />
              </LocalizationProvider>

              <FormControl required>
                <InputLabel>Type de rendez-vous</InputLabel>
                <Select
                  value={typerdv}
                  onChange={(e) => {
                    setTypeRDV(e.target.value);
                    setSelectedRDV(typeRDVOptions.find(opt => opt.name === e.target.value));
                  }}
                  label="Type de rendez-vous"
                  startAdornment={<BuildIcon color="action" sx={{ ml: 1, mr: 1 }} />}
                >
                  {typeRDVOptions.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {/* Remarks */}
            <TextField
              label="Remarques"
              value={remarque}
              onChange={(e) => setRemarque(e.target.value)}
              multiline
              rows={4}
              placeholder="Entrez vos remarques ici..."
            />

            {/* Submit Button */}
            <Button
              type="submit"
              variant="contained"
              size="large"
              startIcon={<EventIcon />}
              sx={{
                mt: 2,
                py: 1.5,
                backgroundColor: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.dark'
                }
              }}
            >
              Créer le rendez-vous
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default CreateRDV;
