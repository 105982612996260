import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { ChevronRightIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';
import authHeader from "./auth-header";

export default function Mission() {
  const [vhlmission, setVhlMission] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [RDVvhl, setRDVVhl] = useState([]);


  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/vhl/mission", { headers: authHeader() })
      .then((response) => {
        setVhlMission(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
      axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
      axios
            .get(process.env.REACT_APP_BASE_URL + "/api/rdvgarage/", { headers: authHeader() })
            .then((response) => {
              setRDVVhl(response.data);
            })
            .catch((error) => {
              console.log(error);
            });
  }, []);

  const filteredVehicles = vhlmission.filter(
    (vhlmission) => currentUser && vhlmission.service === currentUser.service
  );

  const groupedVehicles = {};

  filteredVehicles.forEach((vhl) => {
    if (!groupedVehicles[vhl.emploi]) {
      groupedVehicles[vhl.emploi] = [];
    }
    groupedVehicles[vhl.emploi].push(vhl);
  });

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="px-4 py-4">
        <h3 className="text-xl font-semibold text-gray-900 mb-6">Véhicules en mission</h3>
        
        <div className="space-y-6">
          {Object.keys(groupedVehicles).map((emploi) => (
            <div key={emploi} className="bg-white rounded-lg shadow overflow-hidden">
              <div className="bg-gray-50 px-4 py-3 border-b border-gray-200">
                <h3 className="text-base font-bold text-gray-900 text-center">{emploi}</h3>
              </div>
              <ul className="divide-y divide-gray-200">
                {groupedVehicles[emploi].map((vhl) => (
                  <li key={vhl._id}>
                    <Link to={`/pagevhl/${vhl._id}`} className="block hover:bg-gray-50 transition duration-150 ease-in-out">
                      <div className="flex items-center px-4 py-4">
                        <div className="flex-shrink-0">
                          <img className="h-12 w-12 rounded-lg object-cover" src={vhl.image} alt="" />
                        </div>
                        <div className="ml-4 flex-1 min-w-0">
                          <div className="flex items-center justify-between">
                            <div>
                              <p className="text-sm font-bold text-gray-900 truncate">{vhl.nomvhl}</p>
                              <p className="mt-1 text-sm text-gray-500 italic">{vhl.dossardorigine}</p>
                            </div>
                            <div className="text-sm text-gray-500 italic truncate ml-4">{vhl.utilisateur}</div>
                          </div>
                        </div>
                        <div className="flex items-center gap-2 ml-4">
                          {RDVvhl.some(rdv => rdv.idvhl === vhl._id) && (
                            <ExclamationCircleIcon className="h-4 w-4 text-red-500 flex-shrink-0" aria-hidden="true" />
                          )}
                          <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
