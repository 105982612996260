import React, { useState, useEffect } from "react";
import { PlusCircleIcon, PencilSquareIcon, UserGroupIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import axios from "axios";
import authHeader from "../auth-header";

function PageAdmin() {
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 py-8">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-8">Administration</h2>

        <div className="grid grid-cols-1 gap-4 mb-8">
          {/* Section principale */}
          <div className="space-y-4">
            <button
              type="button"
              onClick={() => (window.location.href = "/create")}
              className="w-full flex items-center justify-between px-4 py-4 bg-white rounded-xl border border-gray-200 shadow-sm hover:border-blue-500 hover:ring-2 hover:ring-blue-500 transition-all duration-200"
            >
              <div className="flex items-center">
                <PlusCircleIcon className="h-6 w-6 text-blue-600 mr-3" aria-hidden="true" />
                <span className="text-base font-semibold text-gray-900">Créer un véhicule</span>
              </div>
              <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </button>

            <button
              type="button"
              onClick={() => (window.location.href = "/pageadmin/listadminvhl")}
              className="w-full flex items-center justify-between px-4 py-4 bg-white rounded-xl border border-gray-200 shadow-sm hover:border-blue-500 hover:ring-2 hover:ring-blue-500 transition-all duration-200"
            >
              <div className="flex items-center">
                <PencilSquareIcon className="h-6 w-6 text-blue-600 mr-3" aria-hidden="true" />
                <span className="text-base font-semibold text-gray-900">Editer / Supprimer un VHL</span>
              </div>
              <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </button>

            <button
              type="button"
              onClick={() => (window.location.href = "/listuser")}
              className="w-full flex items-center justify-between px-4 py-4 bg-white rounded-xl border border-gray-200 shadow-sm hover:border-blue-500 hover:ring-2 hover:ring-blue-500 transition-all duration-200"
            >
              <div className="flex items-center">
                <UserGroupIcon className="h-6 w-6 text-blue-600 mr-3" aria-hidden="true" />
                <span className="text-base font-semibold text-gray-900">Liste des utilisateurs</span>
              </div>
              <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </button>
          </div>

          {/* Section FOR */}
          {currentUser.service === "FOR" && (
            <>
              <div className="relative py-4">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-200" />
                </div>
              </div>

              <div>
                <button
                  type="button"
                  onClick={() => (window.location.href = "/histoarmoire")}
                  className="w-full flex items-center justify-between px-4 py-4 bg-white rounded-xl border border-gray-200 shadow-sm hover:border-blue-500 hover:ring-2 hover:ring-blue-500 transition-all duration-200"
                >
                  <div className="flex items-center">
                    <span className="text-base font-semibold text-gray-900">Historique Armoire Clés</span>
                  </div>
                  <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PageAdmin;
