import React, { useEffect, useState } from "react";
import axios from "axios";
import authHeader from "../auth-header";
import { TrashIcon, PencilSquareIcon } from '@heroicons/react/20/solid';

export default function ListeAdminVHL() {
  const [vehicles, setVehicles] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  const deleteVHL = (id) => {
    if (
      window.confirm(
        "Êtes-vous sûr de vouloir supprimer ce véhicule ?"
      )
    ) {
      axios
        .delete(process.env.REACT_APP_BASE_URL + "/api/vhl/" + id, { headers: authHeader() })
        .then((res) => console.log(res.data));
      setVehicles(vehicles.filter((el) => el._id !== id));
    }
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/vhl/", { headers: authHeader() })
      .then((response) => {
        setVehicles(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  let filteredVehicles = vehicles;

  if (currentUser.service !== "GARAGE") {
    filteredVehicles = vehicles.filter(
      (vehicle) => currentUser && vehicle.service === currentUser.service
    );
  }
  
  let groupedVehicles = {};

  filteredVehicles.forEach((vhl) => {
    if (!groupedVehicles[vhl.service]) {
      groupedVehicles[vhl.service] = [];
    }
    groupedVehicles[vhl.service].push(vhl);
  });

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-8">Liste des véhicules </h2>
        
        <div className="space-y-6">
          {Object.entries(groupedVehicles).map(([service, vehiclesInService]) => (
            <div key={service} className="bg-white rounded-xl shadow-sm overflow-hidden">
              
              <ul className="divide-y divide-gray-200">
                {vehiclesInService.map((vhl) => (
                  <li key={vhl._id} className="hover:bg-gray-50 transition-colors duration-150">
                    <div className="flex items-center px-4 py-4">
                      <div className="flex-shrink-0">
                        <img 
                          className="h-12 w-12 rounded-lg object-cover bg-gray-100" 
                          src={vhl.image} 
                          alt="" 
                        />
                      </div>
                      <div className="ml-4 flex-1 min-w-0">
                        <div className="flex items-center justify-between">
                          <div>
                            <p className="text-sm font-semibold text-gray-900">{vhl.nomvhl}</p>
                            <p className="mt-1 text-sm text-gray-500 italic">{vhl.dossardorigine}</p>
                          </div>
                          <div className="flex items-center gap-4">
                            <button
                              onClick={() => deleteVHL(vhl._id)}
                              className="text-red-500 hover:text-red-700 transition-colors duration-150"
                            >
                              <TrashIcon className="h-5 w-5" />
                            </button>
                            <button
                              onClick={() => (window.location.href = `/edit/${vhl._id}`)}
                              className="text-blue-500 hover:text-blue-700 transition-colors duration-150"
                            >
                              <PencilSquareIcon className="h-5 w-5" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
