import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import authHeader from "../auth-header";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { 
  TrashIcon, 
  CalendarIcon, 
  UserIcon, 
  ClockIcon,
  ArrowLeftIcon,
  ExclamationTriangleIcon,
  TagIcon
} from '@heroicons/react/24/outline';

function DetailResa() {
  const [reservation, setReservation] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { id } = useParams();

  const deleteResa = () => {
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/reservations/${id}`, { headers: authHeader() })
      .then(() => {
        window.location = '/reservations/list';
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [reservationRes, userRes] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BASE_URL}/api/reservations/${id}`, { headers: authHeader() }),
          axios.get(`${process.env.REACT_APP_BASE_URL}/api/users/CurrentUser`, { headers: authHeader() })
        ]);
        setReservation(reservationRes.data);
        setCurrentUser(userRes.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const formatDate = (date) => {
    if (!date) return '';
    const formatString = currentUser.service === "CAB" ? 'dd MMMM yyyy à HH:mm' : 'dd MMMM yyyy';
    return format(new Date(date), formatString, { locale: fr });
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-900"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-3xl mx-auto px-4 py-8">
        {/* Bouton retour */}
        <Link
          to="/reservations/list"
          className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 mb-6"
        >
          <ArrowLeftIcon className="h-4 w-4 mr-1" />
          Retour aux réservations
        </Link>

        <div className="bg-white shadow-sm ring-1 ring-gray-900/5 rounded-xl overflow-hidden">
          {/* En-tête avec image */}
          <div className="relative h-48 bg-gradient-to-r from-blue-900 to-blue-800">
            <div className="absolute inset-0 flex items-center justify-center">
              <img
                className="h-32 w-32 rounded-lg object-cover ring-4 ring-white"
                src={reservation.imagevhl}
                alt={reservation.nomvhl}
              />
            </div>
          </div>

          {/* Informations principales */}
          <div className="px-6 py-4 border-b border-gray-200">
            <h1 className="text-2xl font-bold text-center text-gray-900">{reservation.nomvhl}</h1>
            {reservation.dossardorigine && (
              <p className="mt-1 text-sm font-semibold text-center text-gray-500">
                {reservation.dossardorigine}
              </p>
            )}
          </div>

          {/* Détails de la réservation */}
          <div className="px-6 py-4 space-y-4">
            <DetailItem
              icon={TagIcon}
              label="Type de réservation"
              value={reservation.typereservation}
            />
            <DetailItem
              icon={CalendarIcon}
              label="Date de début"
              value={formatDate(reservation.datedebut)}
            />
            <DetailItem
              icon={ClockIcon}
              label="Date de fin"
              value={formatDate(reservation.datefin)}
            />
            <DetailItem
              icon={UserIcon}
              label="Réservé par"
              value={reservation.nom}
            />
          </div>

          {/* Actions */}
          {(currentUser.roles === "Admin" || currentUser.username === reservation.nom) && (
            <div className="px-6 py-4 bg-gray-50 flex justify-center">
              <button
                type="button"
                onClick={() => setShowDeleteModal(true)}
                className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-red-600 rounded-md shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              >
                <TrashIcon className="h-5 w-5 mr-2" />
                Supprimer la réservation
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Modal de confirmation de suppression */}
      {showDeleteModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Supprimer la réservation
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Êtes-vous sûr de vouloir supprimer cette réservation ? Cette action ne peut pas être annulée.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                  onClick={deleteResa}
                >
                  Supprimer
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Annuler
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const DetailItem = ({ icon: Icon, label, value }) => (
  <div className="flex items-center text-sm">
    <Icon className="h-5 w-5 text-gray-400 mr-3" />
    <dt className="font-medium text-gray-900">{label}</dt>
    <dd className="ml-auto text-gray-500">{value}</dd>
  </div>
);

export default DetailResa;
