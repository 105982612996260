import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  IconButton,
  Badge,
  Chip,
  Divider,
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  DirectionsCar as CarIcon,
  ChevronRight as ChevronRightIcon,
  FiberNew as NewIcon,
  Build as BuildIcon,
  CalendarToday as CalendarIcon
} from '@mui/icons-material';
import authHeader from '../auth-header';

export default function ListForGarageDmdInterv() {
  const [dmdGarage, setDmdGarage] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + '/api/dmdintervgarage/', { headers: authHeader() })
      .then((response) => {
        setDmdGarage(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get(process.env.REACT_APP_BASE_URL + '/api/users/CurrentUser', { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('fr-FR', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }).format(date);
  }

  function handleListItemClick(Id) {
    const vehicleToUpdate = dmdGarage.find((vehicle) => vehicle._id === Id);

    if (!vehicleToUpdate) {
      console.error(`Vehicle with ID ${Id} not found.`);
      return;
    }

    if (vehicleToUpdate.idusers.includes(currentUser.username)) {
      navigate(`/dmdintervgarage/${Id}`);
    } else {
      const updatedIdUsers = [...vehicleToUpdate.idusers, currentUser.username];

      axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/dmdintervgarage/update/${Id}`,
        { idusers: updatedIdUsers },
        { headers: authHeader() }
      )
        .then((res) => {
          const updatedDmdGarage = dmdGarage.map((vehicle) =>
            vehicle._id === Id ? { ...vehicle, idusers: updatedIdUsers } : vehicle
          );
          setDmdGarage(updatedDmdGarage);
          navigate(`/dmdintervgarage/${Id}`);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      {/* Header Card */}
      <Card 
        elevation={3}
        sx={{ 
          position: 'relative',
          overflow: 'hidden',
          mb: 4
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '100%',
            background: 'linear-gradient(135deg, rgba(26, 35, 126, 0.8) 0%, rgba(13, 71, 161, 0.8) 100%)',
            zIndex: 1
          }}
        />
        <CardContent sx={{ position: 'relative', zIndex: 2, color: 'white', textAlign: 'center', py: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2, mb: 2 }}>
            <CarIcon sx={{ fontSize: 40 }} />
            <BuildIcon sx={{ fontSize: 40 }} />
          </Box>
          <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
            Demandes d'interventions
          </Typography>
          <Typography variant="subtitle1" sx={{ opacity: 0.9 }}>
            Liste des demandes d'intervention en attente
          </Typography>
        </CardContent>
      </Card>

      {/* List Card */}
      <Card elevation={2}>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {dmdGarage.slice().reverse().map((vhl, index) => (
            <React.Fragment key={vhl._id}>
              {index > 0 && <Divider component="li" />}
              <ListItem
                button
                onClick={() => handleListItemClick(vhl._id)}
                sx={{
                  py: 2,
                  '&:hover': {
                    backgroundColor: 'action.hover',
                  }
                }}
              >
                <ListItemAvatar>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    badgeContent={
                      !vhl.idusers.includes(currentUser.username) ? (
                        <NewIcon sx={{ fontSize: 20, color: 'error.main' }} />
                      ) : null
                    }
                  >
                    <Avatar
                      src={vhl.image}
                      alt={vhl.nomvhl}
                      sx={{
                        width: 56,
                        height: 56,
                        mr: 2,
                        border: '2px solid',
                        borderColor: 'primary.main'
                      }}
                    >
                      <CarIcon />
                    </Avatar>
                  </Badge>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" fontWeight="medium">
                      {vhl.nomvhl}
                    </Typography>
                  }
                  secondary={
                    <Box sx={{ mt: 0.5 }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ fontStyle: 'italic' }}
                      >
                        {vhl.dossardorigine}
                      </Typography>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 1,
                        mt: 0.5,
                        color: 'text.secondary',
                        fontSize: '0.75rem'
                      }}>
                        <CalendarIcon sx={{ fontSize: '1rem' }} />
                        {formatDate(vhl.createdAt)}
                      </Box>
                      {!isMobile && (
                        <Chip
                          label={vhl.service}
                          size="small"
                          sx={{ mt: 1 }}
                          color="primary"
                          variant="outlined"
                        />
                      )}
                    </Box>
                  }
                  sx={{ mr: 2 }}
                />
                {isMobile ? (
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'flex-end',
                      gap: 0.5
                    }}
                  >
                    {vhl.service && (
                      <Typography 
                        variant="caption" 
                        color="primary"
                        sx={{ fontWeight: 'medium' }}
                      >
                        {vhl.service}
                      </Typography>
                    )}
                    <ChevronRightIcon color="action" />
                  </Box>
                ) : (
                  <ListItemSecondaryAction>
                    <IconButton 
                      edge="end" 
                      component={Link} 
                      to={`/dmdintervgarage/${vhl._id}`}
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Card>
    </Container>
  );
}
