import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import authHeader from "../auth-header";
import { CheckIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function HistoRevision() {
  const [histovhl, setHistoVhl] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [vhl, setVhl] = useState([]);

  const facteurrevision = ["FOR", "FSP", "FI", "SMS", "AMS"].includes(vhl.service)
    ? 10000
    : 20000;

  const revisions = [];
  for (let km = 0; km <= 400000; km += facteurrevision) {
    revisions.push({ title: `Révision des ${km} km`, km, etat: 'grayed', remarque: '', kmeffectif: '', date: '' });
  }

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/historiquerevision/" + id, {
        headers: authHeader(),
      })
      .then((response) => {
        setHistoVhl(response.data);
      });

    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/vhl/" + id, { headers: authHeader() })
      .then((response) => {
        setVhl(response.data);
      });

    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      });
  }, []);

  const deleteRevision = (revisionId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette révision ?')) {
      axios
        .delete(process.env.REACT_APP_BASE_URL + "/api/historiquerevision/" + revisionId, { headers: authHeader() })
        .then(() => {
          setHistoVhl(histovhl.filter((entry) => entry._id !== revisionId));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // Mettre à jour les révisions avec les données récupérées
  revisions.forEach((revision) => {
    const matchingEntry = histovhl.find((entry) => entry.km === revision.km);
    if (matchingEntry) {
      revision.etat = 'complete';
      revision.remarque = matchingEntry.remarque;
      revision.kmeffectif = matchingEntry.kmeffectif;
      revision.date = matchingEntry.date;
      revision.id = matchingEntry._id; // Ajout de l'ID pour suppression
    }
  });

  // Trouver le kilométrage de la première révision enregistrée
  const firstRecordedKm = Math.min(...histovhl.map((entry) => entry.km), Infinity);

  // Si aucune révision n'est enregistrée, on garde tout
  const startKm = firstRecordedKm === Infinity ? 0 : firstRecordedKm;

  // Filtrer les révisions pour n'inclure que celles à partir du premier kilométrage enregistré
  const filteredRevisions = revisions.filter((revision) => revision.km >= startKm);

  return (
    <div className="bg-gray-100">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
        <div className="bg-white rounded-2xl shadow-lg p-6 transform hover:scale-[1.01] transition-all duration-300">
          {/* En-tête */}
          <div className="flex flex-col items-center mb-8">
            <h3 className="text-2xl font-bold text-gray-900">
              Historique des révisions
            </h3>
            <p className="mt-2 text-lg text-gray-500">
              {vhl.immatriculation}
            </p>
          </div>

          {/* Timeline des révisions */}
          <nav aria-label="Progress" className="px-4">
            <ol role="list" className="overflow-hidden">
              {filteredRevisions.map((revision, revisionID) => (
                <li 
                  key={revision.title} 
                  className={classNames(
                    revisionID !== filteredRevisions.length - 1 ? 'pb-10' : '', 
                    'relative'
                  )}
                >
                  {/* Révision complétée */}
                  {revision.etat === 'complete' ? (
                    <>
                      {revisionID !== filteredRevisions.length - 1 && (
                        <div 
                          className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-blue-600" 
                          aria-hidden="true" 
                        />
                      )}
                      <div className="group relative flex items-start">
                        <span className="flex h-9 items-center">
                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-blue-600 ring-2 ring-white">
                            <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                          </span>
                        </span>
                        <div className="ml-4 flex min-w-0 flex-col bg-blue-50 rounded-lg p-4 hover:bg-blue-100 transition-colors duration-200">
                          <span className="text-sm font-semibold text-blue-900">{revision.title}</span>
                          <div className="mt-2 space-y-1">
                            <div className="flex items-center text-sm text-gray-600">
                              <span className="font-medium mr-2">Date :</span>
                              {new Date(revision.date).toLocaleDateString('fr-FR', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric'
                              })}
                            </div>
                            <div className="flex items-center text-sm text-gray-600">
                              <span className="font-medium mr-2">Kilométrage :</span>
                              {revision.kmeffectif}
                            </div>
                            {revision.remarque && (
                              <div className="flex items-center text-sm text-gray-600">
                                <span className="font-medium mr-2">Remarques :</span>
                                {revision.remarque}
                              </div>
                            )}
                          </div>
                          {currentUser.roles === "Admin" && (
                            <button
                              onClick={() => deleteRevision(revision.id)}
                              className="mt-3 inline-flex items-center text-sm text-red-600 hover:text-red-900 transition-colors duration-200"
                            >
                              <TrashIcon className="h-4 w-4 mr-1" aria-hidden="true" />
                              Supprimer
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  ) : revision.etat === 'upcoming' ? (
                    <>
                      {revisionID !== filteredRevisions.length - 1 && (
                        <div 
                          className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" 
                          aria-hidden="true" 
                        />
                      )}
                      <div className="group relative flex items-start" aria-current="step">
                        <span className="flex h-9 items-center" aria-hidden="true">
                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-blue-600 bg-white ring-2 ring-white">
                            <span className="h-2.5 w-2.5 rounded-full bg-blue-600" />
                          </span>
                        </span>
                        <div className="ml-4 flex min-w-0 flex-col bg-yellow-50 rounded-lg p-4">
                          <span className="text-sm font-semibold text-blue-900">{revision.title}</span>
                          <span className="text-sm text-yellow-800">Prochaine révision</span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {revisionID !== filteredRevisions.length - 1 && (
                        <div 
                          className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" 
                          aria-hidden="true" 
                        />
                      )}
                      <div className="group relative flex items-start">
                        <span className="flex h-9 items-center" aria-hidden="true">
                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white ring-2 ring-white">
                            <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                          </span>
                        </span>
                        <div className="ml-4 flex min-w-0 flex-col bg-gray-50 rounded-lg p-4">
                          <span className="text-sm font-medium text-gray-700">{revision.title}</span>
                          <span className="text-sm text-gray-500">Révision future</span>
                        </div>
                      </div>
                    </>
                  )}
                </li>
              ))}
            </ol>
          </nav>

          {/* Bouton d'ajout pour les admins */}
          {currentUser.roles === "Admin" && (
            <div className="fixed bottom-6 right-6">
              <button
                type="button"
                onClick={() => (window.location.href = `/newrevision/${vhl._id}`)}
                className="rounded-full bg-blue-600 p-3 text-white shadow-lg hover:bg-blue-700 transform hover:scale-110 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <PlusCircleIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
