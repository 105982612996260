import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import authHeader from "../auth-header";

export default function HistoPerception() {
  const [histovhl, setHistoVhl] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/historiqueperception/" + id, {
        headers: authHeader(),
      })
      .then((response) => {
        setHistoVhl(response.data);
        console.log("Données histovhl reçues:", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
        console.log("Current user reçu:", response.data);
        console.log("Service de l'utilisateur:", response.data.service);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  // Filtrer les véhicules par service
  const filteredVehicles = histovhl.filter((item) => {
    console.log("Vérification de l'item:", item);
    console.log("Service de l'item:", item.service);
    console.log("Service de l'utilisateur:", currentUser?.service);
    console.log("Correspond ?", item.service === currentUser?.service);
    
    // Supprimer temporairement le filtre pour voir toutes les données
    return true;
    // return currentUser && item.service === currentUser.service;
  });
  console.log("Véhicules filtrés:", filteredVehicles);

  // Trier les véhicules par date
  const sortedVehicles = filteredVehicles.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });
  console.log("Véhicules triés:", sortedVehicles);

  // Grouper les véhicules par date
  const groupedVehicles = sortedVehicles.reduce((groups, vhl) => {
    const date = new Date(vhl.date);
    const day = date.getDate();
    const month = date.toLocaleString('fr-FR', { month: 'long' });
    const year = date.getFullYear();
    const formattedDate = `${day} ${month} ${year}`;

    if (!groups[formattedDate]) {
      groups[formattedDate] = [];
    }
    groups[formattedDate].push(vhl);
    return groups;
  }, {});
  console.log("Véhicules groupés:", groupedVehicles);

  return (
    <div className="bg-gray-100">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
        <div className="bg-white rounded-2xl shadow-lg p-4 transform hover:scale-[1.01] transition-all duration-300">
          {/* En-tête */}
          <div className="flex flex-col items-center mb-6">
            <h3 className="text-2xl font-bold text-gray-900">
              Historique des perceptions
            </h3>
            <p className="mt-2 text-lg text-gray-500 font-semibold">
              {histovhl.length > 0 ? histovhl[0].nomvhl : ""}
            </p>
          </div>

          {/* Liste des perceptions */}
          <nav className="h-full overflow-y-auto" aria-label="Directory">
            {Object.entries(groupedVehicles).map(([date, vehicles]) => (
              <div key={date} className="relative mb-4 last:mb-0">
                {/* En-tête de date */}
                <div className="sticky top-0 z-10 bg-gray-50 rounded-lg shadow-sm mb-2">
                  <div className="px-4 py-2 flex items-center justify-center">
                    <div className="text-sm font-semibold text-gray-900">
                      {date}
                    </div>
                  </div>
                </div>

                {/* Liste des perceptions pour cette date */}
                <ul role="list" className="space-y-2">
                  {vehicles.map((vhl) => (
                    <li key={vhl._id}>
                      <Link
                        to={`/historiqueperception/detail/${vhl._id}`}
                        className="block bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-200 transform hover:scale-[1.02]"
                      >
                        <div className="px-4 py-3 flex items-center justify-between">
                          <div className="min-w-0 flex-1">
                            <div className="flex items-center justify-between">
                              <div>
                                <p className="text-sm font-semibold text-gray-900 mb-1">
                                  {vhl.utilisateur}
                                </p>
                                <p className="text-sm text-gray-500">
                                  {vhl.emploi}
                                </p>
                              </div>
                              <div className="flex items-center">
                                <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                                  {vhl.position}
                                </span>
                                <ChevronRightIcon
                                  className="ml-4 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                            <div className="mt-2 flex items-center text-xs text-gray-500 space-x-2">
                              <span>Kilométrage : {vhl.km}</span>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}

            {/* Message si aucune perception */}
            {Object.keys(groupedVehicles).length === 0 && (
              <div className="text-center py-12">
                <p className="text-sm text-gray-500">
                  Aucun historique de perception disponible
                </p>
              </div>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
}
