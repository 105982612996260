import * as React from "react";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import { PlusIcon, TrashIcon, PencilSquareIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import authHeader from "../auth-header";

const UserList = () => {
  const [users, setUsers] = React.useState([]);
  const [currentUser, setCurrentUser] = React.useState({});
  const [sortConfig, setSortConfig] = React.useState({ key: null, direction: 'ascending' });

  React.useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/", { headers: authHeader() })
      .then((response) => {
      setUsers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
      axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const deleteUser = (id) => {
    if (
      window.confirm(
        "Êtes-vous sûr de vouloir supprimer cet utilisateur ?"
      )
    ) {
      axios
        .delete(`${process.env.REACT_APP_BASE_URL}/api/users/${id}`, { headers: authHeader() })
        .then((res) => {
          console.log(res.data);
          setUsers(users.filter((user) => user._id !== id));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedUsers = React.useMemo(() => {
    if (!sortConfig.key) return users;

    return [...users].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  }, [users, sortConfig]);

  const SortIcon = ({ columnKey }) => {
    if (sortConfig.key !== columnKey) {
      return <ChevronUpIcon className="h-4 w-4 text-gray-400" />;
    }
    return sortConfig.direction === 'ascending' ? (
      <ChevronUpIcon className="h-4 w-4 text-blue-500" />
    ) : (
      <ChevronDownIcon className="h-4 w-4 text-blue-500" />
    );
  };

  return (
    currentUser.roles === "Admin" && (
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">Utilisateurs</h1>
            </div>
            <div className="mt-4 sm:mt-0">
              <button
                type="button"
                onClick={() => (window.location.href = "/user")}
                className="inline-flex items-center rounded-lg bg-blue-900 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-900 transition-colors duration-200"
              >
                <PlusIcon className="h-5 w-5 mr-2" />
                Ajouter un utilisateur
              </button>
            </div>
          </div>

          <div className="mt-8 bg-white rounded-lg shadow-sm overflow-hidden">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th 
                      scope="col" 
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 cursor-pointer hover:bg-gray-100"
                      onClick={() => requestSort('username')}
                    >
                      <div className="flex items-center gap-2">
                        Nom
                        <SortIcon columnKey="username" />
                      </div>
                    </th>
                    <th 
                      scope="col" 
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer hover:bg-gray-100"
                      onClick={() => requestSort('service')}
                    >
                      <div className="flex items-center gap-2">
                        Service
                        <SortIcon columnKey="service" />
                      </div>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Role
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {sortedUsers.map((user) => (
                    <tr key={user.username} className="hover:bg-gray-50 transition-colors duration-150">
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {user.username}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-blue-700">
                          {user.service}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-green-700">
                          {user.roles}
                        </span>
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <div className="flex justify-end gap-3">
                          <button
                            onClick={() => (window.location.href = `/edituser/${user._id}`)}
                            className="text-blue-600 hover:text-blue-900 transition-colors duration-150"
                          >
                            <PencilSquareIcon className="h-5 w-5" />
                          </button>
                          <button
                            onClick={() => deleteUser(user._id)}
                            className="text-red-600 hover:text-red-900 transition-colors duration-150"
                          >
                            <TrashIcon className="h-5 w-5" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default UserList;
