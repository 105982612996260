import React, { useState, useEffect } from 'react';
import authHeader from "../auth-header";
import axios from 'axios';
import { CalendarDaysIcon, ListBulletIcon, ClockIcon } from '@heroicons/react/20/solid';

function AccueilReservation() {
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* En-tête avec image */}
        <div className="relative rounded-xl overflow-hidden shadow-lg mb-8 bg-white">
          <div className="h-48 sm:h-64 w-full relative">
            <img 
              className="w-full h-full object-cover" 
              src='images/reservation.jpeg'
              alt="Réservation de véhicules"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
            <div className="absolute bottom-0 left-0 right-0 p-6">
              <h1 className="text-2xl sm:text-3xl font-bold text-white mb-2">
                Réservation de véhicules
              </h1>
            </div>
          </div>
        </div>

        {/* Grille de boutons */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {/* Bouton Réservations en cours */}
          <div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-200 overflow-hidden">
            <button
              type="button"
              onClick={() => (window.location.href = "reservations/list")}
              className="w-full h-full p-6 text-left focus:outline-none"
            >
              <div className="flex items-center space-x-4">
                <div className="p-3 bg-blue-50 rounded-lg">
                  <ListBulletIcon className="h-6 w-6 text-blue-900" aria-hidden="true" />
                </div>
                <h2 className="text-lg font-semibold text-gray-900">Réservations en cours</h2>
              </div>
            </button>
          </div>
          {/* Bouton Réserver un VHL */}
          <div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-200 overflow-hidden">
            <button
              type="button"
              onClick={() =>
                (window.location.href =
                  ["CAB", "EMOPS", "R&D", "DT"].includes(currentUser.service)
                    ? "reservations/formtime"
                    : "reservations/form")
              }
              className="w-full h-full p-6 text-left focus:outline-none"
            >
              <div className="flex items-center space-x-4">
                <div className="p-3 bg-blue-900 rounded-lg">
                  {["CAB", "EMOPS", "R&D", "DT"].includes(currentUser.service) ? (
                    <ClockIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  ) : (
                    <CalendarDaysIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  )}
                </div>
                <h2 className="text-lg font-semibold text-gray-900">Réserver un véhicule</h2>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccueilReservation;
