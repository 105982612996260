import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Grid,
  Avatar,
  IconButton,
  Paper,
  Divider,
  useTheme,
  useMediaQuery,
  FormHelperText,
  Slider
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fr } from 'date-fns/locale';
import {
  DirectionsCar as CarIcon,
  Build as BuildIcon,
  Assignment as TaskIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import authHeader from "../auth-header";

const NewTache = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [idvhl, setIdVHL] = useState("");
  const [nomvhl, setVHL] = useState("");
  const [dossardorigine, setImmat] = useState("");
  const [service, setService] = useState("");
  const [km, setKm] = useState("");
  const [image, setImage] = useState("");
  const [date, setDate] = useState(null);
  const [nomgaragiste, setNom] = useState("");
  const [selectedTravail, setselectedTypetravail] = useState(null);
  const [typetravail, setTypetravail] = useState("");
  const [remarques, setRemarques] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [selectedVHL, setSelectedVHL] = useState(null);
  const [priorite, setPriorite] = useState(0);
  const [couleur, setCouleur] = useState("Absent");

  const typetravailOptions = [
    { id: 1, categorie: 'Méca', name: 'Check VHL' },
    { id: 2, categorie: 'Méca', name: 'Visite' },
    { id: 3, categorie: 'Méca', name: 'Accident' },
    { id: 4, categorie: 'Méca', name: 'Confection de plaques' },
    { id: 5, categorie: 'Méca', name: 'Liquide de frein' },
    { id: 6, categorie: 'Méca', name: 'Pneumatique' },
    { id: 7, categorie: 'Méca', name: 'Frein' },
    { id: 8, categorie: 'Méca', name: 'Distribution' },
    { id: 9, categorie: 'Méca', name: 'Embrayage' },
    { id: 10, categorie: 'Méca', name: 'Recherche de panne' },
    { id: 11, categorie: 'Autre', name: 'Secrétariat' },
    { id: 12, categorie: 'Autre', name: 'Instruction' },
    { id: 13, categorie: 'Autre', name: 'Garde' },
    { id: 14, categorie: 'Autre', name: 'Repos / Perms' },
    { id: 15, categorie: 'Autre', name: 'Sport' },
    { id: 16, categorie: 'Autre', name: 'Depannage' },
    { id: 17, categorie: 'Autre', name: 'Nettoyage' },
    { id: 18, categorie: 'Autre', name: 'Série 0' },
    { id: 19, categorie: 'Autre', name: 'Cérémonie / Dem' },
    { id: 20, categorie: 'Autre', name: 'Liaison' },
    { id: 21, categorie: 'Autre', name: 'Réunion' },
    { id: 22, categorie: 'Autre', name: 'OPEX' },
    { id: 23, categorie: 'Autre', name: 'Désequipement GIE' },
  ];

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/vhl/", { headers: authHeader() })
      .then((response) => {
        setVehicles(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const groupedTypetravailOptions = typetravailOptions.reduce((acc, option) => {
    if (!acc[option.categorie]) {
      acc[option.categorie] = [];
    }
    acc[option.categorie].push(option);
    return acc;
  }, {});

  const handleChangeVHL = (event, newValue) => {
    setSelectedVHL(newValue);
    if (newValue) {
      setIdVHL(newValue._id || "");
      setService(newValue.service || "");
      setVHL(newValue.nomvhl || "");
      setImmat(newValue.dossardorigine || "");
      setKm(newValue.km || "");
      setImage(newValue.image || "");
    } else {
      setIdVHL("");
      setService("");
      setVHL("");
      setImmat("");
      setKm("");
      setImage("");
    }
  };

  const handleChangeTypeTravail = (event, newValue) => {
    setselectedTypetravail(newValue);
    if (newValue) {
      setTypetravail(newValue.name);
    } else {
      setTypetravail("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const tache = {
      idvhl,
      nomvhl,
      service,
      dossardorigine,
      km,
      date,
      image,
      nomgaragiste,
      typetravail,
      remarques,
      priorite,
      couleur
    };

    axios
      .post(process.env.REACT_APP_BASE_URL + "/api/planninggarage/add", tache, {
        headers: authHeader(),
      })
      .then((res) => {
        navigate("/calendriertachesv2");
      })
      .catch((error) => {
        console.error("Erreur lors de la création de la tâche:", error);
      });
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      {/* Header Card */}
      <Card 
        elevation={3}
        sx={{ 
          position: 'relative',
          overflow: 'hidden',
          mb: 4
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '100%',
            background: 'linear-gradient(135deg, rgba(26, 35, 126, 0.8) 0%, rgba(13, 71, 161, 0.8) 100%)',
            zIndex: 1
          }}
        />
        <CardContent sx={{ position: 'relative', zIndex: 2, color: 'white', textAlign: 'center', py: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2, mb: 2 }}>
            <TaskIcon sx={{ fontSize: 40 }} />
            <BuildIcon sx={{ fontSize: 40 }} />
          </Box>
          <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
            Nouvelle tâche
          </Typography>
          <Typography variant="subtitle1" sx={{ opacity: 0.9 }}>
            Créer une nouvelle tâche de maintenance
          </Typography>
        </CardContent>
      </Card>

      {/* Form Card */}
      <Paper elevation={2} sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {/* Vehicle Selection */}
            <Grid item xs={12}>
              <Autocomplete
                value={selectedVHL}
                onChange={handleChangeVHL}
                options={vehicles}
                groupBy={(option) => option.service}
                getOptionLabel={(option) => option.nomvhl || ''}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Avatar
                      src={option.image}
                      alt={option.nomvhl}
                      sx={{ width: 32, height: 32, mr: 2 }}
                    >
                      <CarIcon />
                    </Avatar>
                    <Box>
                      <Typography variant="subtitle2">{option.nomvhl}</Typography>
                      <Typography variant="caption" color="text.secondary">
                        {option.dossardorigine} - {option.service}
                      </Typography>
                    </Box>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sélectionner un véhicule"
                    required
                    fullWidth
                  />
                )}
              />
            </Grid>

            {/* Task Type Selection */}
            <Grid item xs={12} md={6}>
              <Autocomplete
                value={selectedTravail}
                onChange={handleChangeTypeTravail}
                options={typetravailOptions}
                groupBy={(option) => option.categorie}
                getOptionLabel={(option) => option.name || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Type de travail"
                    required
                    fullWidth
                  />
                )}
              />
            </Grid>

            {/* Date Selection */}
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                <DatePicker
                  label="Date"
                  value={date}
                  onChange={(newValue) => setDate(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} required fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            {/* Priority Slider */}
            <Grid item xs={12}>
              <Typography gutterBottom>
                Priorité
              </Typography>
              <Slider
                value={priorite}
                onChange={(e, newValue) => setPriorite(newValue)}
                step={1}
                marks
                min={0}
                max={5}
                valueLabelDisplay="auto"
              />
            </Grid>

            {/* Mechanic Name */}
            <Grid item xs={12} md={6}>
              <TextField
                label="Nom du garagiste"
                value={nomgaragiste}
                onChange={(e) => setNom(e.target.value)}
                required
                fullWidth
              />
            </Grid>

            {/* Comments */}
            <Grid item xs={12}>
              <TextField
                label="Remarques"
                value={remarques}
                onChange={(e) => setRemarques(e.target.value)}
                multiline
                rows={4}
                fullWidth
              />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                startIcon={<SaveIcon />}
                sx={{ mt: 2 }}
              >
                Créer la tâche
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default NewTache;
