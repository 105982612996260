import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CalendarDaysIcon, CheckBadgeIcon, ChevronDownIcon, PencilSquareIcon } from '@heroicons/react/20/solid';
import "react-datepicker/dist/react-datepicker.css";
import authHeader from "../auth-header";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { XCircleIcon } from "@heroicons/react/20/solid";
import { Switch } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function PageVHL(props) {
  const [vhl, setVhl] = useState({});
  const [isLoading, setisLoading] = useState(true);
  const [newKm, setNewKm] = useState("");
  const [derniereRevision, setDerniereRevision] = useState("");
  const [newRemarques, setNewRemarques] = useState(vhl.remarques);
  const [currentUser, setCurrentUser] = useState({});
  const [RDVvhl, setRDVVhl] = useState([]);
  const [HistoRevision, setHistoRevision] = useState([]);
  const [ProchaineKmRevision, setProchaineKmRevision] = useState(null);
  const [dmdGarage, setDmdGarage] = useState([]);


  const { id } = useParams();

  const [openKM, setOpenKM] = React.useState(false);
  const [openRemarques, setOpenRemarques] = React.useState(false);

  const cancelButtonRef = useRef(null)
  const cancelButtonRefRemarques = useRef(null)
  const [enabled, setEnabled] = useState(false)
  const [enabledA, setEnabledA] = useState(false)


  useEffect(() => {
    setEnabled(vhl.pneusneige);
  }, [vhl.pneusneige]);

  useEffect(() => {
    setEnabledA(vhl.attelage);
  }, [vhl.attelage]);


  const handleClickOpenKM = () => {
    setOpenKM(true);
  };

  const handleClickOpenRemarques = () => {
    setOpenRemarques(true);
  };

  const handleCloseKM = () => {
    setOpenKM(false);
  };

  const handleCloseRemarques = () => {
    setOpenRemarques(false);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isLoading) {
          // Récupération des informations du véhicule
          const vhlResponse = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/vhl/${id}`,
            { headers: authHeader() }
          );
          setVhl(vhlResponse.data);
          setNewRemarques(vhlResponse.data.remarques);
          setisLoading(false);
  
          // Déterminer facteurrevision en fonction de vhl.service
          const facteurrevision = ["FOR", "FSP", "FI", "SMS", "AMS"].includes(vhlResponse.data.service)
            ? 10000
            : 20000;
  
          // Récupérer l'historique des révisions
          const histoRevisionResponse = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/historiquerevision/${id}`,
            { headers: authHeader() }
          );
          setHistoRevision(histoRevisionResponse.data);
  
          // Trouver la révision avec le plus grand kilométrage
          const maxRevision = histoRevisionResponse.data.reduce(
            (max, current) => (current.km > (max?.km || 0) ? current : max),
            null
          );
  
          // Calculer et définir la prochaine révision
          setProchaineKmRevision((maxRevision?.km || 0) + facteurrevision);
          setDerniereRevision(maxRevision);
        }
  
        // Récupération de l'utilisateur actuel
        const userResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/users/CurrentUser`,
          { headers: authHeader() }
        );
        setCurrentUser(userResponse.data);
  
        // Récupération des demandes d'intervention et des rendez-vous de garage
        const [dmdGarageResponse, rdvGarageResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BASE_URL}/api/dmdintervgarage/`, { headers: authHeader() }),
          axios.get(`${process.env.REACT_APP_BASE_URL}/api/rdvgarage/`, { headers: authHeader() })
        ]);
        setDmdGarage(dmdGarageResponse.data);
        setRDVVhl(rdvGarageResponse.data);
  
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData();
  }, [id, isLoading]);
  
  
  function ValiderKM(e) {
    setOpenKM(false);
    const updatedValue = { ...vhl, km: newKm };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/vhl/update/${id}`, updatedValue, {
        headers: authHeader(),
      })
      .then(() => setVhl((prevVhl) => ({ ...prevVhl, ...updatedValue })))
      .catch((error) => console.error("Erreur lors de la validation des KM : ", error));
  }
  

  function ValiderRemarques(e) {
    setOpenRemarques(false);
    const updatedValue = { ...vhl, remarques: newRemarques };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/vhl/update/${id}`, updatedValue, {
        headers: authHeader(),
      })
      .then(() => setVhl((prevVhl) => ({ ...prevVhl, ...updatedValue })))
      .catch((error) => console.error("Erreur lors de la validation des remarques : ", error));
  }

  const handleSwitchChangePneus = (value) => {
    setEnabled(value);
    setVhl((prevVhl) => {
      const updatedValue = { pneusneige: value };
      const newVhl = { ...prevVhl, ...updatedValue };
      
      axios
        .post(process.env.REACT_APP_BASE_URL + "/api/vhl/update/" + id, newVhl, {
          headers: authHeader(),
        })
      
      return newVhl;
    });
  };  

  const handleSwitchChangeAttelage = (value) => {
    setEnabledA(value);
    setVhl((prevVhl) => {
      const updatedValue = { attelage: value };
      const newVhl = { ...prevVhl, ...updatedValue };
      
      axios
        .post(process.env.REACT_APP_BASE_URL + "/api/vhl/update/" + id, newVhl, {
          headers: authHeader(),
        })
      
      return newVhl;
    });
  };  

  // Déterminer facteurrevision en fonction de vhl.service
  const facteurrevision = ["FOR", "FSP", "FI", "SMS", "AMS"].includes(vhl.service)
  ? 10000
  : 20000;

  const progress = ((vhl.km - (ProchaineKmRevision - facteurrevision)) / facteurrevision) * 100;


  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 space-y-2">
        {/* En-tête avec image et informations principales */}
        <div className="bg-white rounded-2xl shadow-lg p-8 transform hover:scale-[1.02] transition-all duration-300">
          <div className="flex flex-col items-center">
            <div className="relative group">
              <div className="absolute inset-0 bg-blue-900 rounded-full opacity-10 group-hover:opacity-20 animate-pulse transition-opacity"></div>
              <img
                className="relative h-48 w-48 rounded-full object-cover border-4 border-white shadow-lg transform group-hover:scale-105 transition-transform duration-300"
                src={vhl.image}
                alt={vhl.nomvhl}
              />
            </div>
            <h1 className="mt-6 text-3xl font-bold text-gray-900">{vhl.nomvhl}</h1>
            <p className="mt-2 text-lg text-gray-500 font-semibold">{vhl.dossardorigine}</p>
          </div>

          {/* Bouton de perception */}
          <div className="mt-4 flex justify-center">
            <button
              type="button"
              onClick={() => (window.location.href = `/perception/${vhl._id}`)}
              className="inline-flex items-center px-8 py-3 border border-transparent text-base font-medium rounded-xl text-white bg-blue-900 hover:bg-blue-800 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 transition-all duration-200 shadow-md"
            >
              Perception
            </button>
          </div>
        </div>

        {/* Alertes et notifications */}
        <div className="space-y-4">
          {vhl.km > (ProchaineKmRevision - 1000) && (
            <div className="bg-red-50 border-l-4 border-red-400 rounded-lg p-4 shadow-md transform hover:scale-[1.01] transition-all duration-200">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
              </div>
              <div className="ml-4">
                <h3 className="text-lg font-semibold text-red-800">Attention à la révision !</h3>
                <div className="mt-2 text-sm text-red-700">
                  <ul className="list-disc space-y-2 pl-5">
                    <li>Prévue à : {ProchaineKmRevision}</li>
                    <li>Kilométrage actuel : {vhl.km}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          )}
          {RDVvhl.some(rdv => rdv.idvhl === vhl._id) && (
            <div className="bg-red-50 border-l-4 border-red-400 rounded-lg p-4 shadow-md transform hover:scale-[1.01] transition-all duration-200">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-4">
                  <h3 className="text-lg font-semibold text-red-800">Attention, RDV GARAGE prévu :</h3>
                  <div className="mt-2 text-sm text-red-700">
                    <ul className="list-disc space-y-2 pl-5">
                      <li>Le {format(new Date(RDVvhl.find(rdv => rdv.idvhl === vhl._id).daterdv), 'dd LLLL yyyy', { locale: fr })}</li>
                      <li>Raison : {RDVvhl.find(rdv => rdv.idvhl === vhl._id).typerdv}</li>
                      <li>Détails : {RDVvhl.find(rdv => rdv.idvhl === vhl._id).remarque}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}

          {dmdGarage.some(dmdGarage => dmdGarage.idvhl === vhl._id) && (
            <div className="bg-green-50 border-l-4 border-green-400 rounded-lg p-4 shadow-md transform hover:scale-[1.01] transition-all duration-200">
              <div className="flex">
                <div className="flex-shrink-0">
                  <CheckBadgeIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                </div>
                <div className="ml-4">
                  <h3 className="text-lg font-semibold text-green-800">Demande Garage effectuée :</h3>
                  <div className="mt-2 text-sm text-green-700">
                    <ul className="list-disc space-y-2 pl-5">
                      <li>Pour : {dmdGarage.find(dmdGarage => dmdGarage.idvhl === vhl._id).typedemande}</li>
                      <li>Détails : {dmdGarage.find(dmdGarage => dmdGarage.idvhl === vhl._id).details}</li>
                      <li>Par : {dmdGarage.find(dmdGarage => dmdGarage.idvhl === vhl._id).nom}</li>
                      <li>Le {format(new Date(dmdGarage.find(dmd => dmd.idvhl === vhl._id).createdAt), ' d MMMM yyyy', { locale: fr })}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Situation du véhicule */}
        <div className="bg-white rounded-xl shadow-lg overflow-hidden transform hover:scale-[1.01] transition-all duration-200">
          <div className="px-6 py-3">
            <h3 className="text-xl font-semibold text-gray-900">Situation du véhicule</h3>
          </div>
          <div className="border-t border-gray-200">
            <dl className="divide-y divide-gray-200">
              <div className="px-6 py-3 grid grid-cols-3 gap-4 hover:bg-gray-50 transition-colors duration-150">
                <dt className="text-sm font-semibold text-gray-500">Position</dt>
                <dd className="text-sm text-gray-900 col-span-2">{vhl.position}</dd>
              </div>
              <div className="px-6 py-3 grid grid-cols-3 gap-4 hover:bg-gray-50 transition-colors duration-150">
                <dt className="text-sm font-semibold text-gray-500">Emploi</dt>
                <dd className="text-sm text-gray-900 col-span-2">{vhl.emploi}</dd>
              </div>
              <div className="px-6 py-3 grid grid-cols-3 gap-4 hover:bg-gray-50 transition-colors duration-150">
                <dt className="text-sm font-semibold text-gray-500">Utilisateur</dt>
                <dd className="text-sm text-gray-900 col-span-2">{vhl.utilisateur}</dd>
              </div>
              {currentUser.service !== "FOR" && (
                <div className="px-6 py-4 grid grid-cols-3 gap-4 hover:bg-gray-50 transition-colors duration-150">
                  <dt className="text-sm font-semibold text-gray-500">Carburant</dt>
                  <dd className="text-sm text-gray-900 col-span-2">{vhl.carburant}</dd>
                </div>
              )}
            </dl>
          </div>
        </div>

        {/* Kilométrage et révision */}
        <div className="bg-white rounded-xl shadow-lg p-4 transform hover:scale-[1.01] transition-all duration-200">
          <div className="flex flex-col items-center">
            <button
              type="button"
              onClick={handleClickOpenKM}
              className="group inline-flex items-center px-8 py-3 border-2 border-blue-900 text-lg font-medium rounded-xl text-blue-900 bg-white hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 transition-all duration-200"
            >
              <span className="group-hover:scale-105 transition-transform duration-200">
                Kilométrage : {vhl.km}
              </span>
            </button>

            <div className="w-full mt-8">
              <h4 className="text-lg font-medium text-gray-900 text-center mb-4">
                Prochaine révision : {ProchaineKmRevision} km
              </h4>
              <div className="w-full bg-gray-200 rounded-full h-3 overflow-hidden shadow-inner">
                <div
                  className={`h-3 rounded-full transition-all duration-500 ${
                    progress <= 50 ? "bg-green-500" : progress <= 80 ? "bg-orange-500" : "bg-red-500"
                  }`}
                  style={{ width: `${Math.min(progress, 100)}%` }}
                >
                </div>
              </div>
              <p className="text-sm text-gray-500 text-center mt-2">{Math.floor(progress)}% effectués</p>
            </div>
          </div>
        </div>

        {/* Remarques */}
        <div className="bg-white rounded-xl shadow-lg p-4 transform hover:scale-[1.01] transition-all duration-200">
          <button
            type="button"
            onClick={handleClickOpenRemarques}
            className="w-full inline-flex items-center justify-center px-6 py-3 border border-gray-300 shadow-sm text-base font-medium rounded-xl text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 transition-all duration-200"
          >
            <PencilSquareIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
            Modifier les remarques
          </button>

          <div className="mt-6">
            <textarea
              rows={4}
              className="block w-full rounded-xl border-gray-300 shadow-sm focus:border-blue-900 focus:ring-blue-900 sm:text-sm"
              value={newRemarques}
              disabled
            />
          </div>
        </div>

        <Transition.Root show={openKM} as={Fragment}>
          <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleCloseKM}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform translate-y-0 overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Kilométrage actuel
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            {vhl.km}
                          </p>
                        </div>
                        <div className="mt-2">
                          <textarea
                            rows={1}
                            name="km"
                            id="km"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={newKm}
                            onChange={(e) => setNewKm(e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                        onClick={ValiderKM}
                      >
                        Valider
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={handleCloseKM}
                        ref={cancelButtonRef}
                      >
                        Annuler
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={openRemarques} as={Fragment}>
          <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleCloseRemarques}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform translate-y-0 overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Remarques / Détails
                        </Dialog.Title>
                        <div className="mt-2">
                          <textarea
                            rows={5}
                            name="remarques"
                            id="remarques"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-800 sm:text-sm sm:leading-6"
                            value={newRemarques}
                            onChange={(e) => setNewRemarques(e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                            onMouseDown={(e) => e.stopPropagation()}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                        onClick={ValiderRemarques}
                      >
                        Valider
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={handleCloseRemarques}
                        ref={cancelButtonRefRemarques}
                      >
                        Annuler
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {vhl.service === "FI" && (
          <>
            <div className="bg-white px-8 py-2 flex flex-row items-center">
              <dt className="text-sm font-medium leading-6 text-gray-900">Pneus neige</dt>
              <dd className="ml-auto text-sm leading-6 text-gray-700">
                {vhl.pneusneige ? "Oui" : "Non"}
              </dd>
            </div>

            <Switch.Group as="div" className="mt-2 ml-8 mr-5 flex items-center justify-between">
              <span className="flex flex-grow flex-col">
                <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
                  Pneus neige
                </Switch.Label>
              </span>
              <Switch
                checked={enabled}
                onChange={handleSwitchChangePneus}
                className={classNames(
                  enabled ? 'bg-indigo-600' : 'bg-gray-200',
                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabled ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                  )}
                />
              </Switch>
            </Switch.Group>
          </>
        )}

        {(vhl.service === "FOR" || vhl.service === "SMS") && (
          <>
            {vhl.dossardcompl && (
              <div className="flex items-center mt-5 ">
                <div className="flex-auto">
                  <p className="text-base text-center font-semibold leading-6 text-gray-900">Dossard complémentaire</p>
                  <p className="mt-1 mb-3 text-center truncate text-sm leading-5 text-gray-500">{vhl.dossardcompl}</p>
                </div>
              </div>
            )}

            {vhl.dossardbana && (
              <div className="flex items-center mt-5">
                <div className="flex-auto">
                  <p className="text-base text-center font-semibold leading-6 text-gray-900">Dossard Bana Totale</p>
                  <p className="mt-1 mb-3 text-center truncate text-sm leading-5 text-gray-500">{vhl.dossardbana}</p>
                </div>
              </div>
            )}
            {vhl.dossardbana && (
              <div className="flex items-center">
                <div className="flex-auto">
                  <p className="text-base text-center font-semibold leading-6 text-gray-900">Identité Bana Totale</p>
                  <p className="mt-1 mb-5 text-center truncate text-sm leading-5 text-gray-500 whitespace-pre-wrap max-w-[200px] mx-auto">{vhl.identitebana}</p>
                </div>
              </div>
            )}

            <h2 className="text-lg font-semibold text-center mb-3">Détails du véhicule</h2>

            <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Type de VHL
              </dt>
              <dd className="ml-auto text-sm leading-6 text-gray-700">
                {vhl.typedevhl}
              </dd>
            </div>
            <div className="bg-white px-8 py-2 flex flex-row items-center">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Plaque Magnétique
              </dt>
              <dd className="ml-auto text-sm leading-6 text-gray-700">
                {vhl.plaquevelcro ? "Oui" : "Non"}
              </dd>
            </div>
            <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
              <dt className="text-sm font-medium leading-6 text-gray-900">MAJ Radio</dt>
              <dd className="ml-auto text-sm leading-6 text-gray-700">
                {vhl.majradio}
              </dd>
            </div>
            <div className="bg-white px-8 py-2 flex flex-row items-center">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Chargeur intelligent
              </dt>
              <dd className="ml-auto text-sm leading-6 text-gray-700">
                {vhl.chargeurintelligent ? "Oui" : "Non"}
              </dd>
            </div>
            <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
              <dt className="text-sm font-medium leading-6 text-gray-900">Balisé</dt>
              <dd className="ml-auto text-sm leading-6 text-gray-700">
                {vhl.balise ? "Oui" : "Non"}
              </dd>
            </div>
            <div className="bg-white px-8 py-2 flex flex-row items-center">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Crossclimate Avant
              </dt>
              <dd className="ml-auto text-sm leading-6 text-gray-700">
                {vhl.pneusavant}
              </dd>
            </div>
            <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Crossclimate Arrière
              </dt>
              <dd className="ml-auto text-sm leading-6 text-gray-700">
                {vhl.pneusarriere}
              </dd>
            </div>
            <div className="bg-white px-8 py-2 flex flex-row items-center">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Taille pneus
              </dt>
              <dd className="ml-auto text-sm leading-6 text-gray-700">
                {vhl.taillepneus}
              </dd>
            </div>
            <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
              <dt className="text-sm font-medium leading-6 text-gray-900">AD-Blue</dt>
              <dd className="ml-auto text-sm leading-6 text-gray-700">
                {vhl.adblue ? "Oui" : "Non"}
              </dd>
            </div>
            <div className="bg-white px-8 py-2 flex flex-row items-center">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Taille du VHL
              </dt>
              <dd className="ml-auto text-sm leading-6 text-gray-700">
                {vhl.taillevhl}
              </dd>
            </div>
            <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
              <dt className="text-sm font-medium leading-6 text-gray-900">Poids VHL</dt>
              <dd className="ml-auto text-sm leading-6 text-gray-700">
                {vhl.poidsvhl}
              </dd>
            </div>
            <div className="bg-white px-8 py-2 flex flex-row items-center">
              <dt className="text-sm font-medium leading-6 text-gray-900">Pneus neige</dt>
              <dd className="ml-auto text-sm leading-6 text-gray-700">
                {vhl.pneusneige ? "Oui" : "Non"}
              </dd>
            </div>

            <Switch.Group as="div" className="mt-2 ml-8 mr-5 flex items-center justify-between">
              <span className="flex flex-grow flex-col">
                <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
                  Pneus neige
                </Switch.Label>
              </span>
              <Switch
                checked={enabled}
                onChange={handleSwitchChangePneus}
                className={classNames(
                  enabled ? 'bg-indigo-600' : 'bg-gray-200',
                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabled ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                  )}
                />
              </Switch>
            </Switch.Group>

            <div className="bg-gray-50 mt-2 px-8 py-2 flex flex-row items-center">
              <dt className="text-sm font-medium leading-6 text-gray-900">Attelage</dt>
              <dd className="ml-auto text-sm leading-6 text-gray-700">
                {vhl.attelage ? "Oui" : "Non"}
              </dd>
            </div>

            <Switch.Group as="div" className="mt-2 ml-8 mr-5 flex items-center justify-between">
              <span className="flex flex-grow flex-col">
                <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
                  Attelage
                </Switch.Label>
              </span>
              <Switch
                checked={enabledA}
                onChange={handleSwitchChangeAttelage}
                className={classNames(
                  enabledA ? 'bg-indigo-600' : 'bg-gray-200',
                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabledA ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                  )}
                />
              </Switch>
            </Switch.Group>
          </>
        )}

        <h2 className="mt-6 text-lg font-semibold text-center mb-3">Cartes essences</h2>

        <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            Carte Total
          </dt>
          <dd className="ml-auto text-sm leading-6 text-gray-700">
            {vhl.carteEssSchell}
          </dd>
        </div>
        <div className="bg-white px-8 py-2 flex flex-row items-center">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            Date Exp. Total
          </dt>
          <dd className="ml-auto text-sm leading-6 text-gray-700">
            {new Date(vhl.dateCarteEssSchell).toLocaleDateString()}
          </dd>
        </div>
        <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
          <dt className="text-sm font-medium leading-6 text-gray-900">Carte Shell</dt>
          <dd className="ml-auto text-sm leading-6 text-gray-700">
            {vhl.carteEssTotal}
          </dd>
        </div>
        <div className="bg-white px-8 py-2 flex flex-row items-center">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            Date Exp. Shell
          </dt>
          <dd className="ml-auto text-sm leading-6 text-gray-700">
            {new Date(vhl.dateCarteEssTotal).toLocaleDateString()}
          </dd>
        </div>

        <div className="flex flex-col space-y-4 mb-6">
          <button
            type="button"
            onClick={() => (window.location.href = `/historiqueperception/${vhl._id}`)}
            className="inline-flex items-center justify-center px-4 py-2 border-2 border-blue-900 text-base font-medium rounded-lg text-blue-900 bg-white hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 transition-all duration-200"
          >
            <CalendarDaysIcon className="mr-2 h-5 w-5" aria-hidden="true" />
            Historique des perceptions
          </button>

          <button
            type="button"
            onClick={() => (window.location.href = `/historiquerevision/${vhl._id}`)}
            className="inline-flex items-center justify-center px-4 py-2 border-2 border-blue-900 text-base font-medium rounded-lg text-blue-900 bg-white hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 transition-all duration-200"
          >
            <CalendarDaysIcon className="mr-2 h-5 w-5" aria-hidden="true" />
            Historique des révisions
          </button>
        </div>
      </div>
    </div>
  );
}

export default PageVHL;
