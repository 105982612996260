import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import authHeader from "../auth-header";
import { TrashIcon, CalendarIcon, WrenchIcon, CheckCircleIcon } from '@heroicons/react/20/solid';

export default function RDVVhlService() {
  const [vhl, setVhl] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/rdvgarage/", {
        headers: authHeader(),
      })
      .then((response) => {
        setVhl(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", {
        headers: authHeader(),
      })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const sortedVehicles = vhl
    .filter(
      (vhl) =>
        currentUser &&
        vhl.service === currentUser.service &&
        vhl.daterdv !== null &&
        vhl.daterdv !== undefined &&
        vhl.daterdv !== ""
    )
    .sort((a, b) => new Date(a.daterdv) - new Date(b.daterdv));

  const groupedVehicles = {};

  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  sortedVehicles.forEach((vhl) => {
    const date = new Date(vhl.daterdv);
    const day = date.getDate();
    const monthNumber = date.getMonth();
    const year = date.getFullYear();

    const monthName = months[monthNumber];
    const formattedDate = `${day} ${monthName} ${year}`;

    if (!groupedVehicles[formattedDate]) {
      groupedVehicles[formattedDate] = [];
    }
    groupedVehicles[formattedDate].push(vhl);
  });

  function handleListItemClick(Id) {
    const vehicleToUpdate = vhl.find((vehicle) => vehicle._id === Id);

    if (!vehicleToUpdate) {
      console.error(`Vehicle with ID ${Id} not found.`);
      return;
    }

    if (vehicleToUpdate.idusers.includes(currentUser.username)) {
      navigate(`/rdvvhlservice`);
    } else {
      const updatedVehicle = {
        ...vehicleToUpdate,
        idusers: [...vehicleToUpdate.idusers, currentUser.username],
      };

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/rdvgarage/update/${Id}`,
          updatedVehicle,
          { headers: authHeader() }
        )
        .then((res) => {
          const updatedRdvGarage = vhl.map((vehicle) =>
            vehicle._id === Id ? updatedVehicle : vehicle
          );

          setVhl(updatedRdvGarage);
          navigate(`/rdvvhlservice`);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  const deleteRDV = (id) => {
    axios
      .delete(process.env.REACT_APP_BASE_URL + "/api/rdvgarage/" + id, { headers: authHeader() })
      .then(() => window.location = '/rdvvhlservice')
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-sm overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            {/* Header */}
            <div className="sm:flex sm:items-center border-b border-gray-200 pb-5">
              <div className="sm:flex-auto">
                <div className="flex items-center gap-3">
                  <CalendarIcon className="h-8 w-8 text-blue-900" />
                  <div>
                    <h1 className="text-2xl font-bold text-gray-900">
                      RDV Garage à venir
                    </h1>
                  </div>
                </div>
              </div>
            </div>

            {/* Table */}
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        <div className="flex items-center gap-1">
                          <CalendarIcon className="h-5 w-5 text-blue-900" />
                          <span>Date</span>
                        </div>
                      </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Véhicule
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        <div className="flex items-center gap-1">
                          <WrenchIcon className="h-5 w-5 text-blue-900" />
                          <span>Type de RDV</span>
                        </div>
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Remarques
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                          <span className="sr-only">Actions</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {Object.keys(groupedVehicles).map((date) => (
                        <React.Fragment key={date}>
                          {groupedVehicles[date].map((vhl) => (
                            <tr
                              key={vhl._id}
                              onClick={() => handleListItemClick(vhl._id)}
                              className={classNames(
                                'hover:bg-gray-50 cursor-pointer transition-colors duration-150',
                                !vhl.idusers.includes(currentUser.username) ? 'bg-green-50' : ''
                              )}
                            >
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-0">
                                <div className="flex items-center gap-2">
                                  <span className="font-medium text-blue-900">{date}</span>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm">
                                <div className="flex items-center">
                                  <div className="h-11 w-11 flex-shrink-0">
                                    <img
                                      className="h-10 w-10 rounded-lg object-cover ring-1 ring-gray-200"
                                      src={vhl.image}
                                      alt={vhl.nomvhl}
                                    />
                                  </div>
                                  <div className="ml-4">
                                    <div className="font-medium text-gray-900">{vhl.nomvhl}</div>
                                    <div className="text-gray-500">{vhl.dossardorigine}</div>
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm">
                                <div className="flex items-center gap-2">
                                  <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-sm font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                                    {vhl.typerdv}
                                  </span>
                                </div>
                              </td>
                              <td className="px-3 py-4 text-sm text-gray-500">
                                <div className="max-w-xl">
                                  {vhl.remarque}
                                </div>
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                <div className="flex justify-end items-center gap-3">
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (window.confirm('Êtes-vous sûr de vouloir supprimer ce RDV ?')) {
                                        deleteRDV(vhl._id);
                                      }
                                    }}
                                    className="text-red-600 hover:text-red-900 p-1 rounded-md hover:bg-red-50 transition-colors duration-200"
                                  >
                                    <TrashIcon className="h-5 w-5" />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
