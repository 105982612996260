import React, { useEffect, useState } from "react";
import axios from "axios";
import authHeader from "../auth-header";
import { format, isWithinInterval, startOfDay, endOfDay, subMonths } from "date-fns";
import { fr } from "date-fns/locale";

export default function ListHistoPerceptions() {
  const [perceptions, setPerceptions] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [dateFilter, setDateFilter] = useState("all"); // "all", "custom", "1month", "3months", "6months"
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Récupérer l'historique des perceptions
        const perceptionsResponse = await axios.get(
          process.env.REACT_APP_BASE_URL + "/api/histoperceptionmateriel/",
          { headers: authHeader() }
        );
        setPerceptions(perceptionsResponse.data);

        // Récupérer l'utilisateur actuel
        const currentUserResponse = await axios.get(
          process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser",
          { headers: authHeader() }
        );
        setCurrentUser(currentUserResponse.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Filtrer les perceptions par date
  const getFilteredPerceptions = () => {
    let filtered = [...perceptions];

    if (dateFilter === "custom" && startDate && endDate) {
      const start = startOfDay(new Date(startDate));
      const end = endOfDay(new Date(endDate));
      filtered = filtered.filter(perception => {
        const date = new Date(perception.date);
        return isWithinInterval(date, { start, end });
      });
    } else if (dateFilter !== "all") {
      const today = new Date();
      const monthsToSubtract = {
        "1month": 1,
        "3months": 3,
      }[dateFilter];
      
      const startDateFilter = subMonths(today, monthsToSubtract);
      filtered = filtered.filter(perception => {
        const date = new Date(perception.date);
        return date >= startDateFilter;
      });
    }

    return filtered.sort((a, b) => new Date(b.date) - new Date(a.date));
  };

  const sortedPerceptions = getFilteredPerceptions();

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-4 sm:py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-6">
          <div className="flex items-center justify-between">
            <button
              onClick={() => window.history.back()}
              className="inline-flex items-center px-3 py-1.5 sm:px-4 sm:py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <svg
                className="h-5 w-5 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <h1 className="text-xl sm:text-2xl font-bold text-gray-900">
              Historique des perceptions
            </h1>
            <div className="w-10"></div>
          </div>
        </div>

        {/* Filtres de date */}
        <div className="bg-white shadow-sm rounded-lg p-4 mb-6">
          <div className="space-y-4">
            <div className="flex flex-wrap gap-2">
              <button
                onClick={() => {
                  setDateFilter("all");
                  setStartDate("");
                  setEndDate("");
                }}
                className={`px-4 py-2 rounded-md text-sm font-medium ${
                  dateFilter === "all"
                    ? "bg-indigo-600 text-white"
                    : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                }`}
              >
                Tout afficher
              </button>
              <button
                onClick={() => setDateFilter("1month")}
                className={`px-4 py-2 rounded-md text-sm font-medium ${
                  dateFilter === "1month"
                    ? "bg-indigo-600 text-white"
                    : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                }`}
              >
                Dernier mois
              </button>
              <button
                onClick={() => setDateFilter("3months")}
                className={`px-4 py-2 rounded-md text-sm font-medium ${
                  dateFilter === "3months"
                    ? "bg-indigo-600 text-white"
                    : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                }`}
              >
                3 derniers mois
              </button>
              <button
                onClick={() => setDateFilter("custom")}
                className={`px-4 py-2 rounded-md text-sm font-medium ${
                  dateFilter === "custom"
                    ? "bg-indigo-600 text-white"
                    : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                }`}
              >
                Période personnalisée
              </button>
            </div>

            {/* Sélecteurs de dates personnalisées */}
            {dateFilter === "custom" && (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                  <label htmlFor="start-date" className="block text-sm font-medium text-gray-700 mb-1">
                    Date de début
                  </label>
                  <input
                    type="date"
                    id="start-date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label htmlFor="end-date" className="block text-sm font-medium text-gray-700 mb-1">
                    Date de fin
                  </label>
                  <input
                    type="date"
                    id="end-date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Liste des perceptions */}
        <div className="space-y-4">
          {sortedPerceptions.length === 0 ? (
            <div className="bg-white shadow-sm rounded-lg p-6 text-center">
              <p className="text-gray-500">Aucune perception enregistrée pour cette période.</p>
            </div>
          ) : (
            sortedPerceptions.map((perception, index) => (
              <div
                key={perception._id}
                className="bg-white shadow-sm rounded-lg overflow-hidden hover:shadow-md transition-shadow duration-200"
              >
                <div className="p-4 sm:p-6">
                  {/* En-tête de la carte */}
                  <div className="flex items-start justify-between mb-4">
                    <div className="flex-1">
                      <h3 className="text-lg font-semibold text-gray-900 mb-1">
                        {perception.nomperception}
                      </h3>
                      <p className="text-sm text-gray-500">
                        {format(new Date(perception.date), "dd MMMM yyyy à HH:mm", {
                          locale: fr,
                        })}
                      </p>
                    </div>
                  </div>

                  {/* Lien vers les détails */}
                  <div className="mt-4 flex justify-end">
                    <a
                      href={`/detailhistoperception/${perception._id}`}
                      className="inline-flex items-center text-sm font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Voir les détails
                      <svg
                        className="ml-1 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}
