import React, { useState, useEffect } from "react";
import axios from "axios";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { fr } from 'date-fns/locale';
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Avatar,
  Container,
  IconButton,
  Tooltip
} from '@mui/material';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import BuildIcon from '@mui/icons-material/Build';
import SpeedIcon from '@mui/icons-material/Speed';
import EventIcon from '@mui/icons-material/Event';
import authHeader from "../auth-header";

const CreateRevisionVHL = () => {
  const [vehicles, setVehicles] = useState([]);
  const [selectedVHL, setSelectedVHL] = useState(null);
  const [date, setDate] = useState(new Date());
  const [kmeffectif, setKmEffectif] = useState("");
  const [selectedRevision, setSelectedRevision] = useState(10000);
  const [remarque, setRemarque] = useState("");
  const [currentUser, setCurrentUser] = useState({});

  const revisions = Array.from({ length: 35 }, (_, i) => {
    const km = (i + 1) * 10000;
    return { title: `Révision des ${km.toLocaleString()}km`, km };
  });

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/vhl/", { headers: authHeader() })
      .then((response) => {
        setVehicles(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!selectedVHL) {
      alert("Veuillez sélectionner un véhicule");
      return;
    }

    const historevision = {
      idvhl: selectedVHL._id,
      nomvhl: selectedVHL.nomvhl,
      service: selectedVHL.service,
      date: date,
      remarque: remarque,
      kmeffectif: Number(kmeffectif),
      km: selectedRevision,
    };

    axios
      .post(process.env.REACT_APP_BASE_URL + "/api/historiquerevision/add/", historevision, { headers: authHeader() })
      .then((res) => {
        window.location = `/rdvvhl`;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      {/* Header Card */}
      <Card 
        elevation={3}
        sx={{ 
          position: 'relative',
          overflow: 'hidden',
          mb: 4
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '100%',
            background: 'linear-gradient(135deg, rgba(26, 35, 126, 0.8) 0%, rgba(13, 71, 161, 0.8) 100%)',
            zIndex: 1
          }}
        />
        <CardContent sx={{ position: 'relative', zIndex: 2, color: 'white', textAlign: 'center', py: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2, mb: 2 }}>
            <DirectionsCarIcon sx={{ fontSize: 40 }} />
            <BuildIcon sx={{ fontSize: 40 }} />
          </Box>
          <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
            Nouvelle Révision
          </Typography>
          <Typography variant="subtitle1" sx={{ opacity: 0.9 }}>
            Ajouter une révision effectuée pour un véhicule
          </Typography>
        </CardContent>
      </Card>

      {/* Main Form Card */}
      <Card elevation={2}>
        <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
          <Box component="form" onSubmit={onSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            {/* Vehicle Selection */}
            <Autocomplete
              value={selectedVHL}
              onChange={(event, newValue) => {
                setSelectedVHL(newValue);
              }}
              options={vehicles}
              getOptionLabel={(option) => `${option.nomvhl} - ${option.dossardorigine} - ${option.service}`}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  <Avatar
                    src={option.image}
                    alt={option.nomvhl}
                    sx={{ width: 32, height: 32, mr: 2 }}
                  >
                    <DirectionsCarIcon />
                  </Avatar>
                  <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography variant="body1" fontWeight="medium">
                      {option.nomvhl}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                      {option.dossardorigine}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {option.service}
                    </Typography>
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sélectionner un véhicule"
                  required
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <DirectionsCarIcon color="action" sx={{ mr: 1 }} />
                    )
                  }}
                />
              )}
            />

            {/* Date and Mileage Row */}
            <Box sx={{ 
              display: 'flex', 
              gap: 2, 
              flexDirection: { xs: 'column', sm: 'row' },
              '& > *': { flex: 1 }
            }}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                <DatePicker
                  label="Date de la révision"
                  value={date}
                  onChange={(newValue) => setDate(newValue)}
                  slotProps={{
                    textField: {
                      required: true,
                      fullWidth: true,
                      InputProps: {
                        startAdornment: (
                          <EventIcon color="action" sx={{ mr: 1 }} />
                        )
                      }
                    }
                  }}
                />
              </LocalizationProvider>

              <TextField
                label="Kilométrage effectif"
                value={kmeffectif}
                onChange={(e) => setKmEffectif(e.target.value)}
                type="number"
                required
                InputProps={{
                  startAdornment: (
                    <SpeedIcon color="action" sx={{ mr: 1 }} />
                  )
                }}
              />
            </Box>

            {/* Revision Type */}
            <FormControl required>
              <InputLabel>Type de révision</InputLabel>
              <Select
                value={selectedRevision}
                onChange={(e) => setSelectedRevision(e.target.value)}
                label="Type de révision"
                startAdornment={<BuildIcon color="action" sx={{ ml: 1, mr: 1 }} />}
              >
                {revisions.map((revision) => (
                  <MenuItem key={revision.km} value={revision.km}>
                    {revision.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Remarks */}
            <TextField
              label="Remarques"
              value={remarque}
              onChange={(e) => setRemarque(e.target.value)}
              multiline
              rows={4}
              placeholder="Entrez vos remarques ici..."
            />

            {/* Submit Button */}
            <Button
              type="submit"
              variant="contained"
              size="large"
              startIcon={<BuildIcon />}
              sx={{
                mt: 2,
                py: 1.5,
                backgroundColor: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.dark'
                }
              }}
            >
              Créer la révision
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default CreateRevisionVHL;
