import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import authHeader from "../auth-header";

export default function EditMateriel() {
  const [materiel, setMateriel] = useState({
    nom: "",
    categorie: "",
    alerte: "",
    image: "",
    emplacement: "",
    remarques: "",
    imageperception: "",
  });
  const [message, setMessage] = useState("");
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/materiels/" + id, {
        headers: authHeader(),
      })
      .then((response) => {
        setMateriel(response.data);
      })
      .catch((error) => {
        console.log(error);
        setMessage("Erreur lors du chargement du matériel");
      });
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        process.env.REACT_APP_BASE_URL + "/api/materiels/update/" + id,
        materiel,
        { headers: authHeader() }
      )
      .then((response) => {
        setMessage("Matériel modifié avec succès!");
        // Redirect after 2 seconds
        setTimeout(() => {
          window.location.href = "/materiel/toutmateriel";
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        setMessage("Erreur lors de la modification du matériel");
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMateriel((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const categories = [
    "CNP",
    "COF / Perben",
    "Divers",
    "Rens aérien / Drones",
    "Image",
    "Interpel / Armement",
    "Micro",
    "Mobilité",
    "SIC",
    "Track",
    "Verte",
  ];

  const alertes = ["Alerte 1", "Alerte 2", "Spare"];

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-6">
          <button
            onClick={() => window.history.back()}
            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <svg
              className="-ml-1 mr-2 h-5 w-5 text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
            Retour
          </button>
        </div>

        {/* Main Card */}
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <h1 className="text-2xl font-bold text-gray-900 mb-1">
              Modifier le matériel
            </h1>
            <p className="text-sm text-gray-500 mb-6">
              Modifiez les informations du matériel ci-dessous.
            </p>

            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Nom */}
              <div>
                <label
                  htmlFor="nom"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nom
                </label>
                <input
                  type="text"
                  id="nom"
                  name="nom"
                  value={materiel.nom}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              {/* Catégorie */}
              <div>
                <label
                  htmlFor="categorie"
                  className="block text-sm font-medium text-gray-700"
                >
                  Catégorie
                </label>
                <select
                  id="categorie"
                  name="categorie"
                  value={materiel.categorie}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  <option value="">Sélectionnez une catégorie</option>
                  {categories.map((cat) => (
                    <option key={cat} value={cat}>
                      {cat}
                    </option>
                  ))}
                </select>
              </div>

              {/* Alerte */}
              <div>
                <label
                  htmlFor="alerte"
                  className="block text-sm font-medium text-gray-700"
                >
                  Catégorie d'alerte
                </label>
                <select
                  id="alerte"
                  name="alerte"
                  value={materiel.alerte}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  <option value="">Sélectionnez une catégorie d'alerte</option>
                  {alertes.map((alert) => (
                    <option key={alert} value={alert}>
                      {alert}
                    </option>
                  ))}
                </select>
              </div>

              {/* Emplacement */}
              <div>
                <label
                  htmlFor="emplacement"
                  className="block text-sm font-medium text-gray-700"
                >
                  Emplacement
                </label>
                <input
                  type="text"
                  id="emplacement"
                  name="emplacement"
                  value={materiel.emplacement}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              {/* Remarques */}
              <div>
                <label
                  htmlFor="remarques"
                  className="block text-sm font-medium text-gray-700"
                >
                  Remarques
                </label>
                <textarea
                  id="remarques"
                  name="remarques"
                  value={materiel.remarques}
                  onChange={handleChange}
                  rows={4}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              {/* Image URL */}
              <div>
                <label
                  htmlFor="image"
                  className="block text-sm font-medium text-gray-700"
                >
                  URL de l'image
                </label>
                <input
                  type="url"
                  id="image"
                  name="image"
                  value={materiel.image}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              {/* Image Perception */}
              <div>
                <label
                  htmlFor="imageperception"
                  className="block text-sm font-medium text-gray-700"
                >
                  URL de l'image de perception
                </label>
                <input
                  type="url"
                  id="imageperception"
                  name="imageperception"
                  value={materiel.imageperception}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              {/* Message de confirmation/erreur */}
              {message && (
                <div
                  className={`rounded-md p-4 ${
                    message.includes("succès")
                      ? "bg-green-50 text-green-800"
                      : "bg-red-50 text-red-800"
                  }`}
                >
                  <p className="text-sm font-medium">{message}</p>
                </div>
              )}

              {/* Buttons */}
              <div className="flex justify-center space-x-3 pt-4">
                <button
                  type="button"
                  onClick={() => window.history.back()}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Enregistrer les modifications
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
