import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
  ButtonGroup,
  Button
} from "@mui/material";

// Icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import KeyIcon from '@mui/icons-material/Key';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import MicIcon from '@mui/icons-material/Mic';
import ForestIcon from '@mui/icons-material/Forest';
import MasksIcon from '@mui/icons-material/Masks';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ConstructionIcon from '@mui/icons-material/Construction';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventIcon from '@mui/icons-material/Event';
import CategoryIcon from '@mui/icons-material/Category';
import authHeader from "../../auth-header";

const categories = [
  { id: 'image', label: 'Image', icon: CameraAltIcon, color: '#4CAF50' },
  { id: 'cof', label: 'COF / Perben', icon: KeyIcon, color: '#2196F3' },
  { id: 'drone', label: 'Rens aérien / Drone', icon: AirplanemodeActiveIcon, color: '#9C27B0' },
  { id: 'micro', label: 'Micro', icon: MicIcon, color: '#F44336' },
  { id: 'verte', label: 'Verte', icon: ForestIcon, color: '#4CAF50' },
  { id: 'cnp', label: 'CNP', icon: MasksIcon, color: '#FF9800' },
  { id: 'interpel', label: 'Interpel / Armement', icon: LocalFireDepartmentIcon, color: '#795548' },
  { id: 'mobilite', label: 'Mobilité', icon: DirectionsCarIcon, color: '#607D8B' },
  { id: 'divers', label: 'Divers', icon: ConstructionIcon, color: '#9E9E9E' },
  { id: 'sic', label: 'SIC', icon: SettingsInputAntennaIcon, color: '#3F51B5' },
  { id: 'track', label: 'Track', icon: LocationOnIcon, color: '#E91E63' }
];

function DetailPerception() {
  const [perception, setPerception] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const actions = [
    { icon: <DeleteIcon />, name: 'Supprimer', action: handleDelete },
    { icon: <EditIcon />, name: 'Editer', action: handleEdit },
    { icon: <ReplyAllIcon />, name: 'Réintégrer', action: handleReintegrate }
  ];

  useEffect(() => {
    const fetchPerception = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_BASE_URL + "/api/perceptionmateriel/" + id,
          { headers: authHeader() }
        );
        setPerception(response.data);
      } catch (error) {
        console.error("Erreur lors du chargement de la perception:", error);
      }
    };
    fetchPerception();
  }, [id]);

  function handleDelete() {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette perception ?")) {
      axios
        .delete(process.env.REACT_APP_BASE_URL + "/api/perceptionmateriel/" + id, { headers: authHeader() })
        .then(() => navigate("/listperceptions"))
        .catch(error => console.error("Erreur lors de la suppression:", error));
    }
  }

  function handleEdit() {
    navigate(`/update/${id}`);
  }

  async function handleReintegrate() {
    try {
      await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/histoperceptionmateriel/add/",
        perception,
        { headers: authHeader() }
      );
      navigate(`/reintegration/${id}`);
    } catch (error) {
      console.error("Erreur lors de la réintégration:", error);
    }
  }

  function handleCategoryClick(category) {
    setSelectedCategory(category);
    setOpenDialog(true);
  }

  if (!perception) return null;

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {/* Header Card */}
      <Card 
        sx={{ 
          mb: 4, 
          position: 'relative',
          background: 'linear-gradient(135deg, rgba(26, 35, 126, 0.8) 0%, rgba(13, 71, 161, 0.8) 100%)',
          color: 'white',
          borderRadius: '16px',
          overflow: 'visible'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: -20,
            left: 20,
            backgroundColor: perception.typeperception === 'Mission' ? '#f44336' : 
                           perception.typeperception === 'Exercice' ? '#2196f3' : '#4caf50',
            color: 'white',
            padding: '8px 24px',
            borderRadius: '20px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            zIndex: 1
          }}
        >
          <CategoryIcon />
          {perception.typeperception}
        </Box>

        <CardContent sx={{ pt: 4 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
            <Box>
              <Typography variant="h4" component="h1" sx={{ 
                fontWeight: 'semibold',
                mb: 1,
                color: 'white'
              }}>
                {perception.nomperception}
              </Typography>
              {perception.remarques && (
                <Typography 
                  variant="body1" 
                  sx={{ 
                    color: 'rgba(255,255,255,0.8)',
                    maxWidth: '600px'
                  }}
                >
                  {perception.remarques}
                </Typography>
              )}
            </Box>
          </Box>

          <Box 
            sx={{ 
              mt: 3,
              pt: 3,
              borderTop: '1px solid rgba(255,255,255,0.1)',
              display: 'flex',
              gap: 2,
              flexWrap: 'wrap'
            }}
          >
            {Object.entries(perception).reduce((count, [key, value]) => {
              if (Array.isArray(value) && value.length > 0) {
                count.total += value.length;
                count.categories += 1;
              }
              return count;
            }, { total: 0, categories: 0 }).total > 0 && (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.8)' }}>
                    Total matériels :
                  </Typography>
                  <Chip
                    label={Object.entries(perception).reduce((count, [key, value]) => {
                      if (Array.isArray(value)) {
                        count += value.length;
                      }
                      return count;
                    }, 0)}
                    sx={{ 
                      backgroundColor: 'rgba(255,255,255,0.2)',
                      color: 'white',
                      fontWeight: 'bold'
                    }}
                    size="small"
                  />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.8)' }}>
                    Catégories utilisées :
                  </Typography>
                  <Chip
                    label={Object.entries(perception).reduce((count, [key, value]) => {
                      if (Array.isArray(value) && value.length > 0) {
                        count += 1;
                      }
                      return count;
                    }, 0)}
                    sx={{ 
                      backgroundColor: 'rgba(255,255,255,0.2)',
                      color: 'white',
                      fontWeight: 'bold'
                    }}
                    size="small"
                  />
                </Box>
              </>
            )}
          </Box>
        </CardContent>
      </Card>

      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center',
        mb: 4, 
        mt: 2,
        gap: 2,
        flexWrap: 'wrap'
      }}>
        <Button
          variant="contained"
          startIcon={<EditIcon />}
          onClick={handleEdit}
          sx={{
            minWidth: '160px',
            backgroundColor: 'primary.main',
            '&:hover': { 
              backgroundColor: 'primary.dark',
              transform: 'translateY(-2px)',
              transition: 'transform 0.2s'
            },
            borderRadius: '8px',
            textTransform: 'none',
            py: 1,
            boxShadow: 2,
          }}
        >
          Éditer
        </Button>
        <Button
          variant="contained"
          startIcon={<ReplyAllIcon />}
          onClick={handleReintegrate}
          sx={{
            minWidth: '160px',
            backgroundColor: 'success.main',
            '&:hover': { 
              backgroundColor: 'success.dark',
              transform: 'translateY(-2px)',
              transition: 'transform 0.2s'
            },
            borderRadius: '8px',
            textTransform: 'none',
            py: 1,
            boxShadow: 2,
          }}
        >
          Réintégrer
        </Button>
        <Button
          variant="contained"
          startIcon={<DeleteIcon />}
          onClick={handleDelete}
          sx={{
            minWidth: '160px',
            backgroundColor: 'error.main',
            '&:hover': { 
              backgroundColor: 'error.dark',
              transform: 'translateY(-2px)',
              transition: 'transform 0.2s'
            },
            borderRadius: '8px',
            textTransform: 'none',
            py: 1,
            boxShadow: 2,
          }}
        >
          Supprimer
        </Button>
      </Box>

      {/* Categories Grid */}
      <Grid container spacing={2}>
        {categories.map((category) => {
          const categoryItems = perception[category.id] || [];
          const hasItems = categoryItems.length > 0;
          const Icon = category.icon;

          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={category.id}>
              <Paper
                sx={{
                  p: 2,
                  height: '100%',
                  cursor: hasItems ? 'pointer' : 'default',
                  opacity: hasItems ? 1 : 0.6,
                  transition: 'transform 0.2s, opacity 0.2s',
                  '&:hover': hasItems ? {
                    transform: 'scale(1.02)',
                  } : {},
                }}
                onClick={() => hasItems && handleCategoryClick(category)}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Icon sx={{ color: category.color, mr: 1 }} />
                  <Typography variant="subtitle1">
                    {category.label}
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {hasItems ? `${categoryItems.length} élément${categoryItems.length > 1 ? 's' : ''}` : 'Aucun élément'}
                </Typography>
              </Paper>
            </Grid>
          );
        })}
      </Grid>

      {/* Materials Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullScreen={isMobile}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {selectedCategory && (
                <>
                  <selectedCategory.icon sx={{ color: selectedCategory.color, mr: 1 }} />
                  <Typography variant="h6">{selectedCategory.label}</Typography>
                </>
              )}
            </Box>
            <IconButton onClick={() => setOpenDialog(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {selectedCategory && perception[selectedCategory.id] && (
            <List>
              {perception[selectedCategory.id]
                .sort((a, b) => a.materiel.nom.localeCompare(b.materiel.nom))
                .map((item, index) => (
                  <ListItem
                    key={index}
                    button
                    onClick={() => navigate(`/pagemateriel/${item.materiel._id}`)}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={item.materiel.imageperception}
                        alt={item.materiel.nom}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.materiel.nom}
                      secondary={`Quantité : ${item.materiel.nbperception}`}
                    />
                  </ListItem>
                ))}
            </List>
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default DetailPerception;
