import React, { useEffect, useState } from "react";
import axios from "axios";
import authHeader from "../../auth-header";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

export default function ListPerceptionCours() {
  const [perceptions, setPerceptions] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedType, setSelectedType] = useState("all");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [perceptionsResponse, userResponse] = await Promise.all([
          axios.get(process.env.REACT_APP_BASE_URL + "/api/perceptionmateriel/", { headers: authHeader() }),
          axios.get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
        ]);
        
        setPerceptions(perceptionsResponse.data);
        setCurrentUser(userResponse.data);
      } catch (error) {
        console.error("Erreur lors du chargement des données:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Obtenir les types uniques de perceptions
  const uniqueTypes = ["all", ...new Set(perceptions.map(p => p.typeperception))];

  // Filtrer les perceptions
  const filteredPerceptions = perceptions.filter(perception => {
    const matchesSearch = perception.nomperception.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesType = selectedType === "all" || perception.typeperception === selectedType;
    return matchesSearch && matchesType;
  });

  // Grouper les perceptions par type
  const groupedPerceptions = filteredPerceptions.reduce((groups, perception) => {
    const type = perception.typeperception;
    if (!groups[type]) {
      groups[type] = [];
    }
    groups[type].push(perception);
    return groups;
  }, {});

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-4 sm:py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header avec recherche */}
        <div className="mb-6 space-y-4">
          <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
            <h1 className="text-2xl font-bold text-gray-900">
              Perceptions en cours
            </h1>
            <div className="w-full sm:w-96">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Rechercher une perception..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full rounded-lg border-gray-300 pl-10 pr-4 py-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          {/* Filtres par type */}
          <div className="flex flex-wrap gap-2">
            {uniqueTypes.map((type) => (
              <button
                key={type}
                onClick={() => setSelectedType(type)}
                className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                  selectedType === type
                    ? "bg-blue-600 text-white"
                    : "bg-white text-gray-700 hover:bg-blue-50 border border-gray-300"
                }`}
              >
                {type === "all" ? "Tous les types" : type}
              </button>
            ))}
          </div>
        </div>

        {/* Liste des perceptions groupées */}
        <div className="space-y-8">
          {Object.entries(groupedPerceptions).map(([type, perceptions]) => (
            <div key={type} className="space-y-4">
              <div className="flex items-center space-x-4">
                <h2 className="text-lg font-semibold text-gray-900">{type}</h2>
                <div className="flex-grow border-t border-gray-200"></div>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {perceptions.map((perception) => (
                  <div
                    key={perception._id}
                    className="bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-200 overflow-hidden"
                  >
                    <div className="p-6">
                      <div className="flex justify-between items-start mb-4">
                        <h3 className="text-lg font-medium text-gray-900">
                          {perception.nomperception}
                        </h3>
                    
                      <div className="text-sm text-gray-500 mb-4">
                        {format(new Date(perception.date), "dd MMMM yyyy", { locale: fr })}
                      </div>
                      </div>

                      <div className="flex justify-end mt-4">
                        <a
                          href={`/perceptionmateriel/${perception._id}`}
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          Voir les détails
                          <svg className="ml-2 -mr-1 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}

          {filteredPerceptions.length === 0 && (
            <div className="bg-white rounded-lg shadow-sm p-6 text-center">
              <p className="text-gray-500">
                Aucune perception ne correspond à votre recherche
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
