import React, { useEffect, useState } from "react";
import axios from "axios";
import authHeader from "./auth-header";
import { Link } from "react-router-dom";
import { ChevronRightIcon, ExclamationCircleIcon, SignalIcon, LinkIcon } from '@heroicons/react/20/solid';

export default function ListetousVHL() {
  const [vehicles, setVehicles] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [searchValueGarage, setSearchValueGarage] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [RDVvhl, setRDVVhl] = useState([]);


  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/vhl/", { headers: authHeader() })
      .then((response) => {
        setVehicles(response.data);        
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
      axios
            .get(process.env.REACT_APP_BASE_URL + "/api/rdvgarage/", { headers: authHeader() })
            .then((response) => {
              setRDVVhl(response.data);              
            })
            .catch((error) => {
              console.log(error);
            });
  }, []);
  
  const listStyles = {
    width: "100%",
    maxWidth: "100%",
    bgcolor: "background.paper",
    marginTop: "-25px",
  };

  const handleSearchGarage = (e) => {
    setSearchValueGarage(e.target.value);
  };

  const filteredVehiclesGarage = vehicles.filter((vehicle) => {
    if (searchValueGarage === "") {
      return vehicle;
    } else {
      return (
        (vehicle.nomvhl && vehicle.nomvhl.toLowerCase().includes(searchValueGarage.toLowerCase())) ||
        (vehicle.dossardorigine && vehicle.dossardorigine.toLowerCase().includes(searchValueGarage.toLowerCase()))
      );
    }
  });

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const filteredVehicles = vehicles.filter((vehicle) => {
    if (searchValue === "") {
      return vehicle && currentUser && vehicle.service === currentUser.service;
    } else {
      return (
        (vehicle && currentUser && vehicle.service === currentUser.service) &&
        ((vehicle && currentUser && vehicle.nomvhl && vehicle.nomvhl.toLowerCase().includes(searchValue.toLowerCase())) ||
        (vehicle && currentUser && vehicle.dossardorigine && vehicle.dossardorigine.toLowerCase().includes(searchValue.toLowerCase())))
      );
    }
  });
  
  // Trier les véhicules par ordre alphabétique du nomvhl
  filteredVehicles.sort((a, b) => a.nomvhl.localeCompare(b.nomvhl));
  
  const groupedVehicles = {};
  
  filteredVehicles.forEach((vhl) => {
    if (!groupedVehicles[vhl.typedevhl]) {
      groupedVehicles[vhl.typedevhl] = [];
    }
    groupedVehicles[vhl.typedevhl].push(vhl);
  });
  
  const groupedVehiclesGarage = {};
  
  filteredVehiclesGarage.forEach((vhl) => {
    if (!groupedVehiclesGarage[vhl.service]) {
      groupedVehiclesGarage[vhl.service] = [];
    }
    groupedVehiclesGarage[vhl.service].push(vhl);
  });
  

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Barre de recherche fixe en haut */}
      <div className="sticky top-0 z-20 bg-white shadow-sm px-4 py-3">
        {currentUser.service === "GARAGE" ? (
          <div className="relative max-w-md mx-auto">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd" />
              </svg>
            </div>
            <input
              type="text"
              name="rechercher"
              placeholder="Rechercher un véhicule..."
              className="block w-full rounded-lg border-0 py-3 pl-10 pr-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
              value={searchValueGarage}
              onChange={handleSearchGarage}
            />
          </div>
        ) : (
          <div className="relative max-w-md mx-auto">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd" />
              </svg>
            </div>
            <input
              type="text"
              name="rechercher"
              placeholder="Rechercher un véhicule..."
              className="block w-full rounded-lg border-0 py-3 pl-10 pr-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
              value={searchValue}
              onChange={handleSearch}
            />
          </div>
        )}
      </div>

      <div className="px-4 py-4">
        {currentUser.service === "GARAGE" ? (
          <div>
            <h3 className="text-xl font-semibold text-gray-900 mb-6">Tous les véhicules</h3>
            <div className="space-y-6">
              {Object.keys(groupedVehiclesGarage).map((service) => (
                <div key={service} className="bg-white rounded-lg shadow overflow-hidden">
                  <div className="bg-gray-50 px-4 py-3 border-b border-gray-200">
                    <h3 className="text-sm font-medium text-gray-900">{service}</h3>
                  </div>
                  <ul className="divide-y divide-gray-200">
                    {groupedVehiclesGarage[service].map((vhl) => (
                      <li key={vhl._id}>
                        <Link to={`/pagevhl/${vhl._id}`} className="block hover:bg-gray-50 transition duration-150 ease-in-out">
                          <div className="flex items-center px-4 py-4">
                            <div className="flex-shrink-0">
                              <img className="h-12 w-12 rounded-lg object-cover" src={vhl.image} alt="" />
                            </div>
                            <div className="ml-4 flex-1 min-w-0">
                              <div className="flex items-center justify-between">
                                <p className="text-sm font-medium text-gray-900 truncate">{vhl.nomvhl}</p>
                                <p className="text-sm text-gray-500">{vhl.position}</p>
                              </div>
                              <p className="mt-1 text-sm text-gray-500 italic">{vhl.dossardorigine}</p>
                            </div>
                            <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>
            <h3 className="text-xl font-semibold text-gray-900 mb-6">Liste des véhicules</h3>
            <div className="space-y-6">
              {Object.keys(groupedVehicles).map((typedevhl) => (
                <div key={typedevhl} className="bg-white rounded-lg shadow overflow-hidden">
                  <div className="bg-gray-50 px-4 py-3 border-b border-gray-200">
                    <h3 className="text-base font-bold text-gray-900 text-center">{typedevhl}</h3>
                  </div>
                  <ul className="divide-y divide-gray-200">
                    {groupedVehicles[typedevhl].map((vhl) => (
                      <li key={vhl._id}>
                        <Link to={`/pagevhl/${vhl._id}`} className="block hover:bg-gray-50 transition duration-150 ease-in-out">
                          <div className="flex items-center px-4 py-4">
                            <div className="flex-shrink-0">
                              <img className="h-12 w-12 rounded-lg object-cover" src={vhl.image} alt="" />
                            </div>
                            <div className="ml-4 flex-1 min-w-0">
                              <div className="flex items-center gap-2">
                                <p className="text-sm font-bold text-gray-900 truncate">{vhl.nomvhl}</p>
                                {vhl.majradio === "DMR" && (
                                  <SignalIcon className="h-4 w-4 text-blue-600 flex-shrink-0" aria-hidden="true" />
                                )}
                                {vhl.attelage === true && (
                                  <LinkIcon className="h-4 w-4 text-blue-600 flex-shrink-0" aria-hidden="true" />
                                )}
                                {RDVvhl.some(rdv => rdv.idvhl === vhl._id) && (
                                  <ExclamationCircleIcon className="h-4 w-4 text-red-500 flex-shrink-0" aria-hidden="true" />
                                )}
                              </div>
                              <p className="mt-1 text-sm text-gray-500 italic">{vhl.dossardorigine}</p>
                            </div>
                            <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
