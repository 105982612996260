import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Paper,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  Avatar,
  Chip,
  Stack,
  Alert,
  CircularProgress
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fr } from 'date-fns/locale';
import {
  DirectionsCar as CarIcon,
  Build as BuildIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import authHeader from "../auth-header";

const EditTache = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [vehicles, setVehicles] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [formData, setFormData] = useState({
    idvhl: "",
    nomvhl: "",
    dossardorigine: "",
    service: "",
    km: "",
    image: "",
    date: null,
    nomgaragiste: "",
    typetravail: "",
    remarques: "",
    priorite: 1,
    couleur: ""
  });

  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const typetravailOptions = [
    { id: 1, categorie: 'Méca', name: 'Check VHL' },
    { id: 2, categorie: 'Méca', name: 'Visite' },
    { id: 3, categorie: 'Méca', name: 'Accident' },
    { id: 4, categorie: 'Méca', name: 'Confection de plaques' },
    { id: 5, categorie: 'Méca', name: 'Liquide de frein' },
    { id: 6, categorie: 'Méca', name: 'Pneumatique' },
    { id: 7, categorie: 'Méca', name: 'Frein' },
    { id: 8, categorie: 'Méca', name: 'Distribution' },
    { id: 9, categorie: 'Méca', name: 'Embrayage' },
    { id: 10, categorie: 'Méca', name: 'Recherche de panne' },
    { id: 11, categorie: 'Autre', name: 'Secrétariat' },
    { id: 12, categorie: 'Autre', name: 'Instruction' },
    { id: 13, categorie: 'Autre', name: 'Garde' },
    { id: 14, categorie: 'Autre', name: 'Repos / Perms' },
    { id: 15, categorie: 'Autre', name: 'Sport' },
    { id: 16, categorie: 'Autre', name: 'Depannage' },
    { id: 17, categorie: 'Autre', name: 'Nettoyage' },
    { id: 18, categorie: 'Autre', name: 'Série 0' },
    { id: 19, categorie: 'Autre', name: 'Cérémonie / Dem' },
    { id: 20, categorie: 'Autre', name: 'Liaison' },
    { id: 21, categorie: 'Autre', name: 'Réunion' },
    { id: 22, categorie: 'Autre', name: 'OPEX' },
    { id: 23, categorie: 'Autre', name: 'Désequipement GIE' },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [vehiclesResponse, userResponse, tacheResponse] = await Promise.all([
          axios.get(process.env.REACT_APP_BASE_URL + "/api/vhl/", { headers: authHeader() }),
          axios.get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() }),
          axios.get(process.env.REACT_APP_BASE_URL + '/api/planninggarage/' + id, { headers: authHeader() })
        ]);

        setVehicles(vehiclesResponse.data);
        setCurrentUser(userResponse.data);

        const tacheData = tacheResponse.data;
        const selectedVehicle = vehiclesResponse.data.find(v => v._id === tacheData.idvhl);

        setSelectedVehicle(selectedVehicle);
        setFormData({
          idvhl: selectedVehicle?._id || "",
          nomvhl: selectedVehicle?.nomvhl || "",
          dossardorigine: selectedVehicle?.dossardorigine || "",
          service: selectedVehicle?.service || "",
          km: selectedVehicle?.km || "",
          image: selectedVehicle?.image || "",
          date: tacheData.date ? new Date(tacheData.date) : null,
          nomgaragiste: tacheData.nomgaragiste || "",
          typetravail: tacheData.typetravail || "",
          remarques: tacheData.remarques || "",
          priorite: tacheData.priorite || 1,
          couleur: tacheData.couleur || ""
        });

      } catch (error) {
        console.error('Erreur lors du chargement des données:', error);
        setError('Une erreur est survenue lors du chargement des données.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleVehicleChange = (event, newValue) => {
    if (newValue) {
      setSelectedVehicle(newValue);
      setFormData(prev => ({
        ...prev,
        idvhl: newValue._id,
        nomvhl: newValue.nomvhl,
        dossardorigine: newValue.dossardorigine,
        service: newValue.service,
        km: newValue.km,
        image: newValue.image
      }));
    }
  };

  const handleInputChange = (field) => (event) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleDateChange = (newDate) => {
    setFormData(prev => ({
      ...prev,
      date: newDate
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/planninggarage/update/" + id,
        formData,
        { headers: authHeader() }
      );
      navigate('/calendriertachesv2');
    } catch (error) {
      console.error('Erreur lors de la mise à jour:', error);
      setError('Une erreur est survenue lors de la mise à jour de la tâche.');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center" color="primary">
          Modifier la tâche
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {/* Sélection du véhicule */}
            <Autocomplete
              value={selectedVehicle}
              onChange={handleVehicleChange}
              options={vehicles}
              groupBy={(option) => option.service}
              getOptionLabel={(option) => `${option.nomvhl} - ${option.dossardorigine}`}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  <Avatar src={option.image} alt={option.nomvhl}>
                    <CarIcon />
                  </Avatar>
                  <Box sx={{ ml: 2 }}>
                    <Typography variant="subtitle1">{option.nomvhl}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {option.dossardorigine}
                    </Typography>
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Véhicule"
                  required
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: selectedVehicle && (
                      <Avatar
                        src={selectedVehicle.image}
                        alt={selectedVehicle.nomvhl}
                        sx={{ width: 32, height: 32, mr: 1 }}
                      >
                        <CarIcon />
                      </Avatar>
                    ),
                  }}
                />
              )}
            />

            {/* Type de travail */}
            <FormControl fullWidth>
              <InputLabel>Type de travail</InputLabel>
              <Select
                value={formData.typetravail}
                onChange={handleInputChange('typetravail')}
                required
                startAdornment={<BuildIcon sx={{ mr: 1 }} />}
              >
                {Object.entries(
                  typetravailOptions.reduce((acc, option) => {
                    if (!acc[option.categorie]) acc[option.categorie] = [];
                    acc[option.categorie].push(option);
                    return acc;
                  }, {})
                ).map(([categorie, options]) => [
                  <MenuItem key={categorie} disabled>
                    <Typography variant="subtitle2" color="primary">
                      {categorie}
                    </Typography>
                  </MenuItem>,
                  ...options.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  )),
                ])}
              </Select>
            </FormControl>

            {/* Nom du garagiste */}
            <TextField
              label="Nom du garagiste"
              value={formData.nomgaragiste}
              onChange={handleInputChange('nomgaragiste')}
              required
              fullWidth
            />

            {/* Remarques */}
            <TextField
              label="Remarques"
              value={formData.remarques}
              onChange={handleInputChange('remarques')}
              multiline
              rows={4}
              fullWidth
            />

            {/* Priorité */}
            <Box>
              <Typography gutterBottom>
                Priorité: {formData.priorite}
              </Typography>
              <Slider
                value={formData.priorite}
                onChange={(e, newValue) => setFormData(prev => ({ ...prev, priorite: newValue }))}
                step={1}
                marks
                min={1}
                max={5}
                valueLabelDisplay="auto"
              />
            </Box>

            {/* Date */}
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
              <DatePicker
                label="Date"
                value={formData.date}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} fullWidth />}
                format="dd/MM/yyyy"
              />
            </LocalizationProvider>

            {/* Bouton de soumission */}
            <Button
              type="submit"
              variant="contained"
              size="large"
              startIcon={<SaveIcon />}
              sx={{ mt: 3 }}
            >
              Enregistrer les modifications
            </Button>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
};

export default EditTache;
