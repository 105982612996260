import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  IconButton,
  Chip,
  Divider,
  useTheme,
  useMediaQuery,
  Paper
} from '@mui/material';
import {
  DirectionsCar as CarIcon,
  ChevronRight as ChevronRightIcon,
  Build as BuildIcon,
  LocalShipping as TruckIcon
} from '@mui/icons-material';
import authHeader from "../auth-header";

export default function VhlsGarage() {
  const [vhlgarage, setVhlGarage] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/vhl/garage", { headers: authHeader() })
      .then((response) => {
        setVhlGarage(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const groupedVehicles = vhlgarage.reduce((acc, vhl) => {
    if (!acc[vhl.service]) {
      acc[vhl.service] = [];
    }
    acc[vhl.service].push(vhl);
    return acc;
  }, {});

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      {/* Header Card */}
      <Card 
        elevation={3}
        sx={{ 
          position: 'relative',
          overflow: 'hidden',
          mb: 4
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '100%',
            background: 'linear-gradient(135deg, rgba(26, 35, 126, 0.8) 0%, rgba(13, 71, 161, 0.8) 100%)',
            zIndex: 1
          }}
        />
        <CardContent sx={{ position: 'relative', zIndex: 2, color: 'white', textAlign: 'center', py: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2, mb: 2 }}>
            <CarIcon sx={{ fontSize: 40 }} />
            <BuildIcon sx={{ fontSize: 40 }} />
          </Box>
          <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
            Véhicules en maintenance
          </Typography>
          <Typography variant="subtitle1" sx={{ opacity: 0.9 }}>
            Liste des véhicules actuellement au garage
          </Typography>
        </CardContent>
      </Card>

      {/* Service Groups */}
      {Object.entries(groupedVehicles).map(([service, vehicles]) => (
        <Paper 
          key={service} 
          elevation={2} 
          sx={{ 
            mb: 3,
            overflow: 'hidden',
            borderRadius: 2
          }}
        >
          {/* Service Header */}
          <Box
            sx={{
              px: 2,
              py: 1.5,
              bgcolor: 'primary.main',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            <CarIcon />
            <Typography variant="h6" component="h2">
              {service}
            </Typography>
          </Box>

          {/* Vehicles List */}
          <List disablePadding>
            {vehicles.map((vhl, index) => (
              <React.Fragment key={vhl._id}>
                {index > 0 && <Divider />}
                <ListItem
                  component={Link}
                  to={`/pagevhl/${vhl._id}`}
                  sx={{
                    py: 2,
                    textDecoration: 'none',
                    color: 'inherit',
                    '&:hover': {
                      bgcolor: 'action.hover',
                    }
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      src={vhl.image}
                      alt={vhl.nomvhl}
                      sx={{
                        width: 56,
                        height: 56,
                        mr: 2,
                        border: '2px solid',
                        borderColor: 'primary.main'
                      }}
                    >
                      <CarIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1" fontWeight="medium">
                        {vhl.nomvhl}
                      </Typography>
                    }
                    secondary={
                      <Box sx={{ mt: 0.5 }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ fontStyle: 'italic' }}
                        >
                          {vhl.dossardorigine}
                        </Typography>
                        {!isMobile && (
                          <Chip
                            label="En maintenance"
                            size="small"
                            sx={{ mt: 1 }}
                            color="warning"
                            variant="outlined"
                          />
                        )}
                      </Box>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" component={Link} to={`/pagevhl/${vhl._id}`}>
                      <ChevronRightIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Paper>
      ))}
    </Container>
  );
}
