import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  Avatar,
  Button,
  Divider,
  Paper,
  List,
  ListItem,
  ListItemText,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  DirectionsCar as CarIcon,
  Build as BuildIcon,
  Assignment as TaskIcon,
  PriorityHigh as PriorityIcon,
  Person as PersonIcon,
  Event as EventIcon,
  Comment as CommentIcon
} from '@mui/icons-material';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import authHeader from "../auth-header";

function DetailTache() {
  const [tache, setTache] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (isLoading) {
      axios
        .get(process.env.REACT_APP_BASE_URL + '/api/planninggarage/' + id, { headers: authHeader() })
        .then(response => {
          setTache(response.data);
        })
        .catch(error => {
          console.log(error);
        });

      axios
        .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
        .then(response => {
          setCurrentUser(response.data);
        })
        .catch(error => {
          console.log(error);
        });

      setIsLoading(false);
    }
  }, [id, isLoading]);

  const handleDelete = () => {
    axios
      .delete(process.env.REACT_APP_BASE_URL + "/api/planninggarage/" + id, { headers: authHeader() })
      .then(() => {
        navigate('/calendriertachesv2');
      })
      .catch(error => {
        console.error("Erreur lors de la suppression:", error);
      });
  };

  const getPriorityColor = (priority) => {
    const priorities = {
      0: 'default',
      1: 'info',
      2: 'success',
      3: 'warning',
      4: 'error',
      5: 'error'
    };
    return priorities[priority] || 'default';
  };

  if (isLoading) {
    return (
      <Container maxWidth="md" sx={{ py: 4, textAlign: 'center' }}>
        <Typography>Chargement...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      {/* Header Card */}
      <Card 
        elevation={3}
        sx={{ 
          position: 'relative',
          overflow: 'hidden',
          mb: 4
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '100%',
            background: 'linear-gradient(135deg, rgba(26, 35, 126, 0.8) 0%, rgba(13, 71, 161, 0.8) 100%)',
            zIndex: 1
          }}
        />
        <CardContent sx={{ position: 'relative', zIndex: 2, color: 'white', textAlign: 'center', py: 4 }}>
          <Avatar
            src={tache.image}
            alt={tache.nomvhl}
            sx={{
              width: 120,
              height: 120,
              mx: 'auto',
              mb: 2,
              border: '4px solid',
              borderColor: 'white'
            }}
          >
            <CarIcon sx={{ fontSize: 60 }} />
          </Avatar>
          <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
            {tache.nomvhl}
          </Typography>
          <Typography variant="h6" sx={{ opacity: 0.9, fontStyle: 'italic', mb: 1 }}>
            {tache.dossardorigine}
          </Typography>
          <Chip 
            label={tache.service}
            sx={{ 
              bgcolor: 'rgba(255, 255, 255, 0.2)',
              color: 'white',
              '& .MuiChip-label': { px: 2 }
            }}
          />
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            Kilométrage: {tache.km} km
          </Typography>
        </CardContent>
      </Card>

      {/* Details Card */}
      <Paper elevation={2} sx={{ p: 3 }}>
        <List disablePadding>
          <ListItem>
            <ListItemText
              primary={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <TaskIcon color="primary" />
                  <Typography variant="subtitle1" color="primary">Type de travail</Typography>
                </Box>
              }
              secondary={tache.typetravail}
            />
          </ListItem>
          <Divider component="li" />
          
          <ListItem>
            <ListItemText
              primary={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <PriorityIcon color="primary" />
                  <Typography variant="subtitle1" color="primary">Priorité</Typography>
                </Box>
              }
              secondary={
                <Chip
                  label={`Priorité ${tache.priorite}`}
                  color={getPriorityColor(tache.priorite)}
                  size="small"
                  sx={{ mt: 0.5 }}
                />
              }
            />
          </ListItem>
          <Divider component="li" />

          <ListItem>
            <ListItemText
              primary={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CommentIcon color="primary" />
                  <Typography variant="subtitle1" color="primary">Remarques</Typography>
                </Box>
              }
              secondary={tache.remarques || 'Aucune remarque'}
            />
          </ListItem>
          <Divider component="li" />

          <ListItem>
            <ListItemText
              primary={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <PersonIcon color="primary" />
                  <Typography variant="subtitle1" color="primary">Garagiste</Typography>
                </Box>
              }
              secondary={tache.nomgaragiste}
            />
          </ListItem>
          <Divider component="li" />

          <ListItem>
            <ListItemText
              primary={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <EventIcon color="primary" />
                  <Typography variant="subtitle1" color="primary">Date</Typography>
                </Box>
              }
              secondary={tache.date ? format(new Date(tache.date), 'PPP', { locale: fr }) : 'Date non définie'}
            />
          </ListItem>
        </List>

        {currentUser.roles === "Admin" && (
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', mt: 4 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
              onClick={() => navigate('/edittache/' + tache._id)}
              fullWidth={isMobile}
            >
              Modifier
            </Button>
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => setOpenDialog(true)}
              fullWidth={isMobile}
            >
              Supprimer
            </Button>
          </Box>
        )}
      </Paper>

      {/* Confirmation Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirmer la suppression
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Êtes-vous sûr de vouloir supprimer cette tâche ? Cette action est irréversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Annuler</Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default DetailTache;
