import React, { useState, useEffect } from "react";
import axios from "axios";
import authHeader from "../auth-header";

export default function CreateMateriel() {
  const [nom, setNom] = useState("");
  const [categorie, setCategorie] = useState("");
  const [alerte, setAlerte] = useState("");
  const [emplacement, setEmplacement] = useState("");
  const [image, setImage] = useState("");
  const [remarques, setRemarques] = useState("");
  const [imageperception, setImagePerception] = useState("");
  const [nbperception, setNbPerception] = useState(0);
  const [message, setMessage] = useState("");
  const [currentUser, setCurrentUser] = useState({});

  // Définir les URLs des images pour chaque type d'alerte
  const alerteImages = {
    "Alerte 1": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/A1_Logo_Red.jpg/480px-A1_Logo_Red.jpg",
    "Alerte 2": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/A1_Logo_Red.jpg/480px-A1_Logo_Red.jpg",
    "Spare": "https://st2.depositphotos.com/5943796/11310/v/600/depositphotos_113102904-stock-illustration-initial-letter-s-swoosh-blue.jpg"
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Mettre à jour imageperception quand l'alerte change
  useEffect(() => {
    if (alerte && alerteImages[alerte]) {
      setImagePerception(alerteImages[alerte]);
    }
  }, [alerte]);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const materiel = {
      nom,
      categorie,
      alerte,
      emplacement,
      image,
      remarques,
      imageperception,
    };

    axios
      .post(
        process.env.REACT_APP_BASE_URL + "/api/materiels/add",
        materiel,
        { headers: authHeader() }
      )
      .then((response) => {
        setMessage("Matériel créé avec succès!");
        // Reset form
        setNom("");
        setCategorie("");
        setAlerte("");
        setEmplacement("");
        setImage("");
        setRemarques("");
        setImagePerception("");
        // Redirect after 2 seconds
        setTimeout(() => {
          window.location.href = "/materiel/toutmateriel";
        }, 2000);
      })
      .catch((error) => {
        setMessage("Erreur lors de la création du matériel.");
        console.log(error);
      });
  };

  const categories = [
    "CNP",
    "COF / Perben",
    "Divers",
    "Rens aérien / Drones",
    "Image",
    "Interpel / Armement",
    "Micro",
    "Mobilité",
    "SIC",
    "Track",
    "Verte",
  ];

  const alertes = ["Alerte 1", "Alerte 2", "Spare"];

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="bg-white shadow-sm rounded-lg mb-6">
          <div className="px-4 py-5 sm:p-6">
            <h1 className="text-2xl font-bold text-gray-900 mb-1">
              Créer un nouveau matériel
            </h1>
            <p className="text-sm text-gray-500">
              Remplissez les informations ci-dessous pour ajouter un nouveau matériel à l'inventaire.
            </p>
          </div>
        </div>

        {/* Form */}
        <div className="bg-white shadow-sm rounded-lg">
          <form onSubmit={handleSubmit} className="space-y-6 px-4 py-5 sm:p-6">
            {/* Nom */}
            <div>
              <label htmlFor="nom" className="block text-sm font-medium text-gray-700">
                Nom
              </label>
              <input
                type="text"
                id="nom"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Nom du matériel"
              />
            </div>

            {/* Catégorie */}
            <div>
              <label htmlFor="categorie" className="block text-sm font-medium text-gray-700">
                Catégorie
              </label>
              <select
                id="categorie"
                value={categorie}
                onChange={(e) => setCategorie(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                <option value="">Sélectionnez une catégorie</option>
                {categories.map((cat) => (
                  <option key={cat} value={cat}>
                    {cat}
                  </option>
                ))}
              </select>
            </div>

            {/* Alerte */}
            <div>
              <label htmlFor="alerte" className="block text-sm font-medium text-gray-700">
                Niveau d'alerte
              </label>
              <select
                id="alerte"
                value={alerte}
                onChange={(e) => setAlerte(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                <option value="">Sélectionnez un niveau d'alerte</option>
                {alertes.map((alert) => (
                  <option key={alert} value={alert}>
                    {alert}
                  </option>
                ))}
              </select>
            </div>

            {/* Remarques */}
            <div>
              <label htmlFor="remarques" className="block text-sm font-medium text-gray-700">
                Remarques
              </label>
              <textarea
                id="remarques"
                value={remarques}
                onChange={(e) => setRemarques(e.target.value)}
                rows={4}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Remarques concernant le matériel"
              />
            </div>

            {/* Emplacement */}
            <div>
              <label htmlFor="emplacement" className="block text-sm font-medium text-gray-700">
                Emplacement
              </label>
              <input
                id="emplacement"
                value={emplacement}
                placeholder="Lieu de stockage du matériel"
                onChange={(e) => setEmplacement(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>

            {/* Image */}
            <div>
              <label htmlFor="image" className="block text-sm font-medium text-gray-700">
                Image
              </label>
              <input
                type="text"
                id="image"
                value={image}
                onChange={(e) => setImage(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="URL ou chemin de l'image"
              />
            </div>

            {/* Image Perception (en lecture seule car automatiquement définie) */}
            <div>
              <label htmlFor="imageperception" className="block text-sm font-medium text-gray-700">
                Image de perception (définie automatiquement selon l'alerte)
              </label>
              <input
                type="text"
                id="imageperception"
                value={imageperception}
                readOnly
                className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>

            {/* Submit Button */}
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Créer le matériel
              </button>
            </div>

            {/* Message de confirmation/erreur */}
            {message && (
              <div
                className={`rounded-md p-4 ${
                  message.includes("succès")
                    ? "bg-green-50 text-green-800"
                    : "bg-red-50 text-red-800"
                }`}
              >
                {message}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
