import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { TrashIcon } from '@heroicons/react/20/solid';
import authHeader from "../auth-header";

function PageHisto() {
  const [histovhl, setHistoVhl] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/historiqueperception/detail/" + id, {
        headers: authHeader(),
      })
      .then((response) => {
        setHistoVhl(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
      axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const deleteP = (id) => {
    console.log(histovhl._id);
    console.log(id);
    axios
      .delete(process.env.REACT_APP_BASE_URL + "/api/historiqueperception/" + id, { headers: authHeader() })
      .then((res) => {
        setIsLoading(true);
        window.location = `/historiqueperception/${histovhl.idvhl}`;
        console.log(res.data);
      })
  }

  return (
    <div className="bg-gray-100">
      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
        <div className="bg-white rounded-2xl shadow-lg p-4 transform hover:scale-[1.01] transition-all duration-300">
          {/* En-tête */}
          <div className="flex flex-col items-center mb-6">
            <h3 className="text-2xl font-bold text-gray-900">
              Détails de la perception
            </h3>
            <p className="mt-2 text-lg text-gray-500 font-semibold">
              {new Date(histovhl.date).toLocaleDateString('fr-FR', {
                day: 'numeric',
                month: 'long',
                year: 'numeric'
              })}
            </p>
          </div>

          {/* Informations de perception */}
          <div className="rounded-xl overflow-hidden border border-gray-200">
            <div className="divide-y divide-gray-200">
              {/* Position */}
              <div className="bg-gray-50 px-6 py-3 hover:bg-gray-100 transition-colors duration-150">
                <div className="flex items-center justify-between">
                  <dt className="text-sm font-semibold text-gray-900">Position</dt>
                  <dd className="text-sm text-gray-700">
                    <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                      {histovhl.position}
                    </span>
                  </dd>
                </div>
              </div>

              {/* Emploi */}
              <div className="bg-white px-6 py-3 hover:bg-gray-50 transition-colors duration-150">
                <div className="flex items-center justify-between">
                  <dt className="text-sm font-semibold text-gray-900">Emploi</dt>
                  <dd className="text-sm text-gray-700">{histovhl.emploi}</dd>
                </div>
              </div>

              {/* Utilisateur */}
              <div className="bg-gray-50 px-6 py-3 hover:bg-gray-100 transition-colors duration-150">
                <div className="flex items-center justify-between">
                  <dt className="text-sm font-semibold text-gray-900">Utilisateur</dt>
                  <dd className="text-sm text-gray-700">{histovhl.utilisateur}</dd>
                </div>
              </div>

              {/* Date */}
              <div className="bg-white px-6 py-3 hover:bg-gray-50 transition-colors duration-150">
                <div className="flex items-center justify-between">
                  <dt className="text-sm font-semibold text-gray-900">Date</dt>
                  <dd className="text-sm text-gray-700">
                    {new Date(histovhl.date).toLocaleDateString('fr-FR', {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric'
                    })}
                  </dd>
                </div>
              </div>

              {/* Kilométrage */}
              <div className="bg-gray-50 px-6 py-3 hover:bg-gray-100 transition-colors duration-150">
                <div className="flex items-center justify-between">
                  <dt className="text-sm font-semibold text-gray-900">Kilométrage</dt>
                  <dd className="text-sm text-gray-700">{histovhl.km}</dd>
                </div>
              </div>
            </div>
          </div>

          {/* Bouton de suppression pour les admins */}
          {currentUser.roles === "Admin" && (
            <div className="flex justify-center mt-6">
              <button
                type="button"
                onClick={() => {
                  if (window.confirm('Êtes-vous sûr de vouloir supprimer cette perception ?')) {
                    deleteP(histovhl._id);
                  }
                }}
                className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-lg text-red-700 bg-red-50 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-all duration-200 transform hover:scale-105"
              >
                <TrashIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                Supprimer la perception
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PageHisto;
